import React, { memo } from 'react';
import { CopyOutlined, PhoneOutlined, MailOutlined  } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { cssText } from '_fsd/shared';
import css from './UserContactInfoPopover.module.css';
import { Popover, message } from 'antd';
import cn from 'classnames';



export const UserContactInfoPopover = ({phonePrefix, phoneNumber, email, city, street, children}) => {
  const fullPhoneNumber = (phonePrefix + phoneNumber).replace(/\s+/g, '');
  const { t } = useTranslation();

  const onCopy = (type) => {
    let value = '';
    if (type === 'phone') value = fullPhoneNumber;
    if (type === 'email') value = email;

    navigator.clipboard.writeText(value);
    message.success(t('copied.to.clipboard'));
  };

  return (
    <Popover content={
      <div className={css.cellPopover}>
        {phonePrefix && 
          <div className={css.singlePopover}>
            <div className={cn(cssText.s14w6l18, css.itemName)}>{t('owner_contact_info_popover.phone')}</div>
            <div>
              <span className={css.singlePopOverObject}>
                <a href={`tel:${fullPhoneNumber}`}>
                  {fullPhoneNumber}
                </a>
              </span>
              <button name="phone" onClick={(e) => onCopy(e.currentTarget.name)} className={css.singlePopOverObject}>
                <CopyOutlined className="copy-svg" />
              </button>
            </div>
          </div>
        }
        {email &&
          <div className={css.singlePopover}>
            <div className={cn(cssText.s14w6l18, css.itemName)}>{t('owner_contact_info_popover.email')}</div>
            <div>
              <span className={css.singlePopOverObject}>
                <a href={`mailto:${email}`}>
                {email}
                </a>
              </span>
              <button name="email" onClick={(e) => onCopy(e.currentTarget.name)} className={css.singlePopOverObject}>
                <CopyOutlined className="copy-svg" />
              </button>
            </div>
          </div>
        }
        {city &&
          <div className={css.singlePopover}>
            <div className={cn(cssText.s14w6l18, css.itemName)}>{t('owner_contact_info_popover.city')}</div>
            <span className={cn(css.singlePopOverObject, css.itemWithOutCopyIcon)}>{city}</span>
          </div>
        }
        {street &&
          <div className={css.singlePopover}>
            <div className={cssText.s14w6l18}>{t('owner_contact_info_popover.street')}</div>
            <span className={cn(css.singlePopOverObject, css.itemWithOutCopyIcon)}>{street}</span>
          </div>
        }
      </div>
    }>
      <div>{children}</div>
    </Popover>
  );
};

