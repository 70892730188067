import styled, { css } from 'styled-components';

export const DropDownHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 7.2rem;
  cursor: pointer;
`;
export const DropDownWrapper = styled.div`
  border-bottom: 1px solid #d0d9e7;
  ${({ hideBorder }) =>
    hideBorder &&
    css`
      border-bottom: none;
    `}
`;

export const DropDownHeaderTitle = styled.span`
  font-family: inter, assistant;
  font-weight: 400;
  font-size: 1.4rem;
`;

export const IconWrapper = styled.div`
  transition: all 0.3s ease-out;
  ${({ open }) =>
    open &&
    css`
      transform: rotate(180deg);
    `}
`;
export const DropDownContentWrapper = styled.div`
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  ::-webkit-scrollbar {
    width: 0.6rem;
    position: absolute;
  }

  ::-webkit-scrollbar-track {
    background: #fff;
  }

  ::-webkit-scrollbar-thumb {
    background: #acbcd4;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #acbcd497;
  }
  ${({ open }) =>
    open &&
    css`
      overflow-y: auto;
      max-height: 26.8rem;
      transition: max-height 0.3s ease-out;
    `};
`;
