import React from 'react';
import { useParams } from 'react-router-dom';
import { replaceRouteParams } from 'utils/helpers';

import { petAvatars } from 'constants/pet';
import * as S from 'components/side-menu/components';
import { PetAvatarWrapper, PetName, PetAvatar, SubMenuLink } from './components';
import { SINGLE_PET } from 'constants/client';

export const SubMenuPet = ({ petAvatar, petName, petType, uid }) => {
  const { id } = useParams();
  const isPetActive = id === uid;

  return (
    <SubMenuLink to={replaceRouteParams(SINGLE_PET, 'id', uid)}>
      <S.SubMenuItem>
        <PetAvatarWrapper>
          <PetAvatar
            isPetActive={isPetActive}
            src={petAvatar || petAvatars[petType]}
            hasAvatar={petAvatar}
            alt="pet"
          />
        </PetAvatarWrapper>
        <PetName isPetActive={isPetActive}>{petName}</PetName>
      </S.SubMenuItem>
    </SubMenuLink>
  );
};
