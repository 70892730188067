import React, { useRef } from 'react';
import useOutsideClickDetector from '../../../hooks/useOutsideClickDetector';
import ChatContainer from '../chat-container/ChatContainer';
import ChatButton from '../chat-button/ChatButton';
import { WidgetContainer } from './components';
import { toggleChat } from 'utils/helpers';
import { useHistory } from 'react-router';
import { HOME } from 'constants/client';

const ChatWidget = ({ isForVet }) => {
  const history = useHistory();
  const { location } = history;
  const isChatOpen = location.search === '?chatOpen=true';

  const chatRef = useRef(null);

  useOutsideClickDetector(chatRef, (e) => (isChatOpen ? toggleChat(e, history) : void 0));

  return (
    <>
      {!location.pathname !== HOME && (
        <WidgetContainer isOpen={isChatOpen} isForVet={isForVet} ref={chatRef}>
          <ChatButton clickHandler={(e) => toggleChat(e, history)} />
          <ChatContainer visible={isChatOpen} toggleChat={(e) => toggleChat(e, history)} />
        </WidgetContainer>
      )}
    </>
  );
};

export default ChatWidget;
