import styled from 'styled-components';

export const Wrapper = styled.header`
  height: 5.6rem;
  width: 100%;
  border-bottom: 0.1rem solid ${({ theme }) => theme.colors.gray};
  display: flex;
  align-items: center;
  min-height: 5.6rem;
`;
