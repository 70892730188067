import React, { useContext, useEffect, useState } from 'react';
import { Input, Table, Form, Row, Col, Button } from 'antd';
import { Modal } from '_fsd/shared';

import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import EditClientModal from '../edit-client-modal/EditClientModal';
import PetModal from '../pet-modal/PetModal';
import useCustomersColumns from './useCustomersColumns';
import useCustomersData from './useCustomersData';
import EmptyState from 'components/empty-state/EmptyState';
import { orderConverter } from '../../../utils/helpers';
import { ReactComponent as CustomerIcon } from 'assets/icons/customer.svg';
import { ReactComponent as PowIcon } from 'assets/icons/pow.svg';
import './customers-styles.css';
import AuthContext from '../../../contexts/AuthContext';
import css from './Customers.module.css';
import { cssText, cssSpace } from '_fsd/shared';
import { CreateClient } from '_fsd/features';

const Customers = () => {
  const [customerData, setCustomerData] = useState(null);
  const [isPetModalOpen, setIsPetModalOpen] = useState(false);
  const [petIdToUpdate, setPetIdToUpdate] = useState(null);
  const [formIsReseted, setFormIsReseted] = useState(false);
  const { Search } = Input;
  const columns = useCustomersColumns(setCustomerData);
  const { user: me } = useContext(AuthContext);
  const { t } = useTranslation();
  const [modalClient, setModalClient] = useState(false);
  const {
    customersData,
    setName,
    name,
    setSearch,
    setSortBy,
    countries,
    page,
    setPage,
    subscriptionStatus,
    loading,
    totalData,
    registrationDate,
    setPetName
  } = useCustomersData(me.uid);

  const onSearch = (value) => {
    setPage(1);
    setSearch(value);
  };

  const filterCustomerName = (e) => {
    setName(e.target.value);
  };

  const getPageNumber = (page) => {
    setPage(page);
  };

  useEffect(() => {
    setPage(1);
  }, [registrationDate, subscriptionStatus, countries, name]);

  const changeSort = (sorter) => {
    const sortObject = {};

    if (Array.isArray(sorter)) {
      for (let i = 0; i < sorter.length; i++) {
        sortObject[sorter[i].field] = orderConverter(sorter[i].order);
      }
      setSortBy((prev) => {
        return { ...prev, ...sortObject };
      });
    } else if (!!Object.keys(sorter).length) {
      const { field, order } = sorter;
      setSortBy((prev) => {
        return { ...prev, [field]: orderConverter(order) };
      });
    }
  };

  return (
    <div className={css.container}>
      {customerData && (
        <EditClientModal
          customerData={customerData}
          setIsPetModalOpen={setIsPetModalOpen}
          setPetIdToUpdate={setPetIdToUpdate}
          formIsReseted={formIsReseted}
          setFormIsReseted={setFormIsReseted}
          closeModal={() => {
            setCustomerData(null);
            setFormIsReseted(false);
          }}
        />
      )}
      {(petIdToUpdate || isPetModalOpen) && (
        <PetModal
          isPetModalOpen={isPetModalOpen}
          petIdToUpdate={petIdToUpdate}
          customerUserUid={customerData?.userId}
          closeModal={() => {
            setIsPetModalOpen(false);
            setPetIdToUpdate(null);
          }}
        />
      )}
      <Form className="client-filters-form" layout="vertical">
        <Row className={cssSpace.mb12}>
          <Col xs={24} lg={12}>
            <span className={cssText.s16h24w5}>Clients</span>
          </Col>
          <Col xs={24} lg={12}>
            <Row justify="end">
              <Button
                icon={<PlusOutlined />}
                className={css.btnCreate}
                onClick={() => setModalClient(true)}>
                New Client
              </Button>
            </Row>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} lg={9}>
            <Form.Item label={t('search')}>
              <Search
                className="customers-search-input"
                placeholder={t('customers.search.placeholder')}
                onChange={(e) => onSearch(e.target.value)}
                onSearch={onSearch}
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={5}>
            <Form.Item label={t('customers.client.name')} className="fixed-size-input">
              <Input
                onChange={filterCustomerName}
                prefix={<CustomerIcon />}
                size="small"
                placeholder={t('customers.customer.placeholder')}
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={5}>
            <Form.Item label={t('customers.pets.name')} className="fixed-size-input">
              <Input
                onChange={(e) => setPetName(e.target.value)}
                prefix={<PowIcon />}
                size="small"
                placeholder={t('customers.pets.placeholder')}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {customersData && customersData.length ? (
        <Table
          columns={columns}
          dataSource={customersData}
          rowKey="userId"
          loading={loading}
          onChange={(pagination, filters, sorter) => {
            changeSort(sorter);
          }}
          pagination={{
            pageSize: 10,
            onChange: getPageNumber,
            total: totalData,
            current: page
          }}
        />
      ) : (
        !loading && <EmptyState title={t('no.clients.found')} />
      )}
      {modalClient && (
        <Modal
          title="Create client"
          customFooter={() => null}
          isOpen={modalClient}
          force
          onClose={() => {
            setModalClient(false);
          }}>
          <CreateClient
            vetId={me?.vet?.uid}
            onSubmit={() => {
              setModalClient(false);
            }}
          />
        </Modal>
      )}
    </div>
  );
};

export default Customers;
