import React, { useState } from 'react';
import UsersList from './outdate/Customers';
import { Modal } from '_fsd/shared';
import css from './VetClientsPage.module.css';
import { CreateClient } from '_fsd/widgets/vet-admin-create-client';
import EditClientModal from 'pages/vet/edit-client-modal/EditClientModal';
import PetModal from 'pages/vet/pet-modal/PetModal';

export const VetClientsPage = (props) => {
  const [modalClient, setModalClient] = useState(false);
  const [editClient, setEditClient] = useState();
  const [editPet, setEditPet] = useState();

  return (
    <>
      <UsersList setEditClient={setEditClient} onClickNewClient={() => setModalClient(true)} />
      {modalClient && (
        <Modal
          title="Create client"
          customFooter={() => null}
          isOpen={modalClient}
          forceRender
          onClose={() => setModalClient(false)}>
          <CreateClient onSubmit={() => setModalClient(false)} />
        </Modal>
      )}
      {editClient && (
        <EditClientModal
          customerData={editClient}
          setEditPet={setEditPet}
          closeModal={() => setEditClient(null)}
        />
      )}
      {editPet && (
        <PetModal
          isPetModalOpen={editPet}
          petIdToUpdate={editPet?.uid}
          customerUserUid={editClient?.userId}
          closeModal={() => setEditPet(null)}
        />
      )}
    </>
  );
};
