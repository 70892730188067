import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './components';
import ChatContext from '../ChatContext';
import DropDown from 'components/chat/drop-down/DropDown';
import SharedMedia from '../shared-files/SharedMedia';
import SharedFile from '../shared-files/SharedFile';
import { ReactComponent as SearchIcon } from 'assets/icons/search-2.svg';
import Gallery from 'components/gallery/Gallery';

const ChatMenu = () => {
  const { t } = useTranslation();
  const { currentMedia, currentFiles } = useContext(ChatContext);
  const [previewModeIndex, setPreviewModeIndex] = useState(null);
  const [sharedMediaOpen, setSharedMediaOpen] = useState(false);
  const [sharedFilesOpen, setSharedFilesOpen] = useState(false);

  const toggleSharedMedia = () => {
    setSharedFilesOpen(false);
    setSharedMediaOpen((curr) => !curr);
  };
  const toggleSharedFiles = () => {
    setSharedMediaOpen(false);
    setSharedFilesOpen((curr) => !curr);
  };
  return (
    <S.MenuWrapper>
      <S.SearchWrapper>
        <S.SearchText>{t('search.in.conversations')}</S.SearchText>
        <S.SearchIconWrapper>
          <SearchIcon />
        </S.SearchIconWrapper>
      </S.SearchWrapper>
      <DropDown
        open={sharedFilesOpen}
        headerTitle={t('shared.files', { count: currentFiles?.length })}
        toggle={toggleSharedFiles}>
        <S.DropDownContent column>
          {currentFiles?.length ? (
            currentFiles.map((attachment) => <SharedFile key={attachment?.url} {...attachment} />)
          ) : (
            <div>{t('no.files')}</div>
          )}
        </S.DropDownContent>
      </DropDown>
      <DropDown
        open={sharedMediaOpen}
        headerTitle={t('shared.media', { count: currentMedia?.length })}
        toggle={toggleSharedMedia}
        hideBorder>
        <S.DropDownContent>
          {currentMedia?.length ? (
            currentMedia.map((attachment, index) => (
              <SharedMedia
                key={attachment?.url}
                {...attachment}
                clickHandler={() => setPreviewModeIndex(index)}
              />
            ))
          ) : (
            <div>{t('no.media')}</div>
          )}
          {currentMedia?.length && (
            <>
              <div style={{ width: 72 }}></div>
              <div style={{ width: 72 }}></div>
            </>
          )}
        </S.DropDownContent>
      </DropDown>
      {typeof previewModeIndex === 'number' && (
        <Gallery
          files={currentMedia}
          currIndex={previewModeIndex}
          setGalleryVisible={() => setPreviewModeIndex(null)}
        />
      )}
    </S.MenuWrapper>
  );
};

export default ChatMenu;
