import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import { List, Layout, Button, Avatar, Tooltip, Skeleton } from 'antd';
import { FilterOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons';
import useSchedule from './useSchedule';
import EventDetailsModal from './EventDetailsModal';
import NewAppointmentModal from './modals/NewAppointmentModal';
import NewClientModal from './modals/NewClientModal';
import { useTranslation } from 'react-i18next';
import useActAsUser from '../../../vet-caregivers/outdate/useActAsUser';
import { parseTimeSlotHour, stringToColor } from '_fsd/shared';
import css from './Schedule.module.css';

const { Sider, Content } = Layout;

const Schedule = (props) => {
  const { t } = useTranslation();
  // calnder variables
  const {
    events,
    setEventCache,
    vets,
    loading,
    setEventsStartDate,
    setEventsEndDate,
    eventsStartDate,
    eventsEndDate
  } = useSchedule();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(false);

  const [selectedVets, setSelectedVets] = useState([]);
  const { handleActAsUser } = useActAsUser();
  const localizer = momentLocalizer(moment);
  // new appointment variables
  const [isNewClientModalOpen, setIsNewClientModalOpen] = useState(false);
  const [isNewAppointmentsModalOpen, setIsNewAppointmentsModalOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [isDayView, setIsDayView] = useState(true);

  // todo: issue with caching and deps updating after refecth events
  const forceClearCalendar = () => {
    setSelectedEvent(false);
    setIsModalOpen(false);
    setEventCache({});
    setEventsStartDate(moment(eventsStartDate));
    setEventsEndDate(moment(eventsEndDate));
  };

  // ---------------- new appointment functions ---------------//
  const changeStep = useCallback(
    (step) => {
      if (step < currentStep) setCurrentStep(step);
    },
    [currentStep]
  );

  const openNewClientModal = useCallback(() => {
    setIsNewClientModalOpen(true);
    setIsNewAppointmentsModalOpen(false);
  }, []);

  const handleNewAppointmentModalClose = useCallback(() => {
    setIsNewAppointmentsModalOpen(false);
    setCurrentStep(0);
  }, []);

  // ---------------- Calendar functions ---------------//
  const actAsHandler = (userId) => {
    handleActAsUser(userId);
  };

  const toggleEventDetailsModalView = () => {
    setIsModalOpen((isModalOpen) => {
      const state = !isModalOpen;
      return state;
    });
  };

  const handleViewChange = (view) => {
    // Check if the view changed to 'day'
    if (view === 'day') {
      setIsDayView(true);
    } else {
      setIsDayView(false);
    }
  };

  // ===calendar filtering logic===//

  const removeFilters = () => {
    setSelectedVets([]);
  };

  const handleVetSelect = (vetId) => {
    if (selectedVets.includes(vetId)) {
      setSelectedVets(selectedVets.filter((i) => i !== vetId));
    } else {
      setSelectedVets([...selectedVets, vetId]);
    }
  };

  const filteredEvents = events?.filter((event) => {
    if (selectedVets.length === 0) {
      return true;
    }
    return selectedVets.includes(event.vetId);
  });

  // ====== calendar events logic ======//

  const clickEventRef = useRef(null);

  const eventPropGetter = useCallback(
    (event, start, end, isSelected) => ({
      ...{
        style: {
          backgroundColor: stringToColor(event.vetId)
        }
      }
    }),
    []
  );

  useEffect(() => {
    /* This is to prevent a memory leak, in the off chance that you
     * teardown your interface prior to the timed method being called.
     */
    return () => {
      window.clearTimeout(clickEventRef?.current);
    };
  }, []);

  const onSelectEvent = useCallback((calEvent) => {
    /**
     * Here we are waiting 250 milliseconds  prior to firing
     * our method. to support both 'click' and 'doubleClick'
     */
    window.clearTimeout(clickEventRef?.current);
    clickEventRef.current = window.setTimeout(() => {
      setSelectedEvent(calEvent);
      toggleEventDetailsModalView();
    }, 50);
  }, []);

  const titleAccessor = (event) => {
    let res = '';
    if (event.eventType == 'TimeslotEvent') {
      const name = event?.timeSlot?.name;
      const location = event?.timeSlot?.workingAreas[0]?.polygon?.name || '';
      const full = event?.timeSlot?.isFull ? ' (Full)' : '';
      const locked = event?.timeSlot?.isLocked ? ' (Locked)' : '';

      res = name + (location ? ' - ' + location : '') + full + locked;
    } else {
      const client =
        (event?.appointments[0]?.appointments[0]?.subscription?.user?.firstName || '') +
        ' ' +
        (event?.appointments[0]?.appointments[0]?.subscription?.user?.lastName || '');
      const hours =
        parseTimeSlotHour(event?.timeSlot?.startTime) +
        '-' +
        parseTimeSlotHour(event?.timeSlot?.endTime);

      res = client;
    }
    return res;
  };

  const tooltipAccessor = (event) => {
    let res = '';
    const name = event?.timeSlot?.name;
    const client =
      (event?.appointments[0]?.appointments[0]?.subscription?.user?.firstName || '') +
      ' ' +
      (event?.appointments[0]?.appointments[0]?.subscription?.user?.lastName || '');
    const hours =
      parseTimeSlotHour(event?.timeSlot?.startTime) +
      '-' +
      parseTimeSlotHour(event?.timeSlot?.endTime);
    const location = '\r\nLocation: ' + (event?.timeSlot?.workingAreas[0]?.polygon?.name || '');
    const full = '\r\nFull: ' + (event?.timeSlot?.isFull ? 'Yes' : 'No');
    const locked = '\r\nLocked: ' + (event?.timeSlot?.isLocked ? 'Yes' : 'No');

    if (event.eventType == 'TimeslotEvent') {
      res = location + full + locked;
    } else {
      res = client + '\r\n\r\nTime-slot: ' + name + location;
    }
    return res;
  };

  const handleRangeChange = async (range) => {
    const start = range.start || new Date(Math.min(...range)) || new Date();
    const end = range.end || new Date(Math.max(...range)) || new Date();

    setEventsStartDate(moment(new Date(start.getFullYear(), start.getMonth(), 0)));
    setEventsEndDate(moment(new Date(end.getFullYear(), end.getMonth() + 1, 0)));
  };

  return (
    <div className={css.container}>
      <div style={{ marginBottom: '40px', width: '30%' }}>
        <Button
          icon={<PlusOutlined />}
          color="primary"
          className="appointment-btn"
          onClick={() => setIsNewAppointmentsModalOpen(true)}
          block
          style={{ background: '#001529e3', color: 'white', minWidth: '200px', maxWidth: '300px' }}>
          {t('admin.schedule.open_recommender_button')}
        </Button>
      </div>
      <EventDetailsModal
        isModalOpen={isModalOpen}
        setIsModalOpen={toggleEventDetailsModalView}
        event={selectedEvent}
        forceCloseModal={forceClearCalendar}
      />
      <Layout>
        <Sider style={{ width: '300px', background: 'white' }}>
          <div style={{ fontSize: '20px', paddingBottom: '12px' }}>
            <FilterOutlined style={{ marginRight: '5px' }} />
            {t('admin.schedule.filters_title')}
          </div>
          <div style={{ marginBottom: '20px' }}>
            <Button
              disabled={selectedVets.length === 0}
              type="default"
              shape="round"
              icon={<FilterOutlined />}
              size="small"
              style={{ width: 'fit-content' }}
              onClick={removeFilters}>
              {t('admin.schedule.clear_filters_button')}
            </Button>
          </div>
          <Skeleton
            active
            block
            loading={loading}
            style={{ padding: '20px' }}
            title={false}
            paragraph={{ rows: 10 }}>
            <List
              dataSource={vets}
              renderItem={(vet) => (
                <List.Item
                  // loading={loading}
                  style={{ padding: '0', borderWidth: '0px' }}>
                  <Button
                    type={selectedVets.includes(vet?.uid) ? 'primary' : ''}
                    onClick={() => handleVetSelect(vet?.uid)}
                    block
                    size="large"
                    style={{
                      height: 'auto',
                      minHeight: '60px',
                      borderWidth: 0,
                      borderRadius: '0px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      overflowWrap: 'break-word',
                      whiteSpace: 'normal'
                    }}>
                    {' '}
                    <div style={{ display: 'contents' }}>
                      <Avatar
                        style={{ backgroundColor: stringToColor(vet.uid), verticalAlign: 'middle' }}
                        size={10}
                      />
                      <span style={{ maxWidth: '125px' }}>
                        {vet.user.firstName} {vet.user.lastName}
                      </span>
                    </div>
                    <Tooltip title="Open user dashboard">
                      <Button
                        type="text"
                        icon={
                          <EyeOutlined
                            onClick={(event) => {
                              event.stopPropagation();
                              actAsHandler(vet.user.uid);
                            }}
                          />
                        }
                      />
                    </Tooltip>
                  </Button>
                </List.Item>
              )}
            />
          </Skeleton>
        </Sider>
        <Content style={{ background: 'white' }}>
          <Calendar
            localizer={localizer}
            events={filteredEvents}
            startAccessor={(event) => {
              return new Date(event.startTime);
            }}
            endAccessor={(event) => {
              return new Date(event.endTime);
            }}
            titleAccessor={titleAccessor}
            tooltipAccessor={tooltipAccessor}
            style={{ height: '75vh', background: 'white' }}
            eventPropGetter={eventPropGetter}
            onSelectEvent={onSelectEvent}
            defaultView="day"
            onView={handleViewChange} // Run the function on view change
            scrollToTime={moment().set({ h: 9, m: 0 }).toDate()}
            onRangeChange={handleRangeChange}
            popup
            selectable
            resources={isDayView ? vets : undefined}
            resourceAccessor={isDayView ? 'vetId' : undefined}
            resourceIdAccessor={isDayView ? 'uid' : undefined}
            resourceTitleAccessor={(vet) => (
              <div>
                <h3>
                  {vet.user.firstName} {vet.user.lastName}
                </h3>
              </div>
            )}
          />
        </Content>
      </Layout>
      {isNewAppointmentsModalOpen && vets?.[0] && (
        <NewAppointmentModal
          vet={vets ? vets[0] : null}
          allVets={vets}
          currentStep={currentStep}
          changeStep={changeStep}
          setCurrentStep={setCurrentStep}
          calendarDate={null}
          // selectedTimeslot={null}
          openNewClientModal={openNewClientModal}
          onRequestClose={handleNewAppointmentModalClose}
          getAppointmentsByTimeslotUid={null}
        />
      )}
      {isNewClientModalOpen && vets?.[0] && (
        <NewClientModal
          vet={vets ? vets[0] : null}
          allVets={vets}
          sendRequestCode={null}
          selectedTimeslot={null}
          checkExistingUser={null}
          selectedWorkingArea={null}
          onRequestClose={() => setIsNewClientModalOpen(false)}
          goBack={() => {
            setIsNewClientModalOpen(false);
            setIsNewAppointmentsModalOpen(true);
          }}
          getAppointmentsByTimeslotUid={null}
        />
      )}
    </div>
  );
};

export default Schedule;
