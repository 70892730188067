import styled from 'styled-components';

export const ChatButtonWrapper = styled.div`
  width: 6.4rem;
  height: 6.4rem;
  position: relative;
  cursor: pointer;
  ${({ theme }) => theme.mobile`
    display: none;
  `}
`;

export const ChatImg = styled.img`
  width: 100%;
  height: auto;
`;

export const NewMessageIndicator = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

export const CountWrapper = styled.div`
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: #ffffff;
  padding: 0.3rem;
  position: absolute;
  top: -0.6rem;
  right: -1rem;
`;

export const CountContainer = styled.div`
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background-color: ${({ theme }) => theme.colors.darkGreen};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CountLabel = styled.h3`
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.4rem;
  font-family: inter, assistant;
  font-weight: 600;
`;
