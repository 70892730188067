import { css } from 'styled-components';
import dayjs from 'dayjs';
import { rrulestr } from 'rrule';
import moment from 'moment';

export const generateMediaQuery = (screens, media) => {
  return Object.keys(screens).reduce((acc, label) => {
    acc[label] = (...args) => {
      if (args.length > 0) {
        const [firstArg, ...restOfArgs] = args;

        return css`
          @media (${media}: ${screens[label]}px) {
            ${css(firstArg, ...restOfArgs)}
          }
        `;
      }
    };

    return acc;
  }, {});
};

// timeslots to date object with timeSlots
export const timeSlotsToDateObject = ({
  timeslots,
  currenthMonth,
  fullTimeSlots,
  isForReschedule,
  startDate,
  time = 14,
  isForAdminCalendar = false,
  isForVet = false,
  timeZone = false,
  disabledTimeSlot = true
}) => {
  const datesObj = {};
  // eslint-disable-next-line no-unused-expressions
  timeslots?.forEach((slot) => {
    if (slot.rule && disabledTimeSlot?.uid !== slot?.uid) {
      const oneYearFromNow = new Date();
      oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);

      let twoWeeksAfterToday = new Date(Date.now() + 12096e5);

      let startFrom = startDate ? new Date(startDate) : new Date();

      if (isForVet) {
        startFrom = new Date(getDateFromRrule(slot.rule));
      }

      // startFrom.setDate(startFrom.getDate() + 1);

      if (isForReschedule) {
        // starts from day before appointment
        // ends to day after 2 week
        twoWeeksAfterToday = new Date(startDate);
        twoWeeksAfterToday.setDate(twoWeeksAfterToday.getDate() + 15);
      }

      // rrulestr(`DTSTART;TZID=Etc/GMT-12:20231122T120000;\n RRULE:FREQ=DAILY;COUNT=1;INTERVAL=1;WKST=MO`).between(
      rrulestr(slot.rule).between(
        startFrom,
        isForReschedule ? twoWeeksAfterToday : oneYearFromNow,
        false,
        (date) => {
          // set timeslot's hour and minute to date
          date.setHours(Number(slot?.startTime.slice(0, slot?.startTime.length - 6)));
          date.setMinutes(Number(slot?.startTime.slice(3, slot?.startTime.length - 3)));
          // if currenthMonth is true it will return next 30 days dates
          if (currenthMonth) {
            const today = new Date();
            const future = new Date();
            future.setDate(future.getDate() + 30);

            if (date.getTime() >= today.getTime() && date.getTime() <= future.getTime()) {
              setDateToObject(datesObj, date, slot);
            }
          } else {
            setDateToObject(datesObj, date, slot);
          }

          return date;
        }
      );
    }
  });
  // if timeslot is full remove date from list or disable single timeslot
  if (fullTimeSlots && !!fullTimeSlots.length) {
    fullTimeSlots.forEach((ft) => {
      const timeslots = datesObj[ft.date];

      if (timeslots) {
        timeslots.forEach((t) => {
          if (t.uid === ft.timeSlotId) {
            t.full = true;
          }
        });
        const disabledLength = timeslots.filter((t) => t.full === true).length;
        if (disabledLength > 0 && disabledLength == timeslots.length && !isForAdminCalendar) {
          delete datesObj[ft.date];
        }
      }
    });
  }

  // if currentTime is equal to time user can't choose next day for visit
  if (!isForAdminCalendar) {
    // FIXME: the locking hour should be by the timeslot's timezone (not by the user's timezone)
    const timezone = timeZone ? timeZone : Intl.DateTimeFormat().resolvedOptions().timeZone;
    const d = new Date();
    const currentTime = dayjs();
    const hour = dayjs(d.toLocaleString('en-US', { timezone })).format('HH');
    const nextDay = dayjs(currentTime).add(1, 'day').format('YYYY-MM-DD');
    const in2Days = dayjs(currentTime).add(2, 'day').format('YYYY-MM-DD');
    const in3Days = dayjs(currentTime).add(3, 'day').format('YYYY-MM-DD');

    switch (currentTime.day()) {
      case 5: // friday --> after 2pm remove until Monday
        if (hour >= time) {
          if (datesObj[nextDay]) {
            delete datesObj[nextDay];
          }
          if (datesObj[in2Days]) {
            delete datesObj[in2Days];
          }
          if (datesObj[in3Days]) {
            delete datesObj[in3Days];
          }
        }
        break;
      case 6: // saturday --> remove until Monday
        if (datesObj[nextDay]) {
          delete datesObj[nextDay];
        }
        if (datesObj[in2Days]) {
          delete datesObj[in2Days];
        }
        break;
      case 0: // sunday --> remove until Monday
        if (datesObj[nextDay]) {
          delete datesObj[nextDay];
        }
        break;
      default:
        if (hour >= time) {
          const nextDay = dayjs(currentTime).add(1, 'day').format('YYYY-MM-DD');
          if (datesObj[nextDay]) {
            delete datesObj[nextDay];
          }
        }
        break;
    }
  }

  return datesObj;
};

const getDateFromRrule = (rule) => {
  const year = rule.substring(rule.indexOf(':') + 1, rule.indexOf(':') + 5);

  const month = rule.substring(rule.indexOf(':') + 5, rule.indexOf(':') + 7);

  const day = rule.substring(rule.indexOf(':') + 7, rule.indexOf(':') + 9);

  return `${year}-${month}-${day}`;
};
// create object { date : [timeSlots]}
const setDateToObject = (obj, date, slot) => {
  const formatedDate = dayjs(date).format('YYYY-MM-DD');

  // if key already exists just push timeslot
  if (formatedDate in obj) {
    obj[formatedDate] = [...obj[formatedDate], { ...slot, date }];
  } else obj[formatedDate] = [{ ...slot, date }];
};

export const filterOption = (input, option) => {
  return option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

export const scrollParentToChild = (parent, child) => {
  // Where is the parent on page
  const parentRect = parent.getBoundingClientRect();
  // What can you see?
  const parentViewableArea = {
    height: parent.clientHeight,
    width: parent.clientWidth
  };

  // Where is the child
  const childRect = child.getBoundingClientRect();
  // Is the child viewable?
  const isViewable =
    childRect.top >= parentRect.top && childRect.top <= parentRect.top + parentViewableArea.height;

  // if you can't see the child try to scroll parent
  if (!isViewable) {
    // scroll by offset relative to parent
    parent.scrollTop = childRect.top + parent.scrollTop - parentRect.top;
  }
};

export const sortByStartTime = (data) => {
  return data?.sort(
    (a, b) =>
      Date.parse('1970/01/01 ' + a.startTime.slice(0, -2) + ' ' + a.startTime.slice(-2)) -
      Date.parse('1970/01/01 ' + b.startTime.slice(0, -2) + ' ' + b.startTime.slice(-2))
  );
};

export const getToken = () => {
  let token = sessionStorage.getItem('token');
  if (!token) {
    token = localStorage.getItem('token');
  }
  return token;
};

export const formatAppointmentDuration = (duration) => {
  const minutes = duration;
  const hours = Math.floor(minutes / 60);
  const minutesLeft = minutes % 60;
  const momentDate = moment(new Date()).set('h', hours).set('m', minutesLeft);
  if (hours < 1) {
    return momentDate.format('m') + ' Mins';
  } else if (hours === 1) {
    return `${hours}:${momentDate.format('mm')} Hr`;
  } else {
    return `${hours}:${momentDate.format('mm')} Hrs`;
  }
};
