import React, { useContext } from 'react';

import * as S from './components';
import ChatIcon from 'assets/icons/chat.svg';
import ChatContext from '../ChatContext';

const ChatButton = ({ clickHandler }) => {
  const { totalUnreadCount } = useContext(ChatContext);

  return (
    <S.ChatButtonWrapper onClick={clickHandler}>
      <S.ChatImg src={ChatIcon} alt="chat" />
      {totalUnreadCount > 0 && (
        <S.CountWrapper>
          <S.CountContainer>
            <S.CountLabel>{totalUnreadCount}</S.CountLabel>
          </S.CountContainer>
        </S.CountWrapper>
      )}
    </S.ChatButtonWrapper>
  );
};

export default ChatButton;
