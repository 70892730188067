import styled from 'styled-components';

export const Wrapper = styled.ul`
  flex: 1;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const ConversationItemWrapper = styled.li`
  margin-bottom: 0.8rem;
  height: 8rem;
  cursor: pointer;
`;

export const ConversationItem = styled.div`
  width: 100%;
  height: 100%;
  border: 0.1rem solid rgba(172, 188, 212, 0.56);
  padding: 1.6rem;
  box-shadow: 0 1.2rem 2.3rem 0 rgba(0, 0, 0, 0.02);
  border-radius: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ConversationItemLeft = styled.div`
  display: flex;
  align-items: center;
`;

export const AvatarWrapper = styled.div`
  width: 4.8rem;
  height: 4.8rem;
  margin-right: 1.2rem;
`;

export const Name = styled.h2`
  color: ${({ theme }) => theme.colors.black};
  font-size: 1.4rem;
  font-family: inter, assistant;
  font-weight: ${({ unread }) => (unread ? '600' : '400')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 14rem;
`;

export const LastMessage = styled.p`
  max-width: 12rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.black};
  font-size: 1.2rem;
  line-height: 1.67;
  opacity: ${({ unread }) => (unread ? 0.9 : 0.7)};
  font-family: inter, assistant;
  font-weight: ${({ unread }) => (unread ? '500' : '400')};
  margin-top: 0.8rem;
`;

export const Date = styled.span`
  color: #8a94a6;
  font-size: 1.1rem;
  font-family: inter, assistant;
  font-weight: 400;
  line-height: 1.64;
  white-space: nowrap;
`;

export const UnreadCount = styled.div`
  background-color: ${({ theme }) => theme.colors.darkGreen};
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  margin-top: 0.8rem;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-family: inter, assistant;
  font-weight: 600;
`;

export const ConversationItemRight = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const LastMessageFileLabel = styled.p`
  color: ${({ theme }) => theme.colors.black};
  font-family: inter, assistant;
  font-weight: 500;
  text-transform: capitalize;
  font-size: 1.3rem;
  line-height: 1.44;
  margin-left: 0.6rem;
`;

export const LastMessageFileWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.8rem;
`;
