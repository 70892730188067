import React, { useState } from 'react';
import Conversations from '../conversations/Conversations';

import * as S from './components';
import SingleConversation from '../single-conversation/SingleConversation';

const ChatContainer = ({ visible, toggleChat }) => {
  const [conversationShown, setConversationShown] = useState(false);
  return (
    <S.Wrapper visible={visible}>
      <S.ContentWrapper isShown={conversationShown}>
        <Conversations toggleChat={toggleChat} setConversationShown={setConversationShown} />
        <SingleConversation
          isShown={conversationShown}
          setConversationShown={setConversationShown}
          toggleChat={toggleChat}
        />
      </S.ContentWrapper>
    </S.Wrapper>
  );
};

export default ChatContainer;
