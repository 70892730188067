import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import ChatContext from '../ChatContext';
import * as S from './components';
import ChatHeader from '../chat-header/ChatHeader';
import ConversationList from '../conversation-list/ConversationList';
// import SearchInput from '../search-input/SearchInput';
// import SearchedConversationList from '../searched-conversation-list/SearchedConversationList';

import { ReactComponent as InboxIcon } from 'assets/icons/inbox.svg';
import CloseButton from '../close-button/CloseButton';
// import Button from 'components/common/button/Button';

const Conversations = ({ setConversationShown, toggleChat }) => {
  const { t } = useTranslation();
  const { conversations } = useContext(ChatContext);
  // const [searchValue, setSearchValue] = useState('');
  // const [isSearchMode, setIsSearchMode] = useState(false);

  // const toggleSearchMode = () => {
  //   if (!searchValue) {
  //     setIsSearchMode((curr) => !curr);
  //   }
  // };
  return (
    <S.Wrapper>
      <CloseButton toggleChat={toggleChat} />
      <ChatHeader>
        <S.Title>{t('your.conversations')}</S.Title>
      </ChatHeader>

      {!!conversations?.length ? (
        <S.Content>
          {/* <SearchInput
            toggleSearchMode={toggleSearchMode}
            setValue={setSearchValue}
            value={searchValue}
          />
          {isSearchMode ? (
            <>
              <S.SearchingLabel>
                {t('search.for')} "{searchValue}"
              </S.SearchingLabel>
              <S.SearchedConversationsCaption>
                {t('conversations')}
              </S.SearchedConversationsCaption>
              <SearchedConversationList />
            </>
          ) : ( */}
          <ConversationList setConversationShown={setConversationShown} />
          {/* )} */}
        </S.Content>
      ) : (
        <S.NoConversationsContent>
          <InboxIcon />
          <S.NoMessagesLabel>{t('no.messages.yet')}</S.NoMessagesLabel>
          <S.NoMessagesDescription>
            {t('choose.subscription.plan.and.chat.with.your.vet')}
          </S.NoMessagesDescription>
        </S.NoConversationsContent>
      )}
    </S.Wrapper>
  );
};

export default Conversations;
