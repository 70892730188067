import React from 'react';
import { Tooltip } from 'antd';
import { WorkingAreaType } from 'utils/enums';

const TimeSlotWorkingAreas = ({ timeSlot }) => {
  const displayAreas = (wa, index, recordLength) => {
    if (wa.type === WorkingAreaType.polygon) {
      return (
        <span key={`area${wa?.uid}`}>
          {wa?.polygon?.name}
          {!!wa?.polygon?.name && index !== recordLength - 1 && ','}
        </span>
      );
    }
    return (
      <span key={`area${wa?.uid}`}>
        {wa?.zipCode?.zip}
        {!!wa?.zipCode?.zip && index !== recordLength - 1 && ','}
      </span>
    );
  };

  return (
    <div className="working-area-wrapper">
      {timeSlot?.workingAreas
        ?.slice(0, 2)
        .map((wa, index) => displayAreas(wa, index, timeSlot.workingAreas.slice(0, 2).length))}
      {timeSlot?.workingAreas?.length > 2 && (
        <Tooltip
          placement="top"
          title={timeSlot?.workingAreas
            .slice(2, timeSlot.workingAreas.length)
            .map(({ zipCode, uid }, index) => {
              return (
                <span key={`tooltipArea${uid}`}>
                  {zipCode?.zip}
                  {!!zipCode?.zip && index !== timeSlot.workingAreas.length - 3 && ','}
                </span>
              );
            })}>
          <div className="more-btn">{`+${timeSlot?.workingAreas?.length - 2}`}</div>
        </Tooltip>
      )}
    </div>
  );
};

export default TimeSlotWorkingAreas;
