import React, { memo, useState } from 'react';
import { Appointments as AppointmentsNew } from '../../../widgets';
import { NewAppModalWrapper } from './NewAppModalWrapper';
import Appointments from '../../../../pages/vet/routes/appointments/Appointments';
import EditAppointmentAddressModal from '../../../../pages/vet/edit-appointment-address-modal/EditAppointmentAddressModal';

const AppWrapperComponent = ({
  view,
  vet,
  apps,
  activeTimeslot,
  appRoute,
  route,
  calendarDate,
  refetchApps
}) => {
  const [isNewAppointmentsModalOpen, setIsNewAppointmentsModalOpen] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState();
  const [appointmentAddressToEdit, setAppointmentAddressToEdit] = useState(false);

  return (
    <>
      <Appointments
        setAppointmentAddressToEdit={setAppointmentAddressToEdit}
        selectedAppointment={selectedAppointment}
        setSelectedAppointment={setSelectedAppointment}
        getAppointmentsByTimeslotUid={() => {
          refetchApps();
        }}
        selectedTimeslot={activeTimeslot}
        selectedTimeslotId={activeTimeslot?.uid}
        fullTimeSlots={vet?.fullTimeSlots}
        calendarDate={calendarDate}
        timeSlots={vet?.timeSlots}
        vetId={vet?.uid}
        vet={vet}
      />
      <AppointmentsNew
        apps={apps}
        canCreateTS={view === 'timeslots' && activeTimeslot}
        onClickAddress={(app) => setAppointmentAddressToEdit(app)}
        selectedTimeslot={activeTimeslot}
        vet={activeTimeslot?.vet}
        routeId={appRoute?.uid}
        timeSlotId={activeTimeslot?.uid}
        selectedRoute={appRoute?.uid}
        date={calendarDate.format('YYYY-MM-DD')}
        setSelectedAppointment={setSelectedAppointment}
        routeStatus={route?.status}
        setIsNewAppointmentsModalOpen={setIsNewAppointmentsModalOpen}
      />
      <NewAppModalWrapper
        vet={vet}
        refetchApps={() => refetchApps()}
        isNewAppointmentsModalOpen={isNewAppointmentsModalOpen}
        selectedTimeslot={activeTimeslot}
        calendarDate={calendarDate}
        close={() => setIsNewAppointmentsModalOpen(false)}
      />
      {appointmentAddressToEdit && (
        <EditAppointmentAddressModal
          getAppointmentsByTimeslotUid={() => refetchApps()}
          onRequestClose={() => setAppointmentAddressToEdit(null)}
          appointment={appointmentAddressToEdit}
        />
      )}
    </>
  );
};

export const AppWrapper = memo(AppWrapperComponent);
