import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  min-width: 100%;
`;

export const Title = styled.h2`
  font-size: 1.8rem;
  font-family: inter, assistant;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.black};
  line-height: 1.61;
  margin-left: 2rem;
`;

export const Content = styled.div`
  padding: 2rem 2rem 0 2rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: hidden;
`;

export const SearchingLabel = styled.p`
  max-width: 100%;
  font-family: inter, assistant;
  font-weight: 400;
  font-size: 1.4rem;
  color: #112950;
  line-height: 1.14;
  margin-top: 0.4rem;
  opacity: 0.8;
`;

export const SearchedConversationsCaption = styled.h2`
  margin-top: 3.6rem;
  color: #112950;
  font-size: 1.6rem;
  font-family: inter, assistant;
  font-weight: 500;
`;

export const NoConversationsContent = styled.div`
  padding-top: 7.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`;

export const NoMessagesLabel = styled.h2`
  margin-top: 1.6rem;
  color: ${({ theme }) => theme.colors.black};
  font-family: inter, assistant;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 1.61;
`;

export const NoMessagesDescription = styled.p`
  max-width: 25.8rem;
  color: rgba(50, 59, 75, 0.64);
  font-family: inter, assistant;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.5;
  text-align: center;
  margin-top: 1.6rem;
`;

export const ChoosePlanButtonWrapper = styled.div`
  width: 12.4rem;
  height: 3.6rem;
  margin-top: 3.6rem;
`;
