import { useMutation } from '@apollo/client';
import { editAppointmentRoutePetList, createPetMutation } from 'gql';

const useEditAppointmentPatientList = (selectedAppointment) => {
  const [editAppointmentRoutePetListMutation] = useMutation(editAppointmentRoutePetList);
  const [createPet] = useMutation(createPetMutation);

  const handleChangeAppointmentPatientList = async (
    patientsList,
    selectedPetsIndxList,
    newPetsIndxList,
    isRecalculateDuration
  ) => {
    try {
      const apptExistingPetsIds = [];

      // create all pets that does not exist
      for (const petIdx of newPetsIndxList) {
        const pet = patientsList[petIdx];
        const payload = {
          name: pet.name,
          type: pet.type,
          userId: selectedAppointment?.addPatient?.user?.uid
        };
        const newPet = await createPet({
          variables: {
            record: payload
          }
        });
        // add the new pet id
        if (selectedPetsIndxList.includes(petIdx)) {
          patientsList[petIdx].uid = newPet.data?.createPet.uid;
        }
      }

      // create list of appointment's patients, only for existing pets
      apptExistingPetsIds.push(...selectedPetsIndxList.map((idx) => patientsList[idx].uid));

      await editAppointmentRoutePetListMutation({
        variables: {
          record: {
            appointmentRouteId: selectedAppointment?.addPatient?.appointment.appointmentRoute?.uid,
            petIds: apptExistingPetsIds,
            isRecalculateDuration
          }
        }
      });
    } catch (e) {
      throw new Error(e);
    }
  };

  return {
    // updateAppointmentPatientList,
    handleChangeAppointmentPatientList
  };
};

export default useEditAppointmentPatientList;
