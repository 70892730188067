import * as Yup from 'yup';
import { MandatorySelectorFieldValidation, NonMandatorySelectorFieldValidation } from './common';

export const SignUpValidationScheme = Yup.object().shape({
  phonePrefix: Yup.string().required('required'),
  phoneNumber: Yup.string()
    .matches(/^[0-9]+$/, 'must be a number')
    .required('required')
    .min(8, 'Phone number is invalid')
    .max(15, 'too long')
});

export const CodeValidationScheme = Yup.object().shape({
  code: Yup.string()
    .required('Please provide the code')
    .matches(/\d{6}/, 'Must be exactly 6 digits')
});

export const AddPetValidationScheme = Yup.object().shape({
  type: Yup.string().typeError('required'),
  // isFullyVaccinated: MandatorySelectorFieldValidation,
  gender: MandatorySelectorFieldValidation,
  name: Yup.string().required('required').max(200, 'too long'),
  birthDate: Yup.date().required('required'),
  weight: Yup.number().typeError('must be a number'),
  avatar: Yup.string(),
  chipNumber: Yup.string(),
  breed: Yup.string().max(19, 'maximum 19 symbol'),
  allergies: Yup.array(Yup.string().trim()),
  medicalAlerts: Yup.string().max(300, 'too long'),
  behavioralNotes: Yup.string().max(300, 'too long'),
  isSpayed: Yup.boolean(),
  // spayNeuter: "",
  furColor: NonMandatorySelectorFieldValidation,
  foodType: Yup.string().max(100)
});

function noWhitespace() {
  return this.transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value));
}

Yup.addMethod(Yup.number, 'noWhitespace', noWhitespace);

export const UserPersonalDetailsScheme = Yup.object().shape({
  firstName: Yup.string()
    .required()
    .matches(
      /^[^\u0021-\u0026\u0028-\u002C\u002E-\u0040\u005B-\u005E\u007B-\u00FF\u0060\u005F]*$/,
      'alphabet characters only'
    )
    .max(200, 'too long'),
  lastName: Yup.string()
    .required()
    .matches(
      /^[^\u0021-\u0026\u0028-\u002C\u002E-\u0040\u005B-\u005E\u007B-\u00FF\u0060\u005F]*$/,
      'alphabet characters only'
    )
    .max(200, 'too long'),
  email: Yup.string().email().required(),
  comment: Yup.string().max(300, 'too long'),
  // addressInput: Yup.string().required(),
  address: Yup.object().shape({
    // countryCode: Yup.string().required(),
    // city: Yup.string().required(),
    // street: Yup.string().required(),
    // houseNumber: Yup.string().required(),
    // zipCode: Yup.string()
    //   .matches(/^(\d+-?)+\d+$/, 'invalid')
    //   .min(4)
    //   .max(10),
    // floor: Yup.number()
    //   .noWhitespace()
    //   .typeError('Must be a number')
    //   .min(1)
    //   .required(),
    // apartment: Yup.number()
    //   .noWhitespace()
    //   .typeError('Must be a number')
    //   .min(1)
    //   .required(),
    // description: Yup.string().required('required'),
    lat: Yup.number().required('required'),
    lng: Yup.number().required('required')
  })
});
