import React, { useContext } from 'react';
import dayjs from 'dayjs';

import ChatContext from '../ChatContext';
import * as S from './components';
import DynamicAvatar from 'components/dynamic-avatar/DynamicAvatar';
import { getInitialLetters } from 'utils/helpers';
import { ReactComponent as FileIcon } from 'assets/icons/image-file.svg';

const ConversationList = ({ setConversationShown }) => {
  const { conversations, setCurrentConversation } = useContext(ChatContext);
  return (
    <S.Wrapper>
      {Array.isArray(conversations) &&
        conversations.map((item) => {
          const { groupId, lastMessage, user, unreadMessageCount } = item;

          return (
            <S.ConversationItemWrapper
              key={groupId}
              onClick={() => {
                setConversationShown(true);
                setCurrentConversation(item);
              }}>
              <S.ConversationItem>
                <S.ConversationItemLeft>
                  <S.AvatarWrapper>
                    <DynamicAvatar label={getInitialLetters(user.name)} />
                  </S.AvatarWrapper>
                  <div>
                    <S.Name>{user.name}</S.Name>
                    {lastMessage.type === 'text' ? (
                      <S.LastMessage>{lastMessage.text}</S.LastMessage>
                    ) : (
                      <S.LastMessageFileWrapper>
                        <FileIcon />
                        <S.LastMessageFileLabel>{lastMessage.type}</S.LastMessageFileLabel>
                      </S.LastMessageFileWrapper>
                    )}
                  </div>
                </S.ConversationItemLeft>
                <S.ConversationItemRight>
                  <S.Date>{dayjs(lastMessage.sentAt * 1000).from(new Date())}</S.Date>
                  {!!unreadMessageCount && <S.UnreadCount>{unreadMessageCount}</S.UnreadCount>}
                </S.ConversationItemRight>
              </S.ConversationItem>
            </S.ConversationItemWrapper>
          );
        })}
    </S.Wrapper>
  );
};

export default ConversationList;
