import React from 'react';
import cn from 'classnames';
import sum from 'lodash/sum';
import cls from 'classnames';
import { Card, Col, Button, cssText, cssColor, cssSpace, Modal } from '_fsd/shared';
import { message, Row, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAppointments } from '_fsd/widgets/vet-appointments';
import {
  AppointmentRow,
  ListView,
  MapView,
  AppointmentRouteSummaryModal
} from '_fsd/features/vet-appointemnts';
import { PlusOutlined, MenuOutlined } from '@ant-design/icons';
import css from './Appointments.module.css';
import DragAndDrop from '../../../../components/drag-drop/DragDrop';
import { useMutation } from '@apollo/react-hooks';
import { changeVisitOrderMutation } from '../../../../gql';
import { routeStatus as Route } from '../../../../components/timeline/appointments/useTimelineAppointments';
import emptyAppointmentsImg from '../../../../assets/icons/no-appointments.svg';

export const Appointments = ({
  apps,
  openEditDuration,
  onClickAddress,
  setIsNewAppointmentsModalOpen,
  routeId,
  selectedRoute,
  date,
  timeSlotId,
  selectedTimeslot,
  vet,
  setSelectedAppointment,
  routeStatus,
  canCreateTS = true
}) => {
  const { t } = useTranslation();
  const { view, setView, appointments, loading } = useAppointments({
    date,
    timeSlotId,
    apps
  });
  const { petsCount, houseCount } = React.useMemo(() => {
    return {
      petsCount: sum(appointments?.map((a) => a.pets?.length)),
      houseCount: appointments?.length
    };
  }, [appointments]);
  const [modal, setModal] = React.useState(null);
  const [isDragging, setIsDragging] = React.useState(false);
  const [data, setData] = React.useState([]);
  React.useEffect(() => {
    if (appointments?.length) {
      setData(appointments);
    } else {
      setData([]);
    }
  }, [appointments]);
  const [changeVisitOrder] = useMutation(changeVisitOrderMutation);
  const [isRouteSummaryModalOpen, setIsRouteSummaryModalOpen] = React.useState(false);

  const handleSaveReorder = () => {
    changeVisitOrder({
      variables: {
        record: {
          routeId,
          appointmentRouteOrder: data?.map((item, index) => {
            return {
              appointmentRouteId: item?.appointmentRoute?.uid,
              order: index
            };
          })
        }
      },
      refetchQueries: ['getAppointmentsByDate']
    });
    setIsDragging(false);
    message.success(t('appointments_header.reorder_success'));
  };
  const handleCancelReorder = () => {
    setData(appointments);
    setIsDragging(false);
  };
  const handleRouteSummaryAction = () => {
    setIsRouteSummaryModalOpen(!isRouteSummaryModalOpen);
  };

  const routeSummaryText = t('route-summary.title');

  return (
    <Card>
      <Row justify="space-between">
        <Row className={css.gap}>
          <div className={css.title}>
            <span className={cssText.h5}>{t('vet-appointments.title')}</span>
            <div className={css.routeSummary}>
              {petsCount > 0 && (
                <span className={cn(cssText.pRegular, cssColor.cGrayB8)}>
                  {` (${petsCount} ${t(
                    'vet-appointments.petsCountLabel'
                  )}, ${houseCount} ${t('vet-appointments.houseCountLabel')})`}
                </span>
              )}
              {timeSlotId && selectedTimeslot?.routes[0] ? (
                <Tooltip title={routeSummaryText}>
                  <div onClick={handleRouteSummaryAction} className={css.info}>
                    {t('vet-appointments.moreInfo')}
                  </div>
                </Tooltip>
              ) : null}
            </div>
          </div>
        </Row>
        <Row className={css.gap}>
          {routeStatus?.toLowerCase() === Route.ADJUSTMENT && view === 'list' && (
            <>
              {isDragging ? (
                <>
                  <Button onClick={handleCancelReorder}>{t('cancel')}</Button>
                  <Button icon={<MenuOutlined />} onClick={handleSaveReorder}>
                    {t('save')}
                  </Button>
                </>
              ) : (
                <Button icon={<MenuOutlined />} onClick={() => setIsDragging(true)}>
                  {t('vet-appointments.reorderLabel')}
                </Button>
              )}
            </>
          )}
          {canCreateTS ? (
            <Button
              disabled={!selectedTimeslot}
              onClick={() => setIsNewAppointmentsModalOpen(true)}
              type="primary"
              colorScheme="orange"
              icon={<PlusOutlined />}>
              {t('vet-appointments.newAppLabel')}
            </Button>
          ) : null}
        </Row>
      </Row>
      <Row className={css.mt24}>
        <Button
          className={css.btnLeft}
          type={view === 'list' ? 'secondary' : 'default'}
          colorScheme="blue"
          onClick={() => setView('list')}>
          {t('vet-appointments.listViewLabel')}
        </Button>
        <Button
          className={css.btnRight}
          type={view === 'map' ? 'secondary' : 'default'}
          colorScheme="blue"
          onClick={() => setView('map')}>
          {t('vet-appointments.mapViewLabel')}
        </Button>
      </Row>
      <Col className={cls(css.view, { [css.viewMap]: view === 'map' })}>
        {view === 'list' ? (
          <ListView className={cssSpace.f1}>
            {data.length ? (
              <DragAndDrop
                data={data}
                setData={setData}
                isDragDisabled={!isDragging}
                ChildComponent={AppointmentRow}
                openEditDuration={openEditDuration}
                vet={vet}
                setSelectedAppointment={setSelectedAppointment}
                routeStatus={routeStatus}
                onClickAddress={(app) => onClickAddress(app)}
              />
            ) : (
              <div className={css.noAppsContainer}>
                <img src={emptyAppointmentsImg} alt="no" />
                <p className={cssText.s14h22w4}>No Appointments </p>
              </div>
            )}
          </ListView>
        ) : null}
        {view === 'map' ? (
          <MapView
            data={data}
            selectedTimeslot={selectedTimeslot}
            selectedRoute={selectedRoute}
            openModal={setModal}
          />
        ) : null}
      </Col>
      {modal && (
        <Modal
          isOpen={modal}
          forceRender
          className={css.modal}
          title={<span className={cn(cssText.s16h24w5)}>Appointment Details</span>}
          onClose={() => setModal(false)}
          footerProps={{ displayConfirm: false, titleCancel: 'Close' }}>
          <AppointmentRow
            hideHr
            data={modal}
            vet={vet}
            openEditDuration={openEditDuration}
            setSelectedAppointment={setSelectedAppointment}
            routeStatus={routeStatus}
            onClickAddress={() => {
              onClickAddress(modal);
            }}
          />
        </Modal>
      )}
      {isRouteSummaryModalOpen && (
        <AppointmentRouteSummaryModal
          isRouteSummaryModalOpen={isRouteSummaryModalOpen}
          onRequestClose={handleRouteSummaryAction}
          appointments={data}
          timeSlot={selectedTimeslot}
          petsCount={petsCount}
          clientCount={houseCount}
        />
      )}
    </Card>
  );
};

export default React.memo(Appointments);
