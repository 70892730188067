import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  width: 79.4rem;
  height: 58.1rem;
  border-radius: 0.8rem;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 3.2rem;
  ${({ theme }) => theme.mobile`
    width: calc(100vw - 3.2rem);
    padding: 0 0 2.4rem 0;
    border-radius: 0.2rem;
    height: unset;
  `}
`;

export const HeaderWrapper = styled.div`
  display: flex;
  ${({ theme }) => theme.mobile`
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.1rem solid #f0f0f0;
  `}
`;
export const CloseIcon = styled.div`
  display: none;
  ${({ theme }) => theme.mobile`
    display: block;
    padding-right : 2.2rem;
  `}
`;
export const Title = styled.h2`
  font-family: Roboto;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: rgba(0, 0, 0, 0.85);
  ${({ theme }) => theme.mobile`
    height: 5.6rem;
    padding: 1.6rem 2.4rem;
  `}
`;

export const PleaseMessage = styled.h3`
  font-family: Roboto;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 2rem;
  color: rgba(0, 0, 0, 0.64);
  margin-top: 0.4rem;
  ${({ theme }) => theme.mobile`
    display: none;
  `}
`;

export const VetList = styled.ul`
  width: 75rem;
  height: 36.5rem;
  display: flex;
  flex-wrap: wrap;
  gap: 2.4rem;
  overflow: auto;
  margin-top: 2.4rem;
  ::-webkit-scrollbar {
    width: 0.6rem;
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.lightGray};
    border-radius: 1rem;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.colors.lightGray};
  }
  ${({ theme }) => theme.mobile`
    width: calc(100% - 0.4rem);
    gap: 0.8rem 0;
    padding: 2.4rem 1.4rem 2.4rem 2.4rem;
    margin-top: 0;
  `}
`;

export const VetCard = styled.li`
  width: 35.3rem;
  height: 22.5rem;
  border: 0.1rem solid ${({ theme }) => theme.colors.lightGray};
  padding: 2.4rem;
  position: relative;
  cursor: pointer;
  overflow-y: auto;
  ${({ isSelected }) =>
    isSelected &&
    css`
      border: 0.1rem solid #bfbfbf;
    `}

  :hover {
    border: 0.1rem solid ${({ theme }) => theme.colors.orange};
  }

  ${({ theme }) => theme.mobile`
    width: 100%;
    padding: 1.6rem;
    height: 18.7rem;
  `}
`;

export const Button = styled.div`
  right: 1.6rem;
  top: 1.6rem;
  position: absolute;
  width: 1.6rem;
  height: 1.6rem;
  border: 0.1rem solid ${({ theme }) => theme.colors.orange};
  border-radius: 10rem;
  padding: 0.3rem;
`;

export const ButtonCircle = styled.div`
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 2rem;
  background-color: ${({ theme }) => theme.colors.orange};
`;

export const VetMainInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0 2.4rem;
`;

export const VetAvatarWrapper = styled.div`
  min-width: 6.4rem;
  height: 6.4rem;
  border-radius: 10rem;
  background-color: rgba(255, 154, 5, 0.16);
  ${({ theme }) => theme.mobile`
    width: 4.8rem;
    height: 4.8rem;
    min-width: 4.8rem;
  `}
`;

export const VetAvatar = styled.img`
  object-fit: cover;
  width: 6.4rem;
  height: 6.4rem;
  ${({ theme }) => theme.mobile`
    width: 4.8rem;
    height: 4.8rem;
    border-radius: 10rem;
  `}
`;

export const VetInitials = styled.div`
  min-width: 6.4rem;
  height: 6.4rem;
  border-radius: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.orange};
  font-family: Roboto;
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 3.2rem;
  ${({ theme }) => theme.mobile`
    width: 4.8rem;
    height: 4.8rem;
    min-width: 4.8rem;
  `}
`;

export const VetMain = styled.main`
  display: flex;
`;

export const VetInfo = styled.div`
  margin-left: 2.4rem;
  ${({ theme }) => theme.mobile`
    margin-left: 1.6rem;
  `}
`;

export const Name = styled.h2`
  font-family: Roboto;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: rgba(0, 0, 0, 0.85);
`;

export const Experience = styled.h3`
  font-family: Roboto;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 2rem;
  color: ${({ theme }) => theme.colors.darkGreen};
  margin-top: 0.2rem;
  margin-bottom: 0.8rem;
`;

export const Languages = styled.ul`
  margin-top: 0.8rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.4rem;
`;

export const Language = styled.li`
  height: 2.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.1rem 0.8rem;
  background: ${({ theme }) => theme.colors.snowWhite};
  border: 0.1rem solid ${({ theme }) => theme.colors.lightGray};
  border-radius: 0.2rem;
  color: rgba(0, 0, 0, 0.85);
  font-family: Roboto;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 2rem;
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 4rem;
  ${({ theme }) => theme.mobile`
    margin-top: 3.2rem;
    justify-content: center;
  `}
`;

export const ButtonsWrapper = styled.div`
  width: 17.6rem;
  height: 4rem;
  display: flex;
  gap: 0 0.8rem;
  ${({ theme }) => theme.mobile`
    width: 28rem;
    justify-content: center;
  `}
`;

export const CancelButton = styled.span`
  font-family: Roboto;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.8rem 1.6rem;
  border: 0.1rem solid ${({ theme }) => theme.colors.lightGray};
  cursor: pointer;
  ${({ theme }) => theme.mobile`
   padding: 0.8rem 4.4rem;
    height: 4rem;
  `}
`;

export const ChooseButton = styled(CancelButton)`
  background-color: ${({ theme }) => theme.colors.orange};
  border: 0.1rem solid ${({ theme }) => theme.colors.orange};
  color: ${({ theme }) => theme.colors.white};
`;
