import { useMemo } from 'react';
import { timeSlotsToDateObject } from 'common_repo/utils/helpers';
import { timeSlotIsFull, timeSlotsFilterHelper } from './timeSlot';
import moment from 'moment/moment';

const DATE_FORMAT_FULL = 'YYYY-MM-DD';

export const getTimeslotsInDate = ({
  loading,
  timeslots: rawTimeslots,
  filters = {},
  timeslotActions = {},
  setDates = () => {},
  startDate,
  isForAdminCalendar
}) => {
  return useMemo(() => {
    if (!loading) {
      const timeslots = rawTimeslots?.map((t) => ({
        ...t,
        isFull: timeSlotIsFull(t, t?.routes?.[0]?.appointmentRoutes || [])
      }));
      let datesRaw = {};
      if (!timeslots || !timeslots.length) {
        datesRaw = {};
      } else if (filters.displayFullTS) {
        datesRaw = timeSlotsToDateObject({
          timeslots,
          startDate,
          isForVet: true,
          isForAdminCalendar
        });
      } else {
        datesRaw = timeSlotsToDateObject({
          timeslots: timeSlotsFilterHelper(timeslots),
          startDate,
          isForVet: true,
          isForAdminCalendar
        });
      }
      const date = timeslotActions.calendarDate?.format(DATE_FORMAT_FULL);
      const filtered = datesRaw[date] || [];
      if (
        timeslotActions?.setActiveTimeslot &&
        !filtered.some((ts) => ts.uid === timeslotActions.activeTimeslot?.uid)
      ) {
        timeslotActions?.setActiveTimeslot();
      }
      setDates(datesRaw);
      return filtered;
    }
    return [];
  }, [timeslotActions.calendarDate, loading, filters.displayFullTS, rawTimeslots]);
};
