import styled, { css } from 'styled-components';

export const BiographyWrapper = styled.div`
  width: 21.7rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 1.6rem;
  margin-left: 8.8rem;
  position: relative;
  ${({ theme }) => theme.mobile`
    width: unset;
    margin-left: 6.4rem;    
  `}
`;

export const BiographyTitle = styled.h3`
  font-family: Roboto;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 2rem;
  color: rgba(0, 0, 0, 0.45);
  margin-bottom: 0.2rem;
`;

export const Biography = styled.p`
  width: 18.5rem;
  font-family: Roboto;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 2rem;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgba(0, 0, 0, 0.85);
  ${({ showFullText }) =>
    showFullText &&
    css`
      -webkit-line-clamp: unset;
    `};

  ${({ theme }) => theme.mobile`
    -webkit-line-clamp: 2;   
    width: 12.6rem;
    ${({ showFullText }) =>
      showFullText &&
      css`
        -webkit-line-clamp: unset;
      `};
  `}
`;

export const SeeMore = styled.h3`
  font-family: Roboto;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 2rem;
  color: rgb(0, 0, 0);
  position: absolute;
  right: 0;
  bottom: 0;
`;
