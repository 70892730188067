import React from 'react';
import AppointmentsMapView from '../../../../../pages/vet/routes/appointments/AppointmentViews/AppointmentsMapView';
import css from './MapView.module.css';

export const MapView = ({
  existingAppointments,
  currentAppointmentLocation,
  workingArea,
  startEndPoints,
  data,
  selectedTimeslot,
  selectedRoute,
  openModal
}) => {
  return (
    <div className={css.container}>
      <AppointmentsMapView
        openModal={openModal}
        appointmentsData={data}
        selectedTimeslot={selectedTimeslot}
        currentAppointmentLocation={currentAppointmentLocation}
        existingAppointments={existingAppointments}
        workingArea={selectedTimeslot?.workingAreas?.[0]?.polygon?.area || workingArea}
        startEndPoints={
          selectedRoute
            ? {
                startPoint: selectedRoute?.startPoint,
                endPoint: selectedRoute?.endPoint
              }
            : startEndPoints
        }
      />
    </div>
  );
};
