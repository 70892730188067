import React, { useContext, useEffect } from 'react';
import { cssText, cssColor, Button } from '_fsd/shared';
import cls from 'classnames';
import { Input, Table, DatePicker, Col, Form, Row } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import useColumns from './useColumns';
import useCustomersData from './useCustomersData';
import css from './Customers.module.css';
import './customers-styles.css';
import AuthContext from '../../../../../contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CustomerIcon } from '../../../../../assets/icons/customer.svg';
import { ReactComponent as PowIcon } from '../../../../../assets/icons/pow.svg';

const orderConverter = (orderType) => {
  if (orderType === 'ascend') {
    return 'asc';
  } else if (orderType === 'descend') {
    return 'desc';
  } else return null;
};
const useTotalsListArray = ({ customerTotals, t }) => {
  return [
    {
      uid: 1,
      title: t('admin.totals.number_of_customers'),
      value: customerTotals?.totalCustomers
    },
    {
      uid: 7,
      title: t('admin.totals.number_pets'),
      value: customerTotals?.petsAmount
    }
  ];
};

const Customers = ({ onClickNewClient, setEditClient }) => {
  const { t } = useTranslation();
  const { Search } = Input;
  const { RangePicker } = DatePicker;
  const { user } = useContext(AuthContext);

  const {
    customersData,
    setName,
    name,
    setSearch,
    setSortBy,
    countries,
    page,
    setPage,
    subscriptionStatus,
    loading,
    totalData,
    registrationDate,
    setRegistrationDate,
    customerTotals,
    sortBy,
    petName,
    setPetName
  } = useCustomersData(user.uid);
  const columns = useColumns(sortBy, setEditClient);

  const totalsListArray = useTotalsListArray({ customerTotals, t });
  const onSearch = (value) => {
    setPage(1);
    setSearch(value);
  };

  const filterCustomerName = (e) => {
    setName(e.target.value);
  };

  const onDateChange = (values) => {
    setRegistrationDate(
      values
        ? {
            from: dayjs(values[0]).format(),
            to: dayjs(values[1]).format()
          }
        : null
    );
  };

  const getPageNumber = (page, pageSize) => {
    setPage(page);
  };

  useEffect(() => {
    setPage(1);
  }, [registrationDate, subscriptionStatus, countries, petName, name]);

  const changeSort = (sorter) => {
    const sortObject = {};
    if (Array.isArray(sorter)) {
      for (let i = 0; i < sorter.length; i++) {
        sortObject[sorter[i].field] = orderConverter(sorter[i].order);
      }
      setSortBy((prev) => {
        return { ...prev, ...sortObject };
      });
    } else if (!!Object.keys(sorter).length) {
      const { field, order } = sorter;
      setSortBy((prev) => {
        return {
          registrationDate: null,
          fullName: null,
          country: null,
          cardExpiration: null,
          subscriptionDate: null,
          subscriptionDuration: null,
          spendingAmount: null,
          petAmount: null,
          status: null,
          [field]: orderConverter(order)
        };
      });
    }
  };

  return (
    <div className={css.container}>
      <div className={css.header}>
        <div className={css.headerCounts}>
          <span className={cssText.s16h24w5}>Clients</span>
          <span className={cls(cssText.s13h18w4, cssColor.c8A94A6)}>
            {`Total customers: ${totalsListArray[0]?.value} | Total pets: ${totalsListArray[1]?.value}`}
          </span>
        </div>
        <Button type="default" icon={<PlusOutlined />} onClick={onClickNewClient}>
          Add Client
        </Button>
      </div>

      <div className={css.filters}>
        <Row gutter={24}>
          <Col xs={24} lg={6}>
            <Form.Item label={t('search')} className="fixed-size-input">
              <Search
                placeholder={t('customers.search.placeholder')}
                onChange={(e) => onSearch(e.target.value)}
                onSearch={onSearch}
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={6}>
            <Form.Item label={t('customers.client.name')} className="fixed-size-input">
              <Input
                onChange={filterCustomerName}
                prefix={<CustomerIcon />}
                size="small"
                placeholder={t('customers.customer.placeholder')}
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={6}>
            <Form.Item label={t('customers.pets.name')} className="fixed-size-input">
              <Input
                onChange={(e) => setPetName(e.target.value)}
                prefix={<PowIcon />}
                size="small"
                placeholder={t('customers.pets.placeholder')}
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={6}>
            <Form.Item label={t('Next appt date')} className="fixed-size-input">
              <RangePicker placeholder={['From', 'To']} onChange={onDateChange} />
            </Form.Item>
          </Col>
        </Row>
      </div>

      <Table
        columns={columns}
        dataSource={customersData}
        rowKey="userId"
        loading={loading}
        onChange={(pagination, filters, sorter) => {
          changeSort(sorter);
        }}
        pagination={{
          pageSize: 10,
          onChange: getPageNumber,
          total: totalData,
          current: page
        }}
      />
    </div>
  );
};

export default Customers;
