import { getAppointmentsByDateQuery } from 'gql';
import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

export const useGetAppointments = ({ date, timeSlotId, apps }) => {
  const { data, loading, error } = useQuery(getAppointmentsByDateQuery, {
    variables: {
      record: { date, timeSlotId }
    },
    fetchPolicy: 'network-only',
    skip: !date || !timeSlotId || apps
  });
  return useMemo(
    () => ({
      data: apps?.length ? apps : data?.getAppointmentsByDate,
      loading,
      error
    }),
    [data, apps, loading, error]
  );
};
