import React from 'react';
import Carousel, { Modal, ModalGateway } from 'react-images';

import GalleryCustomView from 'components/gallery-custom-view/GalleryCustomView';

const Gallery = ({ files, currIndex = 0, setGalleryVisible }) => {
  return (
    <ModalGateway>
      <Modal closeOnBackdropClick={true} onClose={setGalleryVisible}>
        <Carousel currentIndex={currIndex} views={files} components={{ View: GalleryCustomView }} />
      </Modal>
    </ModalGateway>
  );
};

export default Gallery;
