import styled, { css } from 'styled-components';

export const Form = styled.form``;
export const DigitsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 0.8rem;
`;
export const DigitInput = styled.input`
  width: 6.4rem;
  height: 6.4rem;
  text-align: center;
  font-family: inter, assistant;
  font-size: 3.2rem;
  font-weight: 600;
  line-height: 0.91;
  color: ${({ theme }) => theme.colors.black};
  border: none;
  outline: none;
  border-radius: 8px;
  border: 0.1rem solid #f3f3f3;
  box-shadow: 0 1.2rem 2.3rem 0 rgba(55, 125, 255, 0.06);
  user-select: none;
  ::selection {
    color: ${({ theme }) => theme.colors.black};
    background: none;
  }
  &:focus {
    border: 1px solid #f9b057;
  }

  ${({ theme }) => theme.mobile`
      width: 3.6rem;
      height: 3.6rem;
      font-size: 1.6rem;
    `}
  ${({ error }) =>
    error &&
    css`
      border: 0.1rem solid #e44e61;
    `}
  ${({ isForModal }) =>
    isForModal &&
    css`
      border-radius: 0.2rem;
      box-shadow: none;
    `}
`;

export const ResendCodeButtonWrapper = styled.div`
  position: relative;
`;

export const QuestionButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ResendCodeButton = styled.button`
  border: none;
  outline: none;
  font-size: 1.6rem;
  color: #26acb9;
  background: transparent;
  font-family: inter, assistant;
  font-weight: 500;
  cursor: pointer;
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.75;
      cursor: default;
    `};
  ${({ theme }) => theme.mobile`
      font-size: ${({ isForModal }) => (isForModal ? '1.2rem' : '1.6rem')}
 `}
`;

export const BottomSectionContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;
export const RemainingTime = styled.span`
  font-family: inter, assistant;
  font-weight: 500;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.5;
  color: #323b4b;
`;

export const Error = styled.span`
  font-family: inter, assistant;
  font-weight: 400;
  font-size: 1.4rem;
  color: #e44e61;
  display: block;
  margin-top: 1rem;
`;

export const CodeSentMobile = styled.div`
  visibility: hidden;
  opacity: 0;
  ${({ visible }) =>
    visible &&
    css`
      visibility: visible;
      opacity: 1;
    `}
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.8rem;
  height: 3.8rem;
  background: rgba(0, 0, 0, 0.75);
  border-radius: 2px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  color: #ffffff;
  position: absolute;
  top: -4.2rem;
  width: 10.1rem;
  &::after {
    content: '';
    width: 0;
    height: 0;
    border-left: 0.5rem solid transparent;
    border-right: 0.5rem solid transparent;
    border-top: 0.5rem solid rgba(0, 0, 0, 0.75);
    position: absolute;
    left: calc(50% - 0.5rem);
    bottom: -0.5rem;
  }
`;

export const CodeSent = styled.div`
  visibility: hidden;
  opacity: 0;
  ${({ visible }) =>
    visible &&
    css`
      visibility: visible;
      opacity: 1;
    `}
  transition: all 0.5s ease-in-out;
  position: absolute;
  display: flex;
  align-items: center;
  padding: 1rem;
  font-family: inter, assistant;
  font-weight: 500;
  border-radius: 0.8rem;
  background: ${({ theme }) => theme.colors.white};
  font-size: 1.6rem;
  right: 0;
  box-shadow: 0 -0.2rem 3.2rem -1.6rem rgba(0, 0, 0, 0.04);
  ::before {
    position: absolute;
    content: '';
    width: 1rem;
    height: 1rem;
    background: #fff;
    transform: translateY(-50%) rotate(45deg);
    top: 50%;
    left: -0.4rem;
  }
  ${({ theme }) => theme.mobile`
    position: fixed;
    top:  1.2rem;
    left: 3.2rem;
    width: 19.2rem;
    ::before{
      display:none;
    }
  `}
`;

export const ResentText = styled.p`
  color: #b0b7c3;
  font-family: inter, assistant;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2.4rem;
`;
