import styled, { css } from 'styled-components';

export const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 2rem;
  padding-top: 0;
`;

export const DropDownHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 7.2rem;
  cursor: pointer;
`;
export const DropDownWrapper = styled.div`
  border-bottom: 1px solid #d0d9e7;
`;

export const DropDownHeaderTitle = styled.span`
  font-family: inter, assistant;
  font-weight: 400;
  font-size: 1.4rem;
`;

export const IconWrapper = styled.div`
  transition: all 0.3s ease-out;
  ${({ open }) =>
    open &&
    css`
      transform: rotate(180deg);
    `}
`;

export const DropDownContent = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 1.2rem;

  ${({ column }) =>
    column &&
    css`
      flex-direction: column;
    `}
`;

export const SearchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 7.2rem;
  border-bottom: 1px solid #d0d9e7;
`;

export const SearchText = styled.span`
  font-family: inter, assistant;
  font-weight: 400;
  font-size: 1.4rem;
  color: #323b4b;
`;

export const SearchIconWrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  background: transparent;
  width: 4rem;
  height: 4rem;
  cursor: pointer;
  :hover {
    background: #f2f4f7;
    border-radius: 0.8rem;
  }
`;
