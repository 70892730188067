import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 53.2rem;
  height: 35.2rem;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 0.2rem;
  ${({ theme }) => theme.mobile`
      width : 32.8rem;
      height : auto;
 `}
`;

export const Header = styled.header`
  width: 100%;
  height: 5.6rem;
  padding: 1.6rem 2.4rem;
  display: flex;
  justify-content: space-between;
  border-bottom: 0.1rem solid rgb(240, 240, 240);
`;

export const Title = styled.h2`
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: ${({ theme }) => theme.colors.black};
`;

export const Main = styled.main`
  width: 100%;
  padding: 2.4rem;
`;

export const MainTitle = styled.h3`
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #8a94a6;
  max-width: 42.7rem;
  margin-bottom: 2.4rem;
  ${({ theme }) => theme.mobile`
      font-size: 1.2rem;
 `}
`;

export const PhoneNumber = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #ff9a05;
  ${({ theme }) => theme.mobile`
      font-size: 1.2rem;
 `}
`;
