import React from 'react';
import { videoFormats } from 'constants/videoFormats';
import { GalleryImage, GalleryItemViewWrapper, GalleryVideo } from './components';

const GalleryCustomView = ({ data }) => {
  const source = data?.url;
  const splitted = source?.split('.');
  const ext = splitted?.[splitted.length - 1];
  return (
    <GalleryItemViewWrapper>
      {videoFormats.includes(ext) ? (
        <GalleryVideo controls>
          <source src={source} type="video/mp4" />
          <source src={source} type="video/ogg" />
          Your browser does not support the video tag.
        </GalleryVideo>
      ) : (
        <GalleryImage src={source} alt="attachment" />
      )}
    </GalleryItemViewWrapper>
  );
};

export default GalleryCustomView;
