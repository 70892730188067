import React, { useMemo, useCallback, useEffect, useRef, useState } from 'react';
import { GoogleMap, Polygon, DrawingManager } from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';
import { Col, Row, Input } from 'antd';
import LoaderWrapper from '../../loaderWrapper/LoaderWrapper';
import RemoveIcon from 'assets/icons/trash-gray.svg';
import { findCenter } from 'utils/helpers';

const { google } = window;

const Map = ({
  path,
  setPath,
  polygonError,
  polygonName,
  setPolygonName,
  lastPathCoordinates,
  setLastPathCoordinates,
  startPoint
}) => {
  const { t } = useTranslation();

  const defaultCenter = useMemo(
    () =>
      path?.length
        ? findCenter(path)
        : lastPathCoordinates?.lat
          ? lastPathCoordinates
          : {
              lat: startPoint?.lat || 38.1843034,
              lng: startPoint?.lng || -120.7975979
            },
    []
  );

  const [mapRef, setMapRef] = useState(null);

  const polygonRef = useRef(null);
  const listenersRef = useRef([]);
  const onEdit = useCallback(() => {
    if (polygonRef.current) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map((latLng) => {
          return { lat: latLng.lat(), lng: latLng.lng() };
        });
      setPath(nextPath);
    }
  }, [setPath]);

  // Bind refs to current Polygon and listeners
  const onLoad = useCallback(
    (polygon) => {
      polygonRef.current = polygon;
      const path = polygon.getPath();
      listenersRef.current.push(
        path.addListener('set_at', onEdit),
        path.addListener('insert_at', onEdit),
        path.addListener('remove_at', onEdit)
      );
    },
    [onEdit]
  );

  // Clean up refs
  const onUnmount = useCallback(() => {
    listenersRef.current.forEach((lis) => lis.remove());
    polygonRef.current = null;
  }, []);

  const getShapeCoordinates = (shape) => {
    return shape
      .getPath()
      .getArray()
      .map((item) => {
        return { lat: item.lat(), lng: item.lng() };
      });
  };

  const onDrawComplete = (shape) => {
    const nextPath = getShapeCoordinates(shape);
    setPath(nextPath);
    setLastPathCoordinates(findCenter(nextPath));
    google?.maps?.event?.clearInstanceListeners(shape);
    shape.setMap(null);
    addButtonToMap();
  };

  const addButtonToMap = useCallback(() => {
    const controlButton = document.createElement('button');
    const deleteImg = document.createElement('img');
    const span = document.createElement('span');
    span.innerHTML = 'Clear';
    deleteImg.src = RemoveIcon;
    controlButton.append(deleteImg);
    controlButton.append(span);
    controlButton.classList.add('delete-btn');

    controlButton.addEventListener('click', (e) => {
      e.preventDefault();
      mapRef.controls[google?.maps?.ControlPosition.BOTTOM_CENTER].pop();
      setPath([]);
    });
    if (!mapRef.controls[google?.maps?.ControlPosition.BOTTOM_CENTER]?.length) {
      mapRef.controls[google?.maps?.ControlPosition.BOTTOM_CENTER].push(controlButton);
    }
  }, [mapRef]);

  useEffect(() => {
    if (mapRef && path.length) {
      addButtonToMap();
    }
  }, [mapRef, path]);

  useEffect(() => {
    if (google && mapRef) {
      const onBoundsChanged = () => {
        const button = mapRef.controls[google?.maps?.ControlPosition.BOTTOM_CENTER]?.Sd?.[0];
        const topButtons = document.getElementsByClassName('gmnoprint');

        if (
          mapRef.getDiv().firstChild.clientHeight === window.innerHeight &&
          mapRef.getDiv().firstChild.clientWidth === window.innerWidth
        ) {
          if (button) button.classList.add('full-screen-btn');
          if (topButtons) {
            Array.from(topButtons).forEach((item) => item.classList.add('full-screen-top-btn'));
          }
        } else {
          if (button) button.classList.remove('full-screen-btn');
          if (topButtons) {
            Array.from(topButtons).forEach((item) => item.classList.remove('full-screen-top-btn'));
          }
        }
      };

      window.google.maps.event.addListener(mapRef, 'bounds_changed', onBoundsChanged);
    }
  }, [google, mapRef]);

  return (
    <>
      <Row style={{ marginBottom: 12 }}>
        <Col span={12}>
          <Input
            defaultValue={polygonName}
            onChange={(e) => setPolygonName(e.target.value)}
            placeholder={t('polygon.name')}
            whitespace
          />
        </Col>
      </Row>
      <GoogleMap
        mapContainerClassName="App-map"
        center={defaultCenter}
        zoom={7}
        onLoad={(map) => setMapRef(map)}
        version="weekly"
        options={{
          fullscreenControl: true,
          streetViewControl: false,
          gestureHandling: 'cooperative'
        }}>
        <LoaderWrapper isLoading={!mapRef}>
          <DrawingManager
            onPolygonComplete={onDrawComplete}
            options={{
              drawingControl: true,
              drawingControlOptions: {
                drawingModes: path.length ? [''] : ['polygon']
              },
              polygonOptions: { editable: true },
              drawingMode: path.length ? '' : 'polygon'
            }}
          />

          {path?.length && (
            <Polygon
              editable
              draggable
              path={path}
              onMouseUp={onEdit}
              onDragEnd={onEdit}
              onLoad={onLoad}
              onUnmount={onUnmount}
            />
          )}
        </LoaderWrapper>
      </GoogleMap>
      {/* </LoaderWrapper>*/}
      {polygonError && <h3 className="ant-form-item-explain-error">{t('error.polygon')}</h3>}
    </>
  );
};

export default Map;
