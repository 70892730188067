import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const PetName = styled.h2`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: inter, assistant;
  font-weight: 400;
  font-size: 1.4rem;
  flex: 1;
  padding-right: 0.5rem;
  max-width: 10rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  opacity: 0.72;
  color: ${({ theme }) => theme.colors.white};

  ${({ isPetActive }) => isPetActive && 'opacity: 1'};
`;

export const PetAvatarWrapper = styled.div`
  width: 1.6rem;
  height: 1.6rem;
  margin-right: 2.4rem;
  border-radius: 50%;
  display: flex;
`;

export const PetAvatar = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: ${({ isPetActive, theme }) =>
    isPetActive ? `0.1rem solid ${theme.colors.orange}` : '0.1rem solid rgba(255, 255, 255, 0.2)'};
  padding: ${({ hasAvatar }) => (hasAvatar ? '0.01rem' : '0.3rem')};
`;
export const SubMenuLink = styled(NavLink)`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;

  &.active {
    background-color: rgba(0, 0, 0, 0.08);
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.08);
  }
`;
