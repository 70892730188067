import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import cls from 'classnames';
import css from './Modal.module.css';
import { Button, ButtonColors } from '../button/Button';
import textCss from '../../styles/text.module.css';
import debounce from 'lodash/debounce';
import { ReactComponent as CloseIcon } from 'assets/icons/close-gray.svg';

const ModalHeader = (props) => {
  const { icon, title, onClose, centeredTitle, hideClose = false } = props;
  return (
    <div className={css.header}>
      {centeredTitle && <div />}
      <div className={css.headerTitleContainer}>
        {icon && <div className={css.headerIcon}>{icon}</div>}
        <span className={cls(css.headerTile, textCss.s16h24w5)}>{title}</span>
      </div>
      {!hideClose && (
        <Button
          type="text"
          className={css.headerCloseButton}
          icon={<CloseIcon />}
          onClick={onClose}
          shape="circle"
        />
      )}
    </div>
  );
};

const ModalFooter = (props) => {
  const {
    onClose,
    onConfirm,
    footerClassName,
    reverseButtonsPosition,
    buttonCancelProps: buttonCancelPropsCustom,
    buttonConfirmProps: buttonConfirmPropsCustom,
    buttonSecondaryConfirmProps,
    titleCancel = 'Cancel',
    titleConfirm = 'Ok',
    displayCancel = true,
    displayConfirm = true,
    displaySecondaryConfirm = false,
    align = 'end'
  } = props;
  const buttonCancelProps = {
    onClick: () => onClose(),
    type: 'default',
    children: titleCancel,
    ...(buttonCancelPropsCustom || {})
  };
  const buttonConfirmProps = {
    onClick: () => onConfirm(),
    type: 'primary',
    colorScheme: ButtonColors.BLUE,
    children: titleConfirm,
    ...(buttonConfirmPropsCustom || {})
  };
  return (
    <div
      className={cls(css.footer, footerClassName, {
        [css.footerLeft]: align === 'start',
        [css.footerRight]: align === 'end',
        [css.footerCenter]: align === 'center',
        [css.footerReverse]: reverseButtonsPosition
      })}>
      {displayCancel && <Button {...buttonCancelProps} />}
      {displaySecondaryConfirm && <Button {...buttonSecondaryConfirmProps} />}
      {displayConfirm && <Button {...buttonConfirmProps} />}
    </div>
  );
};

const ModalComponent = (props) => {
  const {
    isOpen,
    children,
    onClose,
    onConfirm,
    customHeader,
    title,
    icon,
    centeredTitle,
    customFooter,
    footerProps = {},
    className
  } = props;

  const ref = useRef();

  const [margin, setMargin] = useState(window.innerHeight / 10);

  const { current: updateMargin } = useRef(
    debounce(() => {
      const windowHeight = window.innerHeight;
      const contentHeight = ref.current?.offsetHeight;
      if (contentHeight > windowHeight) {
        setMargin(0);
      } else if (contentHeight > windowHeight - 200) {
        setMargin((windowHeight - contentHeight) / 4);
      } else {
        setMargin(window.innerHeight / 10);
      }
    }, 200)
  );
  const { current: resizeObserver } = useRef(
    new window.ResizeObserver((entry) => {
      updateMargin();
    })
  );
  useEffect(() => {
    updateMargin();
    resizeObserver.observe(ref.current);
    const resizeListener = () => updateMargin();
    window.addEventListener('resize', resizeListener);
    return () => {
      window.removeEventListener('resize', resizeListener);
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <div className={cls(css.overlay, { [css.display]: isOpen })} onClick={() => onClose()}>
      <div
        ref={ref}
        className={cls(css.body, className)}
        style={{ marginTop: margin }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}>
        {customHeader ? (
          customHeader
        ) : (
          <ModalHeader title={title} icon={icon} onClose={onClose} centeredTitle={centeredTitle} />
        )}
        <div>{children}</div>
        {customFooter ? (
          customFooter
        ) : (
          <ModalFooter onConfirm={onConfirm} onClose={onClose} {...footerProps} />
        )}
      </div>
    </div>
  );
};

export const Modal = (props) => {
  const { isOpen, force = true } = props;

  return (force && isOpen) || !force
    ? createPortal(<ModalComponent {...props} />, document.body)
    : null;
};
