import styled from 'styled-components';

export const CloseIconWrapper = styled.div`
  position: absolute;
  top: 1.2rem;
  right: 1.4rem;
  cursor: pointer;
  z-index: 1;
  transition: none;
`;

export const CloseIconImg = styled.div`
  width: 3.2rem;
  height: 3.2rem;
  padding: 0.6rem;

  & svg {
    fill: #9094ac;
  }
`;
