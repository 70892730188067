import { useCallback } from 'react';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import {
  getVetTimeSlotsByUserAddressQuery,
  updateAppointmentUserComment,
  updateAppointmentVetCommentMutation
} from 'gql';

const useAppointments = () => {
  const [updateUserComment] = useMutation(updateAppointmentUserComment);
  const [updateVetComment] = useMutation(updateAppointmentVetCommentMutation);

  const updateAppUserComment = async (appointmentId, comment) => {
    return await updateUserComment({
      variables: {
        record: {
          appointmentId,
          comment
        }
      }
    });
  };

  const updateAppVetComment = async (appointmentId, vetComment) => {
    return await updateVetComment({
      variables: {
        record: {
          appointmentId,
          vetComment
        }
      }
    });
  };

  const [getTimeSlots, { data: timeSlotsInArea }] = useLazyQuery(getVetTimeSlotsByUserAddressQuery);

  const fetchTimeSlots = useCallback((vetId, value) => {
    if (value?.__typename) delete value?.__typename;
    getTimeSlots({
      variables: {
        record: {
          uid: vetId,
          address: value,
          isForCustomers: false
        }
      }
    });
  }, []);

  return {
    fetchTimeSlots,
    timeSlotsInArea,
    updateAppUserComment,
    updateAppVetComment
  };
};

export default useAppointments;
