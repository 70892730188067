import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import AvatarGroup from 'components/avatar-group/AvatarGroup';
import AppointmentPopover from './AppointmentPopover';
import Button from 'components/common/button/Button';
import Modal from 'components/common/modal/Modal';
import EtaTooltip from './EtaTooltip';

import { ReactComponent as CloseIcon } from 'assets/icons/close-gray.svg';

import './styles.css';
import './style.scss';

const AppointmentMobileModal = ({ onRequestClose, appointment, setSelectedAppointment }) => {
  const { t } = useTranslation();

  const { uid, user, userComment, pets, ...rest } = appointment;

  const isEtaExists = useCallback((app) => {
    return app?.appointmentRoute?.etaStart && app?.appointmentRoute?.etaEnd;
  }, []);

  return (
    <Modal zIndex={1001} isOpen={true} onRequestClose={onRequestClose} isMobile isTablet autoHeight>
      <div className="appointment-mobile-modal-wrapper">
        <div className="appointment-mobile-modal-header">
          <div className="appointment-mobile-modal-header__title">
            {t('patient_details.add_info_vet')}
          </div>
          <CloseIcon onClick={onRequestClose} />
        </div>
        <div className="appointment-mobile-modal-content">
          <div className="single-appointment-item">
            <div className="appointment-row">
              <div className="appointment-row-left">
                <div>
                  <div className="name-and-location">
                    <p className="person-name">
                      {user?.firstName} {user?.lastName}
                    </p>
                    <p className="location">{rest?.appointmentRoute?.address?.description}</p>
                  </div>
                  {userComment && !!userComment.length && (
                    <div className="cc-and-content">
                      <p className="cc">CC:</p>
                      <p className="cc-content">{userComment}</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="appointment-row-right">
                <AvatarGroup pets={pets.map((p) => p?.pet)} />
              </div>
            </div>
          </div>
          <div className="appointment-mobile-modal-content__eta-wrapper">
            <EtaTooltip appointment={rest} isEtaExists={isEtaExists} />
          </div>
          <AppointmentPopover
            setSelectedAppointment={setSelectedAppointment}
            uid={uid}
            appointment={rest}
            user={user}
          />
        </div>
        <div className="appointment-mobile-modal-footer">
          <Button label={t('cancel')} className="cancel-btn" onClick={onRequestClose} />
        </div>
      </div>
    </Modal>
  );
};

export default AppointmentMobileModal;
