import React from 'react';

import * as S from './components';
import { ReactComponent as CloseIcon } from 'assets/icons/close-gray.svg';

const CloseButton = ({ toggleChat }) => {
  return (
    <S.CloseIconWrapper onClick={toggleChat}>
      <S.CloseIconImg>
        <CloseIcon />
      </S.CloseIconImg>
    </S.CloseIconWrapper>
  );
};

export default CloseButton;
