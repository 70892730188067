import styled from 'styled-components';

export const WidgetContainer = styled.div`
  position: fixed;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
  bottom: 4rem;
  right: 4rem;
  z-index: 10;
  ${({ theme, isOpen }) => theme.mobile`
    width: 100%;
    flex: 1;
    top: 7.2rem;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: ${isOpen ? '10' : '-1'};
    align-items: unset;
  `}
`;
