import React from 'react';
import moment from 'moment';
import { Popconfirm, Popover } from 'antd';
import {
  PlusSquareTwoTone,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
// import {DATE_TIME_FORMAT} from 'common/service/rule-generator/constants';
// import {getPetOwnersQuery, updateNextAppointmentPetEstimationDateMutation} from 'gql';
import { petImages } from 'constants/pet';
import './styles.css';

const useEditClientModalColumns = (
  setIsPetModalOpen,
  setPetIdToUpdate,
  handleRemovePet,
  setEditPet
) => {
  const { t } = useTranslation();

  const content = (
    <div className="delete-popover">
      <ExclamationCircleFilled className="warning-icon" />
      {t('cancel.appointments.before.removing')}
    </div>
  );

  const errorContent = (
    <div className="delete-popover">
      <ExclamationCircleFilled className="warning-icon" />
      {t('this.pet.has.appointments')}
    </div>
  );

  const petColumns = [
    {
      title: 'Pet Name',
      dataIndex: 'petName',
      key: 'petName',
      align: 'center',
      className: 'pet-name-column',
      render: (text, record) => (
        <div className="column-style">
          <img
            className="pet-images"
            src={record?.avatarUrl ? record?.avatarUrl : petImages?.[record?.type?.name]}
            alt="petImage"
          />
          <span className="pet-name">{record?.name}</span>
        </div>
      )
    },
    {
      title: 'Pet Type',
      dataIndex: 'petType',
      key: 'petType',
      align: 'center',
      render: (text, record) => <div>{record?.type?.name}</div>
    },
    {
      title: 'Age',
      dataIndex: 'age',
      key: 'age',
      align: 'center',
      render: (text, record) => (
        <div>{record?.birthDate ? moment(record?.birthDate).from(new Date(), true) : ''}</div>
      )
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      key: 'gender',
      align: 'center'
    },
    {
      title: t('client-modal.next-appointment-column.title'),
      dataIndex: 'nextAppointment',
      key: 'nextAppointment',
      align: 'center',
      render: (text, pet) => {
        let dateString;

        if (pet.nextAppointmentDate) {
          dateString = moment(pet.nextAppointmentDate).format(t('date-time-format.full-date'));
        } else if (pet.nextAppointmentEstimation?.date) {
          dateString =
            t('client-modal.next-appointment-column.estimated-appt-short') +
            ' ' +
            moment(pet.nextAppointmentEstimation?.date).format(t('date-time-format.full-date'));
        } else {
          dateString = t('client-modal.next-appointment-column.no-appointments');
        }

        return <div>{dateString}</div>;
      }
    },
    {
      title: (
        <PlusSquareTwoTone
          className="plus-icon"
          onClick={() => {
            setEditPet && setEditPet({});
          }}
        />
      ),
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (text, record) => {
        const petCanBeDeleted = record?.nextAppointmentDate < moment().format('YYYY-MM-DD');
        return (
          <div className="edit-wrapper">
            <EditOutlined
              className="edit-icon"
              onClick={() => {
                // setPetIdToUpdate && setPetIdToUpdate(record.uid);
                setEditPet && setEditPet(record);
              }}
            />
            {petCanBeDeleted ? (
              <Popconfirm
                placement="top"
                title={t('are.you.sure.remove.pet')}
                okText="Yes"
                cancelText="No"
                onConfirm={() => handleRemovePet(record.uid)}
                id="delete-popconfirm">
                <Popover content={content} trigger="hover">
                  <DeleteOutlined className="pet-table-delete-icon" />
                </Popover>
              </Popconfirm>
            ) : (
              <Popover content={errorContent} trigger="hover">
                <DeleteOutlined className="pet-table-delete-icon" />
              </Popover>
            )}
          </div>
        );
      }
    }
  ];

  return {
    petColumns
  };
};

export default useEditClientModalColumns;
