import { useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { message } from 'antd';
import { createTimeSlotsMutation, getZipCodesQuery } from 'gql';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

const useCreateVetTimeSlots = (closeModal, calendarDate, refetchAuthVet, vet) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [createTimeSlot] = useMutation(createTimeSlotsMutation, {
    refetchQueries: ['getAuthVet']
  });

  const [getZipCodes] = useLazyQuery(getZipCodesQuery);

  const fetchZipCodes = (zip) => {
    return getZipCodes({
      variables: {
        zip
      },
      skip: !zip || zip?.length < 2
    });
  };

  const parseZipCodesData = (options) => {
    return options?.data?.getZipCodes?.map((z) => ({
      label: z.zip,
      value: z.uid
    }));
  };

  const onSubmit = async (timeSlot, addressPoints) => {
    setLoading(true);
    const args = {
      timeSlots: [
        {
          ...timeSlot,
          date: dayjs(timeSlot.date).format('YYYY-MM-DD'),
          type: 'work_availability',
          oldDate: calendarDate
        }
      ]
    };
    if (addressPoints && Object.keys(addressPoints).length > 0) {
      args.timeSlots[0].startPoint = addressPoints.startPoint;
      args.timeSlots[0].endPoint = addressPoints.endPoint;
    }
    if (vet) {
      args.vetId = vet?.uid;
    }
    try {
      await createTimeSlot({
        variables: {
          record: args
        }
      });
      refetchAuthVet();
      message.success(t('updated.successfully'));
      closeModal();
    } catch (e) {
      const error = e?.graphQLErrors?.[0]?.message;
      message.error(error || timeSlot.uid ? t('failed.to.update') : t('failed.to.create'), 10);
    }
    setLoading(false);
  };

  return { onSubmit, fetchZipCodes, parseZipCodesData, loading };
};

export default useCreateVetTimeSlots;
