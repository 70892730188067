import React from 'react';
import { useHistory } from 'react-router';
import { MenuItem, StyledNavLink, MenuItemButton, FlexItem } from './components';

const SingleMenuItem = ({
  to,
  icon,
  menuTitle,
  onClick,
  onPress,
  children,
  subMenuVisible,
  setSidebarOpen,
  isButton,
  isLogOut,
  as,
  isForVet
}) => {
  const history = useHistory();
  const isPetsMenuActive = history.location.pathname.split('/')[1] === 'pets';

  const clickHandler = (e) => {
    if (onClick) {
      onClick(e);
    } else {
      e.preventDefault();
      history.push({
        ...history,
        pathname: to,
        search: history.location.search
      });
    }
    if (setSidebarOpen) {
      setSidebarOpen(false);
    }
  };

  return (
    <MenuItem onClick={onPress} pets={subMenuVisible} isForVet={isForVet}>
      {isButton ? (
        <MenuItemButton
          onClick={clickHandler}
          isLogOut={isLogOut}
          isPetsMenuActive={isPetsMenuActive}>
          <FlexItem>
            {icon}
            <h2> {menuTitle}</h2>
          </FlexItem>
          {children}
        </MenuItemButton>
      ) : (
        <StyledNavLink
          as={as}
          isActive={
            to !== '#'
              ? (match, location) => location.pathname === to
              : (match, location) => location.search === '?chatOpen=true'
          }
          exact
          to="#"
          onClick={clickHandler}>
          <FlexItem>
            {icon}
            <h2> {menuTitle}</h2>
          </FlexItem>
          {children}
        </StyledNavLink>
      )}
    </MenuItem>
  );
};

export default SingleMenuItem;
