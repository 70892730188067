import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import ChatContext from '../ChatContext';
import ChatHeader from '../chat-header/ChatHeader';
import CloseButton from '../close-button/CloseButton';
import ChatMenu from '../chat-menu/ChatMenu';
import SharedFile from '../shared-files/SharedFile';
import Gallery from 'components/gallery/Gallery';
import DynamicAvatar from 'components/dynamic-avatar/DynamicAvatar';
import ErrorModal from 'components/common/error-modal/ErrorModal';
import LoaderWrapper from 'components/loaderWrapper/LoaderWrapper';
import { ReactComponent as LeftArrowIcon } from 'assets/icons/arrow-left.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus-black.svg';
import { ReactComponent as SendIcon } from 'assets/icons/send.svg';
import { ReactComponent as PlayIcon } from 'assets/icons/play.svg';
import { getInitialLetters } from 'utils/helpers';
import theme from 'styles/theme';
import * as S from './components';
import AuthContext from '../../../contexts/AuthContext';

dayjs.extend(relativeTime);

const SingleConversation = ({ isShown, setConversationShown, toggleChat }) => {
  const { user } = useContext(AuthContext);
  const { firstName, lastName } = user || {};
  const { t } = useTranslation();

  const {
    currentConversation,
    setCurrentConversation,
    currentMessages,
    currentMedia,
    loadingMessages,
    sendTextMessage,
    sendMediaMessage,
    sendingMessage,
    isCurrentConversationReadOnly,
    isErrorModalOpen,
    setIsErrorModalOpen
  } = useContext(ChatContext);

  const [text, setText] = useState('');
  const [previewModeItems, setPreviewModeItems] = useState([]);
  const [selectedItemIndex, setSelectedItemIndex] = useState(0);
  const [showAdditionalInfo, setShowAdditionalInfo] = useState(false);

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    if (isShown) {
      messagesEndRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const sendMessage = () => {
    if (text) {
      sendTextMessage(text);
      setText('');
    }
  };

  useEffect(scrollToBottom, [currentMessages, isShown]);

  const sendMedia = (e) => {
    sendMediaMessage(e?.target?.files?.[0]);
    e.target.value = '';
  };

  const handleCloseErrorModal = () => {
    setIsErrorModalOpen(false);
  };

  return (
    <S.Wrapper>
      <CloseButton
        toggleChat={() => {
          setConversationShown(false);
          toggleChat();
          setCurrentConversation(null);
        }}
      />
      <ChatHeader>
        <S.BackIconWrapper
          onClick={() => {
            if (showAdditionalInfo) {
              setShowAdditionalInfo(false);
            } else {
              setConversationShown(false);
              setCurrentConversation(null);
            }
          }}>
          <LeftArrowIcon />
        </S.BackIconWrapper>

        <S.ToolTopWrapper>
          <a data-tip data-for="infoText">
            <S.HeaderHoverWrapper
              onClick={() => {
                setShowAdditionalInfo((curr) => !curr);
              }}>
              <S.AvatarWrapper>
                <DynamicAvatar
                  fs="1.3rem"
                  label={getInitialLetters(currentConversation?.user?.name)}
                />
              </S.AvatarWrapper>
              <S.Name>{currentConversation?.user?.name}</S.Name>
            </S.HeaderHoverWrapper>
          </a>
          <ReactTooltip
            id="infoText"
            type="warning"
            backgroundColor={theme.colors.black}
            textColor={theme.colors.white}
            effect="solid">
            <span>{t('chat.info')}</span>
          </ReactTooltip>
        </S.ToolTopWrapper>
      </ChatHeader>
      {showAdditionalInfo ? (
        <ChatMenu />
      ) : (
        <LoaderWrapper isLoading={loadingMessages}>
          <S.Content>
            <div ref={messagesEndRef} />
            {currentMessages.map(({ text, id, sentAt, isOwned, attachment, type, user }, index) => {
              const avatarShow = currentMessages?.[index - 1]?.user?.id !== user.id;
              const chooseMedia = () => {
                const selectedIndex = currentMedia?.findIndex(
                  (item) => item?.url === attachment?.url
                );
                setSelectedItemIndex(selectedIndex);
                setPreviewModeItems(currentMedia);
              };
              return (
                <S.MessageContainer key={id} isMe={isOwned}>
                  <S.MessageWrapper>
                    {text && <S.Message isMe={isOwned}>{text}</S.Message>}
                    {attachment && type === 'image' && (
                      <img
                        src={attachment?.url}
                        alt="attachment"
                        width="200"
                        onClick={chooseMedia}
                      />
                    )}
                    {attachment && type === 'video' && (
                      <S.VideoWrapper onClick={chooseMedia}>
                        <video
                          controls={false}
                          src={attachment?.url}
                          alt="attachment"
                          width="200"
                        />
                        <S.VideoLayer>
                          <S.VideoIconWrapper>
                            <PlayIcon />
                          </S.VideoIconWrapper>
                        </S.VideoLayer>
                      </S.VideoWrapper>
                    )}
                    {attachment && type === 'file' && (
                      <SharedFile {...attachment} type="file" maxFileNameLength={20} />
                    )}
                    {avatarShow && (
                      <S.MessageAvatarWrapper isMe={isOwned}>
                        <DynamicAvatar
                          fs="1rem"
                          label={
                            isOwned
                              ? getInitialLetters(null, firstName, lastName)
                              : getInitialLetters(currentConversation?.user?.name)
                          }
                        />
                      </S.MessageAvatarWrapper>
                    )}
                  </S.MessageWrapper>
                  <S.MessageDate
                    isMe={isOwned}
                    title={dayjs(new Date(sentAt * 1000)).format('DD/MM/YYYY HH:mm')}>
                    {dayjs(sentAt * 1000).from(new Date())}
                  </S.MessageDate>
                </S.MessageContainer>
              );
            })}
          </S.Content>
        </LoaderWrapper>
      )}
      {isCurrentConversationReadOnly && !showAdditionalInfo && (
        <S.ReadOnlyFooter>
          <S.ReadOnlyLabel>{t('read.only')}</S.ReadOnlyLabel>
          <S.ReadOnlyDescription>{t('read.only.description')}</S.ReadOnlyDescription>
        </S.ReadOnlyFooter>
      )}
      {!isCurrentConversationReadOnly && !showAdditionalInfo && (
        <S.Footer>
          <ErrorModal
            isOpen={isErrorModalOpen}
            handleCloseModal={handleCloseErrorModal}
            errorMessage={t('format.not.supported')}
          />
          <S.PlusIconWrapper>
            <input type="file" onChange={sendMedia} hidden id="attach_file" />
            <label htmlFor="attach_file" style={{ cursor: 'pointer' }}>
              <PlusIcon />
            </label>
          </S.PlusIconWrapper>
          <S.InputWrapper>
            <S.InputPadding>
              <S.Input
                value={text}
                placeholder={t('write.your.message')}
                onKeyDown={(e) => {
                  if ((e.altKey || e.ctrlKey || e.shiftKey) && e.key === 'Enter') {
                    e.preventDefault();
                    sendMessage();
                  }
                }}
                onChange={(e) => {
                  setText(e.target.value);
                }}
              />
            </S.InputPadding>
            <S.SendButton onClick={sendMessage} disabled={sendingMessage}>
              <SendIcon />
            </S.SendButton>
          </S.InputWrapper>
        </S.Footer>
      )}
      {!!previewModeItems?.length && (
        <Gallery
          files={previewModeItems}
          setGalleryVisible={() => setPreviewModeItems([])}
          currIndex={selectedItemIndex}
        />
      )}
    </S.Wrapper>
  );
};

export default SingleConversation;
