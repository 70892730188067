import React, { useContext, useEffect, useState } from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  clientCommunicationConstants,
  clientsPerHourConstants,
  preferenceTypeConstants,
  remindersConstants
} from 'constants/preferences';
import LoaderWrapper from 'components/loaderWrapper/LoaderWrapper';
import { isJsonString } from 'utils/helpers';
import PreferenceSupportedServices from './preferenceItems/PreferenceSupportedServices';
import PreferenceNotification from './preferenceItems/PreferenceNotification';
import PreferenceRangeInput from './preferenceItems/PreferenceRangeInput';
import PreferenceAddress from './preferenceItems/PreferenceAddress';
import AddServiceModal from './preferenceItems/AddServiceModal';
import usePreferences from './usePreferences';
import './style.scss';
import AuthContext from '../../contexts/AuthContext';

const { Title } = Typography;

const Preferences = () => {
  const { t } = useTranslation();
  const { user: me } = useContext(AuthContext);

  const [reminders, setReminders] = useState();
  const [concerns, setConcerns] = useState([]);
  const [preferences, setPreferences] = useState([]);
  const [communications, setCommunications] = useState([]);
  const [endPointAddress, setEndPointAddress] = useState('');
  const [selectedService, setSelectedService] = useState(null);
  const [startPointAddress, setStartPointAddress] = useState('');
  const [isNewServiceModalOpen, setIsNewServiceModalOpen] = useState(false);

  const {
    userSettings,
    loading,
    handleUpdateUserSetting,
    handleCreateUserConcern,
    handleRemoveUserConcern,
    concernsList,
    loadingCreateUserConcern,
    loadingRemoveUserConcern
  } = usePreferences(me?.uid);

  useEffect(() => {
    const userConcernsArray = [];

    if (concernsList?.length) {
      concernsList?.forEach(({ itemValue, concern, duration, isBookable, isSelected, uid }) => {
        userConcernsArray.push({
          value: uid,
          duration,
          isBookable,
          isSelected,
          label: itemValue || concern?.name
        });
      });
      setConcerns(userConcernsArray);
    }

    if (userSettings?.length) {
      const communicationsArray = [];
      const remindersObj = {};
      const preferencesArray = [];
      let startPoint = '';
      let endPoint = '';

      userSettings?.forEach(({ uid, itemValue, setting, userConcerns }) => {
        const item = {
          uid,
          itemValue,
          minValue: setting?.minValue,
          maxValue: setting?.maxValue,
          description: setting?.description,
          defaultValue: setting?.defaultSetting?.messageContent?.text,
          languageCode: setting?.defaultSetting?.messageContent?.languageCode
        };

        if (remindersConstants[item?.description]?.key) {
          if (remindersConstants[item?.description]?.parent && item?.languageCode === 'en') {
            remindersObj[remindersConstants[item?.description]?.parent] = item;
          }
        }
        if (clientCommunicationConstants[item?.description]) {
          communicationsArray.push(item);
        }
        if (clientsPerHourConstants[item?.description]) {
          preferencesArray.push(item);
        }

        if (setting?.description === clientsPerHourConstants.DEFAULT_START_POINT) {
          startPoint = isJsonString(item?.itemValue)
            ? JSON.parse(item?.itemValue)
            : item?.itemValue;
        }
        if (setting?.description === clientsPerHourConstants.DEFAULT_END_POINT) {
          endPoint = isJsonString(item?.itemValue) ? JSON.parse(item?.itemValue) : item?.itemValue;
        }
      });

      setReminders(remindersObj);
      setCommunications(communicationsArray);
      setPreferences(preferencesArray);
      setStartPointAddress(startPoint);
      setEndPointAddress(endPoint);
    }
  }, [userSettings, concernsList]);

  const handleToggleNewServiceModal = (service) => {
    if (service?.value) setSelectedService(service);
    else setSelectedService(null);

    setIsNewServiceModalOpen((prevState) => !prevState);
  };

  const handleAddressChange = (itemValue, uid) => {
    setPreferences(
      preferences?.map((setting) => {
        if (setting.uid === uid) {
          setting.itemValue = itemValue;
        }
        return setting;
      })
    );
  };

  const getPreferenceUiByKey = (description, itemValue, uid, minValue, maxValue, isFirstChild) => {
    if (
      description === clientsPerHourConstants.ETA_WINDOW_SIZE ||
      description === clientsPerHourConstants.DEFAULT_APPOINTMENT_DURATION ||
      // description === clientsPerHourConstants.CLIENTS_PER_HOUR || //TODO: ???
      description === clientsPerHourConstants.FIRST_TIME_VISIT_ADDITIONAL_TIME
    ) {
      return (
        <PreferenceRangeInput
          uid={uid}
          description={description}
          itemValue={itemValue}
          minValue={minValue}
          maxValue={maxValue}
          isFirstChild={isFirstChild}
          step={description === clientsPerHourConstants.ETA_WINDOW_SIZE ? 15 : 1}
          handleUpdateUserSetting={handleUpdateUserSetting}
        />
      );
    }

    if (description === clientsPerHourConstants.DEFAULT_START_POINT) {
      return (
        <PreferenceAddress
          uid={uid}
          isFirstChild={isFirstChild}
          description={description}
          address={startPointAddress}
          onChange={setStartPointAddress}
          onSelect={(area) => {
            handleAddressChange(area, uid, preferenceTypeConstants.ANOTHER);
            handleUpdateUserSetting(uid, area);
          }}
        />
      );
    }

    if (description === clientsPerHourConstants.DEFAULT_END_POINT) {
      return (
        <PreferenceAddress
          uid={uid}
          isFirstChild={isFirstChild}
          description={description}
          address={endPointAddress}
          onChange={setEndPointAddress}
          onSelect={(area) => {
            handleAddressChange(area, uid, preferenceTypeConstants.ANOTHER);
            handleUpdateUserSetting(uid, area);
          }}
        />
      );
    }
  };

  return (
    <LoaderWrapper isLoading={loading}>
      <div className="settings-wrapper">
        <Title level={2} className="settings-text settings-title main-title">
          {t('url.settings')}
        </Title>

        {/* Notifications and messages */}
        <div className="preferences-layout">
          <Title level={3} className="settings-text settings-sub_title">
            {t('preferences.customer_notifications_title')}
          </Title>
          <Title level={4} className="settings-text settings-sub_description">
            {t('preferences.customer_notifications_description')}
          </Title>
          {communications?.map(({ description, uid, itemValue }, i) => (
            <PreferenceNotification
              key={uid}
              description={description}
              uid={uid}
              itemValue={itemValue}
              handleUpdateUserSetting={handleUpdateUserSetting}
              isFirstChild={i === 0}
              reminder={reminders[description]}
            />
          ))}
        </div>

        {/* Route Default settings */}
        <div className="preferences-layout">
          <Title level={3} className="settings-text settings-sub_title">
            {t('preferences.route_title')}
          </Title>
          <Title level={4} className="settings-text settings-sub_description">
            {t('preferences.route_description')}
          </Title>
          {preferences?.map(({ description, uid, itemValue, minValue, maxValue }, i) => {
            return getPreferenceUiByKey(description, itemValue, uid, minValue, maxValue, i === 0);
          })}
        </div>

        {/* Concerns */}
        <div className="preferences-layout supported-services-layout">
          <PreferenceSupportedServices
            concerns={concerns}
            handleToggleNewServiceModal={handleToggleNewServiceModal}
          />
        </div>
      </div>
      {isNewServiceModalOpen && (
        <AddServiceModal
          selectedService={selectedService}
          onRequestClose={handleToggleNewServiceModal}
          handleRemoveUserConcern={handleRemoveUserConcern}
          handleCreateUserConcern={handleCreateUserConcern}
          loadingCreateUserConcern={loadingCreateUserConcern}
          loadingRemoveUserConcern={loadingRemoveUserConcern}
        />
      )}
    </LoaderWrapper>
  );
};

export default Preferences;
