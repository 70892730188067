import styled from 'styled-components';

export const VetInfoWrapper = styled.div`
  width: 24.8rem;
  height: fit-content;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 0.4rem;
  padding: 2.4rem;
  box-shadow: 0 4px 45px -31px rgba(45, 28, 28, 0.08);
  ${({ theme, isBooking }) =>
    isBooking &&
    theme.laptopSm`
    width: 100%;
    min-height: 21.8rem;
    height: fit-content;
    padding: 1.6rem 1.6rem 0 1.6rem;
    margin-bottom : 1.6rem;
  `}
  ${({ theme }) => theme.tabletXl`
    width: 100%;
    min-height: 21.8rem;
    height: fit-content;
    padding: 1.6rem 1.6rem 0 1.6rem;
    margin-bottom : 1.6rem;
  `}
`;

export const VetAvatarWrapper = styled.div`
  width: 5.6rem;
  height: 5.6rem;
  border-radius: 10rem;
  background-color: rgba(255, 154, 5, 0.16);
`;

export const VetAvatar = styled.img`
  object-fit: cover;
  width: 5.6rem;
  height: 5.6rem;
  border-radius: 10rem;
`;

export const VetInitials = styled.div`
  width: 5.6rem;
  height: 5.6rem;
  border-radius: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.orange};
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 3.2rem;
`;

export const VetName = styled.h2`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: rgba(0, 0, 0, 0.85);
  margin-top: 0.8rem;
`;

export const Experience = styled.h2`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2.2rem;
  color: ${({ theme }) => theme.colors.darkGreen};
  margin-top: 0.2rem;
`;

export const Languages = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 0.4rem;
  margin-top: 0.9rem;
`;

export const Language = styled.li`
  height: 2.2rem;
  padding: 0.1rem 0.8rem;
  border: 0.1rem solid ${({ theme }) => theme.colors.lightGray};
  border-radius: 0.2rem;
  background-color: ${({ theme }) => theme.colors.snowWhite};
  color: rgba(0, 0, 0, 0.85);
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 2rem;
  align-items: center;
  justify-content: center;
`;

export const AboutMeTitle = styled.h3`
  font-family: Roboto;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 2rem;
  color: rgba(0, 0, 0, 0.45);
  margin-top: 1.6rem;
`;

export const AboutMe = styled(AboutMeTitle)`
  font-family: Roboto;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  margin-top: 0.4rem;
  white-space: pre-wrap;
  word-break: break-word;
`;

export const ChooseAnotherVet = styled(AboutMeTitle)`
  font-family: Roboto;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.25);
  cursor: pointer;
  :hover {
    color: ${({ theme }) => theme.colors.darkGreen};
  }
  ${({ theme }) => theme.tabletXl`
    height: 4.4rem;
    margin: 1.6rem -1.6rem 0 -1.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 0.1rem solid #f0f0f0;
  `}
`;

export const PersonalInfoWrapper = styled.div`
  width: 77.4rem;
  height: 57.4rem;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 0.4rem;
  padding: 2.4rem 4rem;
  ${({ theme }) => theme.tabletXl`
    width: 100%;
  `}
`;
