import React, { useState, useEffect, memo } from 'react';

import { useTranslation } from 'react-i18next';
import { Upload, Form, Tooltip, message } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { PlusOutlined, DeleteFilled } from '@ant-design/icons';

import useUpload from 'services/useUpload';
import plusIcon from 'assets/icons/add-photo.svg';

import { ReactComponent as Eye } from 'assets/icons/visibility.svg';
import * as S from './components';
import theme from 'styles/theme';
import './styles.css';

const UploadImage = ({
  label,
  name,
  form,
  formInitialValues,
  hasVisibilitySign,
  setImageUrl,
  imageUrl
}) => {
  const { uploadFile } = useUpload();
  const { t } = useTranslation();
  const [isPrevImgDeleted, setIsPrevImgDeleted] = useState(false);

  const isTablet = useMediaQuery({
    query: `(max-width: ${theme.screenSizes.tabletL}px)`
  });

  useEffect(() => {
    formInitialValues.avatarUrl && !isPrevImgDeleted && setImageUrl(formInitialValues.avatarUrl);
  }, [formInitialValues]);

  return (
    <S.Upload className="vet-profile-upload">
      <Form.Item
        name={name}
        label={
          !isTablet && (
            <div>
              {label}
              {hasVisibilitySign && (
                <Tooltip title={t('info.will.be.visible')}>
                  <Eye />
                </Tooltip>
              )}
            </div>
          )
        }>
        <Upload
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          beforeUpload={(file) => {
            if (imageUrl && file.type.includes('image')) {
              setIsPrevImgDeleted(true);
              setImageUrl(null);
            }
          }}
          maxCount={0}
          action={(file) => {
            if (file.type.includes('image')) {
              uploadFile(file).then((res) => {
                form.setFieldsValue({ [name]: res });
                setImageUrl(res);
              });
            } else {
              message.error(t('wrong.file.type'));
            }
          }}>
          {imageUrl ? (
            <div style={{ position: 'relative' }}>
              <img src={imageUrl} alt="avatar" className="avatar-image" />

              {imageUrl && (
                <>
                  <S.DeleteIcon
                    onClick={(e) => {
                      e.stopPropagation();
                      setImageUrl(null);
                      setIsPrevImgDeleted(true);
                    }}>
                    <DeleteFilled />
                  </S.DeleteIcon>

                  <S.AddIcon src={plusIcon} alt="" />
                </>
              )}
            </div>
          ) : (
            <div>
              <PlusOutlined />
            </div>
          )}
        </Upload>
      </Form.Item>
    </S.Upload>
  );
};

export default memo(UploadImage);
