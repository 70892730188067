import React, { useEffect, useState } from 'react';
import { GoogleMap, Marker, InfoWindow, Polygon } from '@react-google-maps/api';
import { validatePoint } from 'utils/helpers';
import { AppointmentMapTooltip } from '_fsd/features/vet-appointemnts';
import { getColor, getIconMarker } from '../../../../../_fsd/features/admin-map-vets';

const center = {
  lat: 37.7749,
  lng: -122.4194
};

const startPoint =
  'https://kumba.s3.eu-west-1.amazonaws.com/Manual+uploads/Product/icons/map/start.png';
const endPoint =
  'https://kumba.s3.eu-west-1.amazonaws.com/Manual+uploads/Product/icons/map/end.png';
const activeColor = 0;

const AppointmentsMapView = ({
  appointmentsData = [],
  workingArea = [],
  startEndPoints = null,
  openModal,
  existingAppointments,
  currentAppointmentLocation
}) => {
  const [map, setMap] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(-1);

  const onMapLoad = React.useCallback((map, err) => {
    setMap(map);
  }, []);

  useEffect(() => {
    if (map) {
      const bounds = new window.google.maps.LatLngBounds();
      appointmentsData.forEach((appt) =>
        bounds.extend(
          new window.google.maps.LatLng(
            appt?.appointmentRoute?.address?.lat,
            appt?.appointmentRoute?.address?.lng
          )
        )
      );
      workingArea.forEach(({ lat, lng }) => bounds.extend(new window.google.maps.LatLng(lat, lng)));
      if (existingAppointments?.length) {
        existingAppointments.forEach((appt) =>
          bounds.extend(new window.google.maps.LatLng(appt?.address?.lat, appt?.address?.lng))
        );
      }
      if (currentAppointmentLocation) {
        bounds.extend(
          new window.google.maps.LatLng({
            lat: currentAppointmentLocation?.lat,
            lng: currentAppointmentLocation?.lng
          })
        );
      }
      if (validatePoint(startEndPoints?.startPoint) && validatePoint(startEndPoints?.endPoint)) {
        bounds.extend(
          new window.google.maps.LatLng({
            lat: startEndPoints?.startPoint?.lat,
            lng: startEndPoints?.startPoint?.lng
          })
        );
        bounds.extend(
          new window.google.maps.LatLng({
            lat: startEndPoints?.endPoint?.lat,
            lng: startEndPoints?.endPoint?.lng
          })
        );
      }

      map.panTo(bounds.getCenter());
      map.fitBounds(bounds, 50);
    }
  }, [map, appointmentsData, startEndPoints, currentAppointmentLocation, existingAppointments]);

  return (
    <GoogleMap
      mapContainerStyle={{
        height: '100%',
        width: '100%'
      }}
      center={center}
      zoom={13}
      onLoad={onMapLoad}
      version="weekly"
      options={{
        // fullscreenControl: false,
        // streetView: false,
        streetViewControl: false,
        mapTypeControl: false,
        gestureHandling: 'cooperative'
        // styles: mapStyle,
      }}>
      {map ? (
        <>
          {workingArea && (
            <Polygon
              paths={[workingArea]}
              options={{
                fillColor: getColor(activeColor),
                fillOpacity: 0.12,
                strokeColor: getColor(activeColor),
                strokeOpacity: 1,
                strokeWeight: 2
              }}
            />
          )}
          {appointmentsData?.map((appt, index) => {
            const route = appt?.appointmentRoute;
            return (
              <Marker
                key={index}
                zIndex={2}
                position={{ lat: route?.address?.lat, lng: route?.address?.lng }}
                icon={{
                  url:
                    'data:image/svg+xml;charset=UTF-8,' +
                    encodeURIComponent(
                      getIconMarker(route?.order >= 0 ? route?.order : 30, 0, false)
                    ),
                  scaledSize: new window.google.maps.Size(30, 30)
                }}
                onClick={() => {
                  setSelectedMarker(appt); // This is just an example of how you could track the selected marker
                }}>
                {(!selectedMarker || selectedMarker === appt) && (
                  <InfoWindow onCloseClick={() => setSelectedMarker(-1)}>
                    <AppointmentMapTooltip data={appt} openModal={openModal} />
                  </InfoWindow>
                )}
              </Marker>
            );
          })}
          {existingAppointments?.map((appt, index) => (
            <Marker
              key={index}
              position={{ lat: appt?.address?.lat, lng: appt?.address?.lng }}
              icon={{
                url:
                  'data:image/svg+xml;charset=UTF-8,' +
                  encodeURIComponent(getIconMarker(appt?.order >= 0 ? appt?.order : 30, 11, true)),
                scaledSize: new window.google.maps.Size(25, 25)
              }}
            />
          ))}
          {currentAppointmentLocation && (
            <Marker
              position={{
                lat: currentAppointmentLocation?.lat,
                lng: currentAppointmentLocation?.lng
              }}
              icon={{
                url:
                  'data:image/svg+xml;charset=UTF-8,' +
                  encodeURIComponent(getIconMarker(30, activeColor)),
                scaledSize: new window.google.maps.Size(30, 30)
              }}
            />
          )}

          {startEndPoints && (
            <>
              <Marker
                key={'startPoint'}
                position={{
                  lat: startEndPoints?.startPoint?.lat,
                  lng: startEndPoints?.startPoint?.lng
                }}
                icon={startPoint}
                zIndex={1}
              />
              <Marker
                key={'endPoint'}
                position={{
                  lat: startEndPoints?.endPoint?.lat,
                  lng: startEndPoints?.endPoint?.lng
                }}
                icon={endPoint}
                zIndex={0}
              />
            </>
          )}
        </>
      ) : (
        'Loading maps...'
      )}
      ¸
    </GoogleMap>
  );
};

export default AppointmentsMapView;
