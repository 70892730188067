import styled from 'styled-components';
import PlayIcon from 'assets/icons/play.svg';

export const Image = styled.img`
  width: 7.2rem;
  height: 7.2rem;
  border-radius: 0.4rem;
  margin-bottom: 0.8rem;
  background: transparent url('https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif') center
    no-repeat;
  background-size: contain;
  cursor: pointer;
`;

export const VideoWrapper = styled.div`
  position: relative;
  margin-bottom: 0.8rem;
  margin-right: 0.8rem;
  display: inline-block;
  cursor: pointer;

  ::after {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: '';
    width: 3.6rem;
    height: 3.6rem;
    background-image: url(${PlayIcon});
  }
`;
export const Video = styled.video`
  width: 7.2rem;
  height: 7.2rem;
  border-radius: 0.4rem;

  object-fit: cover;
`;

export const FileWrapper = styled.div`
  display: flex;
  margin-bottom: 15px;
  :last-child {
    margin-bottom: 0;
  }
`;

export const ExtensionIcon = styled.div`
  position: absolute;
  font-family: inter, assistant;
  font-weight: 500;
  top: 1.5rem;
  width: 3rem;
  background: ${({ background }) => background};
  text-transform: uppercase;
  height: 1.4rem;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.2rem;
`;

export const TextsWrapper = styled.div`
  margin-left: 1.6rem;
`;

export const FileName = styled.a`
  opacity: 0.8;
  font-family: inter, assistant;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.4;
  color: #36475f;
`;

export const FileSize = styled.span`
  opacity: 0.8;
  font-family: inter, assistant;
  font-weight: 400;
  font-size: 11px;
  line-height: 1.64;
  color: #8a94a6;
`;

export const FileExtension = styled(FileSize)`
  margin-left: 1rem;
`;
