import React from 'react';
import * as S from './components';
import { ReactComponent as Document } from 'assets/icons/document.svg';
import { formatFileSize } from 'utils/helpers';

const colors = {
  pdf: '#ff563f',
  default: '#22a3c3'
};
const SharedFile = ({ type, url, name, extension, size, maxFileNameLength }) => {
  if (type !== 'file') return;
  return (
    <S.FileWrapper>
      <div style={{ position: 'relative' }}>
        <Document />
        <S.ExtensionIcon title={extension} background={colors[extension] || colors.default}>
          {extension?.length > 4 ? extension.substr(0, 2) + '...' : extension}
        </S.ExtensionIcon>
      </div>
      <S.TextsWrapper>
        <S.FileName href={url} target="_blank" rel="noreferrer" title={name}>
          {name.length > 40
            ? name.substr(0, maxFileNameLength ? maxFileNameLength : 25) + '....' + extension
            : name}
        </S.FileName>
        <div>
          <S.FileSize>{formatFileSize(size)}</S.FileSize>
          <S.FileExtension>{extension}</S.FileExtension>
        </div>
      </S.TextsWrapper>
    </S.FileWrapper>
  );
};

export default SharedFile;
