import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';

import { MenuItemCommonStyles } from 'components/side-menu/components';

const NavItemCommonStyles = css`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-left: 1.6rem;
  padding-right: 2rem;
`;

const SvgCommonStyles = css`
  svg {
    fill: ${({ theme }) => theme.colors.white};
    width: 3.2rem;
    height: 3.2rem;
    margin-right: 1.6rem;
    ${({ theme }) => theme.laptop`
      width: 2.8rem;
      height: 2.8rem;
    `}

    ${({ theme }) => theme.laptop`
      width: 2.8rem;
    `}
  }
`;

export const MenuItem = styled.li`
  height: 6.4rem;
  margin-bottom: ${({ pets }) => (pets ? '0rem' : '1.6rem')};

  ${MenuItemCommonStyles}

  & h2 {
    opacity: 0.64;
    color: ${({ theme }) => theme.colors.white};
    ${({ isForVet }) =>
      isForVet &&
      css`
        color: ${({ theme }) => theme.colors.white};
        margin-bottom: 0;
      `}
  }
  ${({ theme }) => theme.mobile`
  margin-bottom: 0rem;
  `}
`;

export const FlexItem = styled.div`
  display: flex;
  align-items: center;
`;
export const StyledNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding-left: 1.6rem;
  padding-right: 2rem;

  ${NavItemCommonStyles}
  &.active svg {
    fill: #ff9a05;
  }
  &.active {
    background-color: rgba(0, 0, 0, 0.16);
  }
  &.active h2 {
    opacity: 1;
  }

  svg {
    fill: ${({ theme }) => theme.colors.white};
    width: 3.2rem;
    height: 3.2rem;
    margin-right: 1.6rem;
    ${({ theme }) => theme.laptop`
      min-width: 2.8rem;
      width: 2.8rem;
      height: 2.8rem;
    `}
  }
  ${SvgCommonStyles}
`;

export const MenuItemButton = styled.button`
  ${({ isLogOut }) => isLogOut && `opacity: 0.72;`}
  color: inherit;
  background: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  justify-content: space-between;
  ${({ isPetsMenuActive }) => isPetsMenuActive && 'background-color: rgba(0, 0, 0, 0.16)'};

  & h2 {
    ${({ isPetsMenuActive }) => isPetsMenuActive && 'opacity: 1'};
  }

  ${SvgCommonStyles}
  & svg {
    ${({ isPetsMenuActive, theme }) => isPetsMenuActive && `fill: ${theme.colors.orange}`};
  }

  ${NavItemCommonStyles}
`;
