/* eslint-disable max-len */
import primaryIcons from './primaryMarkers';
import secondaryIcons from './secondaryMarkers';
const colorsMap = [
  '#22B9C3',
  '#C0AE08',
  '#A68BFF',
  '#585CE5',
  '#9E58E5',
  '#E258E5',
  '#E5587A',
  '#E19A59',
  '#4AB8A4',
  '#B8C053',
  '#003afa',
  '#989CAE'
];

export const getIconMarker = (index, color = 0, secondary) => {
  const colorHex = getColor(secondary ? 11 : color);
  if (index >= 30 || index === null) return primaryIcons[30](colorHex);
  return primaryIcons[index](colorHex);
};
export const getColor = (index) => {
  let colorIndex = index;
  if (colorIndex >= 11) {
    colorIndex = 11;
  }
  return colorsMap[colorIndex];
};
