import React from 'react';
import MapView from './MapView';

const AppointmentsMapView = ({
  appointmentsData = [],
  selectedTimeslot = {},
  workingArea = [],
  startEndPoints = null,
  openModal,
  existingAppointments,
  currentAppointmentLocation
}) => {
  return (
    <MapView
      currentAppointmentLocation={currentAppointmentLocation}
      existingAppointments={existingAppointments}
      openModal={openModal}
      appointmentsData={appointmentsData}
      selectedTimeslot={selectedTimeslot}
      workingArea={workingArea}
      startEndPoints={startEndPoints}
      style={{ width: '100%', height: '50vh' }}
    />
  );
};

export default AppointmentsMapView;
