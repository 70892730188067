import { useMutation } from '@apollo/client';
import { rescheduleVisitMutation } from 'gql';
import dayjs from 'dayjs';

const useRescheduleAppointment = () => {
  const [reschedule] = useMutation(rescheduleVisitMutation);

  const rescheduleVisit = async (date, appointmentId, timeSlotId) => {
    try {
      await reschedule({
        variables: {
          record: {
            date: dayjs(date).format('YYYY-MM-DD'),
            appointmentId,
            timeSlotId
          }
        }
      });
    } catch (err) {
      console.log(err, 'error');
    }
  };

  return {
    rescheduleVisit
  };
};

export default useRescheduleAppointment;
