import React, { useState, useCallback, useMemo, memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Typography, Button } from 'antd';

import { useWizardContext } from 'common_repo/service/wizard/wizardContext';
import mediaQueriesList from 'common_repo/utils/mediaQueriesList';
import { Roles } from 'constants/enums';
import { VET_ROUTES } from '../../constants/client';

import stepOneImg from 'assets/images/wizard/tutorial_step1.gif';
import stepTwoImg from 'assets/images/wizard/tutorial_step2.gif';
import stepThreeImg from 'assets/images/wizard/tutorial_step3.gif';
import stepFourImg from 'assets/images/wizard/tutorial_step4.gif';
import { ReactComponent as CloseIcon } from 'assets/icons/close-gray.svg';
import kumbaLogo from 'common_repo/assets/icons/logo-blue-beta.png';

import useWizard from './useWizard';

import './style.scss';

const { Title, Paragraph } = Typography;

const Wizard = ({ auth }) => {
  const history = useHistory();

  const { t } = useTranslation();
  const { isMobile } = mediaQueriesList();
  const { closeWizard, wizardIsOpen, openWizard } = useWizardContext();

  const [currentStep, setCurrentStep] = useState(1);
  const [allStepsVisited, setAllStepsVisited] = useState(false);

  useEffect(() => {
    if (
      auth &&
      auth?.role === Roles.VET &&
      VET_ROUTES.UPDATE_VET === history.location.pathname &&
      auth.hasOwnProperty('hasLoggedIn') &&
      !auth?.hasLoggedIn
    ) {
      openWizard();
    }
  }, [auth]);

  const { toggleHasLoggedIn } = useWizard();

  const changeStep = useCallback(
    (forward) => {
      setCurrentStep(forward ? currentStep + 1 : currentStep - 1);
      if (forward && currentStep === 3) {
        setAllStepsVisited(true);
      }
    },
    [currentStep]
  );

  const setActiveStep = useCallback(
    (step) => {
      if (allStepsVisited) {
        setCurrentStep(step);
      } else {
        if (step < currentStep) {
          setCurrentStep(step);
        }
      }
    },
    [currentStep]
  );

  const handleCloseWizard = useCallback(() => {
    if (!auth?.hasLoggedIn) {
      toggleHasLoggedIn().then(() => closeWizard());
    } else {
      closeWizard();
    }
  }, [auth]);

  const getWizardNumberClass = useCallback(
    (key) => {
      if (key === currentStep) {
        return 'active';
      }
      if (allStepsVisited) {
        return 'visited';
      }
      if (key < currentStep) {
        return 'completed';
      }
    },
    [currentStep]
  );

  const steps = useMemo(() => {
    return [
      {
        key: 1,
        title: t('wizard_tour.fill.your.profile'),
        descriptions: [
          t('wizard_tour.step1.descriptions.1'),
          t('wizard_tour.step1.descriptions.2'),
          t('wizard_tour.step1.descriptions.3')
        ],
        image: stepOneImg
      },
      {
        key: 2,
        title: t('wizard_tour.open.time.slots'),
        descriptions: [
          t('wizard_tour.step2.descriptions.1'),
          t('wizard_tour.step2.descriptions.2'),
          t('wizard_tour.step2.descriptions.3')
        ],
        image: stepTwoImg
      },
      {
        key: 3,
        title: t('wizard_tour.share.your.booking.page.link'),
        descriptions: [
          t('wizard_tour.step3.descriptions.1'),
          t('wizard_tour.step3.descriptions.2'),
          t('wizard_tour.step3.descriptions.3')
        ],
        image: stepThreeImg
      },
      {
        key: 4,
        title: t('wizard_tour.download.the.mobile.app'),
        descriptions: [
          t('wizard_tour.step4.descriptions.1'),
          t('wizard_tour.step4.descriptions.2'),
          t('wizard_tour.step4.descriptions.3')
        ],
        image: stepFourImg
      }
    ];
  }, []);

  const howItWorks = useCallback(() => {
    return (
      <Typography level={5} className="learn-more-text">
        {t('wizard_tour.want.more.info')}
        <a href=" https://www.kumba.pet/" target="_blank">
          {t('wizard_tour.learn.more')}
        </a>
      </Typography>
    );
  }, []);

  if (!wizardIsOpen) return null;

  return (
    <div className="wizard-tour-wrapper">
      <div className="wizard-tour">
        <div className="wizard-tour-header">
          <div className="wizard-tour-header-title-wrapper">
            <Title className="wizard-tour-header-title">{t('wizard_tour.welcome.to')}</Title>
            <img src={kumbaLogo} alt="Kumba" />
            <Title className="wizard-tour-header-title">{t('wizard_tour.tutorial.guide')}</Title>
            <Title level={2} className="wizard-tour-header-title wizard-tour-header-sub__title">
              {t('wizard_tour.lets.get.started')}
            </Title>
          </div>
          <div className="wizard-tour-header-close">
            <CloseIcon onClick={handleCloseWizard} />
          </div>
        </div>
        <div className="wizard-tour-content-wrapper">
          <div className="wizard-tour-content-menu">
            <div className="wizard-tour-content-menu--wrapper">
              {steps.map((step) => {
                return (
                  <div
                    key={step.key}
                    className={`wizard-tour-content-menu-item ${currentStep === step.key ? 'active' : ''}`}
                    onClick={() => setActiveStep(step.key)}>
                    <div
                      className={`wizard-tour-content-menu-item-number ${getWizardNumberClass(step.key)}`}>
                      {step.key}
                    </div>
                    <div className="wizard-tour-content-menu-item-title">{step.title}</div>
                  </div>
                );
              })}
              {currentStep === steps.length && !isMobile && howItWorks()}
            </div>
            {!isMobile && (
              <div className="wizard-tour-content-menu--footer">
                {currentStep !== 1 && (
                  <Button type="default" onClick={() => changeStep(false)}>
                    {t('back')}
                  </Button>
                )}
                {currentStep === steps.length ? (
                  <Button type="primary" onClick={handleCloseWizard}>
                    {t('finish')}
                  </Button>
                ) : (
                  <Button type="primary" onClick={() => changeStep(true)}>
                    {t('next')}
                  </Button>
                )}
              </div>
            )}
          </div>
          <div className="wizard-tour-content-body">
            <Title className="content-body-title">{steps[currentStep - 1].title}</Title>
            <img src={steps[currentStep - 1].image} alt={steps[currentStep - 1].title} />
            {steps[currentStep - 1].descriptions.map((description, index) => {
              return (
                <Paragraph className="wizard-tour-content-body-description" key={index}>
                  {description}
                </Paragraph>
              );
            })}
            {currentStep === steps.length && isMobile && howItWorks()}
            {isMobile && (
              <div className="wizard-tour-content-menu--mobile-footer">
                {currentStep !== 1 && (
                  <Button
                    type="default"
                    onClick={() => changeStep(false)}
                    size="large"
                    className="default">
                    {t('back')}
                  </Button>
                )}
                {currentStep === steps.length ? (
                  <Button type="primary" onClick={handleCloseWizard} size="large">
                    {t('finish')}
                  </Button>
                ) : (
                  <Button type="primary" onClick={() => changeStep(true)} size="large">
                    {t('next')}
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Wizard);
