import React, { createContext, useEffect, useMemo, useCallback, useState } from 'react';
import { useQuery } from '@apollo/client';
import { CometChat } from '@cometchat-pro/chat';

import { meQuery, isConversationReadOnlyQuery } from 'gql';
import ChatManager from './ChatManager';
import { supportedVideoFormats } from 'constants/client';

const ChatContext = createContext(null);

export default ChatContext;

// const CONVERSATION_LIMIT = 10;
const MESSAGE_LIMIT = 30;
const SORT_MESSAGES = (a, b) => a.sentAt - b.sentAt;

const serializeConversations = (conversations) => {
  const convs = {};
  conversations.forEach((conversation, i) => {
    convs[conversation.groupId] = conversation;
  });
  return convs;
};

export const ChatProvider = ({ children, isAdmin }) => {
  const [loadingConversations, setLoadingCoversations] = useState(false);
  const [conversations, setConversations] = useState({});
  const [currentConversation, setCurrentConversation] = useState(null);
  const [currentMessages, setCurrentMessages] = useState([]);
  const [loadingMessages, setLoadingMessages] = useState(false);
  const [fetchingMoreMessages, setFetchingMoreMessages] = useState(false);
  const [sendingMessage, setSendingMessage] = useState(false);
  const [hasMoreMessages, setHasMoreMessages] = useState(true);
  const [totalUnreadCount, setTotalUnreadCount] = useState(0);
  const [currentMedia, setCurrentMedia] = useState([]);
  const [currentFiles, setCurrentFiles] = useState([]);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  const { data } = useQuery(meQuery);

  const { data: readOnlyData } = useQuery(isConversationReadOnlyQuery, {
    skip: isAdmin || !currentConversation?.id,
    fetchPolicy: 'network-only',
    variables: {
      record: {
        conversationId: currentConversation?.id
      }
    }
  });

  const chatInstance = useMemo(() => ChatManager.getInstance(), []);

  const updateTotalUnread = useCallback(
    (conversations) => {
      const unread = Object.values(conversations).reduce((count, curr) => {
        if (curr?.groupId !== currentConversation?.groupId) {
          return curr?.unreadMessageCount > 0 ? ++count : count;
        } else {
          return count;
        }
      }, 0);

      setTotalUnreadCount(unread);
    },
    [chatInstance]
  );

  useEffect(() => {
    updateTotalUnread(conversations);
  }, [conversations, updateTotalUnread]);
  const handleConversationUpdate = useCallback(
    (conversation) => {
      updateTotalUnread(conversations);
      setConversations((current) => {
        return {
          ...current,
          [conversation.groupId]: conversation
        };
      });
    },
    [updateTotalUnread]
  );

  const toggleCurrentConversation = useCallback((conversation) => {
    setCurrentConversation((current) =>
      current && !conversation.isNew && current.groupId === conversation.groupId
        ? null
        : conversation
    );

    setHasMoreMessages(true);
  }, []);

  const handleMessageUpdate = useCallback(
    (message) => {
      updateTotalUnread(conversations);
      setCurrentMessages((current) => [...current, message]);
      if (['video', 'image'].includes(message.type)) {
        setCurrentMedia((curr) => [...curr, { type: message.type, ...message?.attachment }]);
      }
      if (message.type === 'file') {
        setCurrentFiles((curr) => [...curr, { type: message.type, ...message?.attachment }]);
      }
    },
    [updateTotalUnread]
  );

  const sendTextMessage = useCallback(
    (text, isGroup) => {
      if (sendingMessage) {
        return new Promise();
      }

      setSendingMessage(true);
      return chatInstance
        .sendTextMessage(
          text,
          currentConversation?.isGroup,
          currentConversation?.isNew ? currentConversation.groupId : undefined
        )
        .finally(() => setSendingMessage(false));
    },
    [chatInstance, currentConversation, sendingMessage]
  );

  const sendMediaMessage = useCallback(
    (file) => {
      if (!file.type.includes('image') && !supportedVideoFormats.includes(file.type)) {
        setIsErrorModalOpen(true);
        return;
      }

      if (sendingMessage) {
        return new Promise();
      }

      setSendingMessage(true);

      const receiverType = currentConversation.isGroup
        ? CometChat.RECEIVER_TYPE.GROUP
        : CometChat.RECEIVER_TYPE.USER;

      let messageType = CometChat.MESSAGE_TYPE.FILE;

      if (file.type.includes('image')) {
        messageType = CometChat.MESSAGE_TYPE.IMAGE;
      } else if (file.type.includes('video')) {
        messageType = CometChat.MESSAGE_TYPE.VIDEO;
      }

      return chatInstance
        .sendMediaMessage({
          file,
          messageType,
          receiverType
        })
        .finally(() => setSendingMessage(false));
    },
    [chatInstance, currentConversation, sendingMessage]
  );

  const markAsRead = useCallback(async () => {
    if (currentConversation && currentConversation.unreadMessageCount) {
      const receiverType = currentConversation.isGroup
        ? CometChat.RECEIVER_TYPE.GROUP
        : CometChat.RECEIVER_TYPE.USER;
      await chatInstance.markAsRead(
        currentConversation.lastMessage.id,
        currentConversation.groupId,
        receiverType,
        currentConversation?.user?.id
      );

      updateTotalUnread(conversations);
    }
  }, [chatInstance, currentConversation, updateTotalUnread, conversations]);

  const fetchAttachments = useCallback(() => {
    return chatInstance.fetchAttachments();
  }, []);

  const fetchMoreMessages = useCallback(() => {
    if (hasMoreMessages && !fetchingMoreMessages) {
      setFetchingMoreMessages(true);

      chatInstance
        .fetchMessages()
        .then((moreMessages) => {
          if (moreMessages.length < MESSAGE_LIMIT) {
            setHasMoreMessages(false);
          }
          setCurrentMessages((curr) => [...curr, ...moreMessages].sort(SORT_MESSAGES));
        })
        .finally(() => setFetchingMoreMessages(false));
    }
  }, [chatInstance, hasMoreMessages, fetchingMoreMessages]);

  const logout = useCallback(() => {
    setLoadingCoversations(false);
    setConversations({});
    setCurrentConversation(null);
    setCurrentMessages([]);
    setLoadingMessages(false);
    setSendingMessage(false);
    setHasMoreMessages(true);
    setTotalUnreadCount(0);

    ChatManager.logout();
  }, []);

  useEffect(() => {
    setCurrentMessages([]);
    if (currentConversation) {
      setLoadingMessages(true);
      chatInstance
        .subscribeToGroup({
          groupId: currentConversation.groupId,
          onMessage: handleMessageUpdate,
          messageLimit: MESSAGE_LIMIT,
          isGroup: currentConversation.isGroup
        })
        .then((messages) => {
          setCurrentMessages(messages);
          fetchAttachments().then((attachments) => {
            setCurrentFiles(attachments.filter((a) => a.type === 'file'));
            setCurrentMedia(attachments.filter((a) => a.type !== 'file'));
          });
        })
        .finally(() => setLoadingMessages(false));
    }
  }, [currentConversation, chatInstance, handleMessageUpdate]);

  useEffect(() => {
    if (currentConversation && !currentConversation.isNew) {
      markAsRead();
    }
  }, [currentConversation, markAsRead]);

  useEffect(() => {
    if (!isAdmin && data?.me && !chatInstance.initialized) {
      setLoadingCoversations(true);
      ChatManager.login(data?.me?.cometAuthToken)
        .then(() =>
          chatInstance.connect({
            onConversationUpdate: handleConversationUpdate,
            onConversationAdd: handleConversationUpdate
          })
        )
        .then((conversations) => setConversations(serializeConversations(conversations)))
        .finally(() => setLoadingCoversations(false));
    }

    if (!data?.getCurrentUser?.user && chatInstance.initialized) {
      logout();
    }
  }, [isAdmin, data, chatInstance, handleConversationUpdate, logout]);

  useEffect(() => {
    updateTotalUnread(conversations);
  }, [updateTotalUnread, conversations]);
  const contextValue = useMemo(
    () => ({
      conversations: conversations ? Object.values(conversations) : [],
      currentConversation,
      currentMessages: [...currentMessages].reverse(),
      toggleCurrentConversation,
      sendTextMessage,
      markAsRead,
      sendMediaMessage,
      fetchMoreMessages,
      hasMoreMessages,
      loadingConversations,
      loadingMessages,
      sendingMessage,
      fetchingMoreMessages,
      setCurrentConversation,
      totalUnreadCount,
      currentMedia,
      currentFiles,
      isErrorModalOpen,
      setIsErrorModalOpen,
      isCurrentConversationReadOnly: readOnlyData?.isConversationReadOnly
    }),
    [
      conversations,
      toggleCurrentConversation,
      currentConversation,
      currentMessages,
      sendTextMessage,
      markAsRead,
      sendMediaMessage,
      fetchMoreMessages,
      hasMoreMessages,
      loadingConversations,
      loadingMessages,
      sendingMessage,
      fetchingMoreMessages,
      setCurrentConversation,
      totalUnreadCount,
      currentMedia,
      currentFiles,
      readOnlyData,
      isErrorModalOpen
    ]
  );

  return <ChatContext.Provider value={contextValue}>{children}</ChatContext.Provider>;
};
