import { birthDayDateToNumbers, birthDayNumbersToDate } from 'utils/helpers';
import uniqueId from 'lodash/uniqueId';

export const preparePet = (pet) => {
  const birthDate = birthDayNumbersToDate(pet);
  return {
    ...pet,
    birthDate,
    ...birthDayDateToNumbers(birthDate),
    _id: uniqueId(),
    type: pet.type?.value
      ? {
          uid: pet.type.value,
          name: pet.type.label
        }
      : null,
    gender: pet.gender?.value || null
  };
};

export const prepareValues = (values) => {
  return {
    user: {
      address: {
        ...values.addressObject,
        houseNumber: Number(values.addressObject?.houseNumber),
        countryCode: values.addressObject?.countryCode || values.addressObject?.country?.code,
        description: values.address,
        floor: values.floor,
        apartment: values.apartment,
        comment: values.comment,
        propertyType: values.propertyType
      },
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phoneNumber: values.phoneNumber,
      phonePrefix: values.phonePrefix
    },
    pets: (values.pets || []).map((p) => {
      const pet = { ...p };
      delete pet.update;
      delete pet._id;
      delete pet.age;
      delete pet.year;
      delete pet.month;
      delete pet.day;
      pet.type = pet.type.uid;
      pet.isSterilized =
        pet.isSterilized === 'no_neutered'
          ? false
          : pet.isSterilized === 'neutered'
            ? true
            : undefined;
      return pet;
    }) // serializePetList(petList, formValues.pets),
  };
};
