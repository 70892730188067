import React, { useEffect, useState, memo, useCallback } from 'react';
import { Form, Row, Col, Button, Typography, Popconfirm, Radio, Checkbox, InputNumber } from 'antd';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import moment from 'moment';

import { Modal } from '_fsd/shared';

import useCreateVetTimeSlots from 'components/vet-components/create-vet-timeslots/useCreateVetTimeSlots';
import AddressAutocomplete from 'components/vet-components/address-autocomplete/AddressAutocomplete';
import SubmitButton from 'components/vet-components/vet-web-inputs/submit-button/SubmitBtn';
import DatePicker from 'components/vet-components/vet-web-inputs/date-picker/DatePicker';
import TimePicker from 'components/vet-components/vet-web-inputs/time-picker/TimePicker';
import Input from 'components/vet-components/vet-web-inputs/input/Input';
import DebounceSelect from 'components/debounce-select/DebounceSelect';
import Map from 'components/vet-components/map/Map';
import { parseTimeSlotHour } from 'utils/helpers';
import { WorkingAreaType } from 'utils/enums';

import { ReactComponent as UserIcon } from 'assets/icons/user-blue.svg';
import arrow from 'assets/icons/arrow-down-blue.svg';

import * as S from './components';
import theme from 'styles/theme';
import './styles.css';

const { Title } = Typography;

const AddTimeslotModal = ({
  closeModal,
  updateTimeslotData,
  calendarDate,
  appointmentsData,
  spotCalendarDate,
  startPoint,
  refetchAuthVet,
  endPoint,
  clientLimit,
  vet
}) => {
  const [workingAreas, setWorkingAreas] = useState([]);
  const [isRecommendationsOpen, setIsRecommendationsOpen] = useState(false);
  const [fieldsAreUpdated, setFieldsAreUpdated] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [workingAreaTabValue, setWorkingAreaTabValue] = useState(false);
  const [polygonError, setPolygonError] = useState(false);
  const [zipCodesError, setZipCodesError] = useState(false);
  const [polygonName, setPolygonName] = useState('');
  const [zipCodes, setZipCodes] = useState([]);
  const [path, setPath] = useState([]);
  const [lastPathCoordinates, setLastPathCoordinates] = useState([]);
  const [customStartPoint, setCustomStartPoint] = useState(startPoint);
  const [customEndPoint, setCustomEndPoint] = useState(endPoint);
  const [customStartPointError, setCustomStartPointError] = useState(false);
  const [customEndPointError, setCustomEndPointError] = useState(false);
  const [isMaxClientsLimitChecked, setIsMaxClientsLimitChecked] = useState(true);
  const [isDefaultPointChecked, setIsDefaultPointChecked] = useState(true);
  const [isUnifyTimeslotChecked, setIsUnifyTimeslotChecked] = useState(true);
  const [maxClientsLimit, setMaxClientsLimit] = useState(0);

  const isTablet = useMediaQuery({
    query: `(max-width: ${theme.screenSizes.tabletL}px)`
  });

  const [form] = Form.useForm();
  const { t } = useTranslation();

  const getFormattedRrule = useCallback((date) => {
    return `DTSTART:${moment(date).format(
      'YYYYMMDD'
    )}T120000Z RRULE:FREQ=DAILY;COUNT=1;INTERVAL=1;WKST=MO`;
  }, []);

  const clearStates = () => {
    setWorkingAreas([]);
    form.resetFields();
    closeModal();
  };

  const { onSubmit, fetchZipCodes, parseZipCodesData, loading } = useCreateVetTimeSlots(
    clearStates,
    calendarDate,
    refetchAuthVet,
    vet
  );

  const handleCatchFieldUpdate = (e) => {
    if (!fieldsAreUpdated) {
      setFieldsAreUpdated(true);
    }
  };

  const selectZipCode = (code) => {
    setZipCodes((prevState) => [
      ...prevState,
      {
        value: code?.value,
        label: code?.label
      }
    ]);
  };

  const removeZipCodeFromWorkingArea = (code) => {
    setZipCodes(zipCodes.filter((zip) => zip?.value !== code?.value));
  };

  useEffect(() => {
    if (updateTimeslotData?.maxAppointments) {
      setMaxClientsLimit(updateTimeslotData?.maxAppointments);
      setIsMaxClientsLimitChecked(false);
    }
    if (updateTimeslotData?.startPoint) {
      setCustomStartPoint(updateTimeslotData?.startPoint);
      setIsDefaultPointChecked(false);
    }
    if (updateTimeslotData?.endPoint) {
      setCustomEndPoint(updateTimeslotData?.endPoint);
      setIsDefaultPointChecked(false);
    }
    if (updateTimeslotData?.isSingle) {
      setIsUnifyTimeslotChecked(false);
    }

    if (updateTimeslotData?.startTime) {
      setStartTime(parseTimeSlotHour(updateTimeslotData?.startTime));
    }
    if (updateTimeslotData?.endTime) {
      setEndTime(parseTimeSlotHour(updateTimeslotData?.endTime));
    }
    if (!!updateTimeslotData?.workingAreas && !!updateTimeslotData.workingAreas.length) {
      const workingAreas = [];
      const codes = [];
      let workingAreaType;

      updateTimeslotData?.workingAreas.forEach((area) => {
        if (!workingAreaType) workingAreaType = area.type;
        if (area?.zipCode) {
          codes.push({
            label: area?.zipCode?.zip,
            value: area?.zipCode?.uid
          });
        }

        if (area.polygon) setPath(area?.polygon?.area);
        if (area.polygon?.name) setPolygonName(area.polygon?.name);
      });

      setZipCodes(codes);
      setWorkingAreas(workingAreas);
      setWorkingAreaTabValue(workingAreaType === WorkingAreaType.zipCode);
    }
  }, [updateTimeslotData]);

  useEffect(() => {
    if (polygonError) {
      const timer1 = setTimeout(() => setPolygonError(false), 3000);
      return () => {
        clearTimeout(timer1);
      };
    }
  }, [polygonError]);

  useEffect(() => {
    if (zipCodesError) {
      const timer1 = setTimeout(() => setZipCodesError(false), 3000);
      return () => {
        clearTimeout(timer1);
      };
    }
  }, [zipCodesError]);

  useEffect(() => {
    if (startPoint && !customStartPoint) setCustomStartPoint(startPoint);
    if (endPoint && !customEndPoint) setCustomEndPoint(endPoint);
  }, [startPoint, endPoint]);

  useEffect(() => {
    if (customEndPointError || customStartPointError) {
      setTimeout(() => {
        setCustomEndPointError(false);
        setCustomStartPointError(false);
      }, 3000);
    }
  }, [customEndPointError, customStartPointError]);

  const addressError = useCallback((address) => {
    return !address?.lat || !address?.lng;
  }, []);

  const initialValues = {
    ...updateTimeslotData,
    date: moment(calendarDate)
  };

  const addressSerializer = (address) => {
    return {
      city: address?.city,
      countryCode: address?.countryCode,
      street: address?.street,
      description: address?.description,
      lat: address?.lat,
      lng: address?.lng,
      zipCode: address?.zipCode,
      houseNumber: parseInt(customStartPoint?.houseNumber)
    };
  };

  return (
    <Modal
      isOpen={true}
      onClose={clearStates}
      customFooter={() => null}
      forceRender
      title={t('add.timeslot')}>
      <S.Recommendations>
        <S.Heading onClick={() => setIsRecommendationsOpen((prev) => !prev)}>
          {t('timeslot_recommendations.recommendations')}
          <S.DropdownImg src={arrow} isRecommendationsOpen={isRecommendationsOpen} />
        </S.Heading>

        <S.UL isRecommendationsOpen={isRecommendationsOpen}>
          <S.SingleReccomendation>
            {t('timeslot_recommendations.make_short')}
          </S.SingleReccomendation>
          <S.SingleReccomendation>
            {t('timeslot_recommendations.morning_or_evening')}
          </S.SingleReccomendation>
        </S.UL>
      </S.Recommendations>

      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        disabled={loading}
        initialValues={initialValues}
        className="add-timeslot-form"
        onFinish={(values) => {
          if (!updateTimeslotData?.uid && !fieldsAreUpdated) {
            return;
          }

          const formValues = {
            ...values
          };

          const addressPoints = {};

          if (initialValues.uid) {
            formValues.uid = initialValues.uid;
          }
          formValues.rule = getFormattedRrule(formValues.date);
          formValues.isSingle = !isUnifyTimeslotChecked;

          if (workingAreaTabValue) {
            if (!zipCodes.length) {
              setZipCodesError(true);
              return;
            }
            formValues.workingAreaType = WorkingAreaType.zipCode;
            formValues.workingAreas = zipCodes?.map((zip) => {
              if (zip?.label) {
                return {
                  zipCode: {
                    uid: zip?.value,
                    zip: zip?.label
                  }
                };
              }
              return zip;
            });
          } else {
            if (!path.length) {
              setPolygonError(true);
              return;
            }
            formValues.workingAreaType = WorkingAreaType.polygon;
            formValues.workingAreas = workingAreas.length
              ? workingAreas.map((wa) => {
                  if (wa?.areas) {
                    return {
                      ...wa?.areas,
                      areas: path,
                      name: polygonName || 'polygon 1'
                    };
                  }
                })
              : [{ areas: path, name: polygonName || 'polygon 1' }];
          }
          formValues.startTime = startTime || moment(formValues.startTime).format('hh:mm a');
          formValues.endTime = endTime || moment(formValues.endTime).format('hh:mm a');

          if (!isMaxClientsLimitChecked) formValues.maxAppointments = maxClientsLimit;
          if (!isDefaultPointChecked) {
            if (addressError(customStartPoint) || addressError(customEndPoint)) {
              if (addressError(customStartPoint)) setCustomStartPointError(true);
              if (addressError(customEndPoint)) setCustomEndPointError(true);
              return;
            }
            addressPoints.startPoint = addressSerializer(customStartPoint);
            addressPoints.endPoint = addressSerializer(customEndPoint);
          }

          for (const value in values) {
            if (value.includes('addressDescription')) {
              delete formValues[value];
            }
          }

          spotCalendarDate(formValues?.date);

          onSubmit(formValues, addressPoints);
        }}>
        <Title level={5} className="working-area-title">
          {t('name')}
        </Title>
        <Row>
          <Col span={24}>
            <Input
              onChange={handleCatchFieldUpdate}
              required
              name="name"
              placeholder={t('name.the.timeslot')}
              whitespace
            />
          </Col>
        </Row>

        <Title level={5} className="working-area-title">
          {t('working.area')}{' '}
          <Title level={3} className="working-area-sub-title">
            ({t('zip.code')})
          </Title>
        </Title>
        <Radio.Group
          onChange={(e) => setWorkingAreaTabValue(e.target.value)}
          value={workingAreaTabValue}>
          <Radio value={false}>{t('routes_page.select_on_map')}</Radio>
          <Radio value={true}>{t('routes_page.by_zip_code')}</Radio>
        </Radio.Group>

        {workingAreaTabValue ? (
          <DebounceSelect
            fetchOptions={fetchZipCodes}
            parseData={parseZipCodesData}
            onSelect={selectZipCode}
            zipCodes={zipCodes}
            onDeselect={removeZipCodeFromWorkingArea}
            error={zipCodesError}
          />
        ) : (
          <div className="map-wrapper">
            <Map
              startPoint={startPoint}
              path={path}
              setPath={setPath}
              polygonError={polygonError}
              polygonName={polygonName}
              setPolygonName={setPolygonName}
              lastPathCoordinates={lastPathCoordinates}
              setLastPathCoordinates={setLastPathCoordinates}
            />
          </div>
        )}

        <Title level={5} className="working-area-title">
          {t('choose.date')}
        </Title>
        <Row>
          <Col span={24}>
            <DatePicker
              required
              name="date"
              date={calendarDate}
              onChange={handleCatchFieldUpdate}
            />
          </Col>
        </Row>

        <Row gutter={12}>
          <Col span={12}>
            <Title level={5} className="working-area-title">
              {t('start.time')}
            </Title>
            <Form.Item
              name="startTime"
              rules={[{ required: true, message: t('field.is.required') }]}
              trigger="onSelect">
              <TimePicker
                onChange={handleCatchFieldUpdate}
                name="startTime"
                form={form}
                initialValues={initialValues}
                placeholder={'00:00'}
                value={startTime}
                onSelect={(time) => {
                  setStartTime(time);
                  form.setFieldsValue({
                    startTime: moment(time, 'hh:mm a')
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Title level={5} className="working-area-title">
              {t('end.time')}
            </Title>
            <Form.Item
              name="endTime"
              rules={[{ required: true, message: t('field.is.required') }]}
              trigger="onSelect">
              <TimePicker
                onChange={handleCatchFieldUpdate}
                name="endTime"
                form={form}
                initialValues={initialValues}
                placeholder={'00:00'}
                value={endTime}
                onSelect={(time) => {
                  setEndTime(time);
                  form.setFieldsValue({
                    endTime: moment(time, 'hh:mm a')
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        {!updateTimeslotData || updateTimeslotData ? (
          <>
            <Row gutter={8} style={{ alignItems: 'center', marginBottom: 8 }}>
              <Checkbox
                checked={isMaxClientsLimitChecked}
                onClick={() => setIsMaxClientsLimitChecked((prev) => !prev)}>
                <span className="checkbox-text">{t('timeslot_modal.clients_limit')}</span>
              </Checkbox>
              {!isMaxClientsLimitChecked && (
                <InputNumber
                  controls={false}
                  addonAfter={<UserIcon />}
                  value={isMaxClientsLimitChecked ? clientLimit : maxClientsLimit}
                  onChange={(e) => setMaxClientsLimit(e)}
                  disabled={isMaxClientsLimitChecked}
                  className="client-limit-input"
                />
              )}
            </Row>
            {!updateTimeslotData?.uid && (
              <>
                <Row gutter={8} style={{ alignItems: 'center', marginBottom: 8 }}>
                  <Checkbox
                    checked={isDefaultPointChecked}
                    onClick={() => setIsDefaultPointChecked((prev) => !prev)}>
                    <span className="checkbox-text">{t('timeslot_modal.default_points')}</span>
                  </Checkbox>
                </Row>
                <Row gutter={8} style={{ alignItems: 'center', marginBottom: 8 }}>
                  <Col span={12}>
                    <span className="checkbox-text">{t('start.point')}</span>
                    <AddressAutocomplete
                      disabled={isDefaultPointChecked}
                      className="address-autocomplete-input"
                      address={customStartPoint?.description || customStartPoint}
                      onChange={(e) => {
                        setCustomStartPoint(e);
                        handleCatchFieldUpdate();
                      }}
                      onSelect={setCustomStartPoint}
                      customErrorMessage={customStartPointError}
                    />
                  </Col>
                  <Col span={12}>
                    <span className="checkbox-text">{t('end.point')}</span>
                    <AddressAutocomplete
                      disabled={isDefaultPointChecked}
                      className="address-autocomplete-input"
                      address={customEndPoint?.description || customEndPoint}
                      onChange={(e) => {
                        setCustomEndPoint(e);
                        handleCatchFieldUpdate();
                      }}
                      onSelect={setCustomEndPoint}
                      customErrorMessage={customEndPointError}
                    />
                  </Col>
                </Row>
              </>
            )}
          </>
        ) : null}

        <Row gutter={8} style={{ alignItems: 'center', marginBottom: 8 }}>
          <Checkbox
            checked={isUnifyTimeslotChecked}
            onClick={() => {
              setIsUnifyTimeslotChecked((prev) => !prev);
              handleCatchFieldUpdate();
            }}>
            <span className="checkbox-text">{t('timeslot_modal.is_single_timeslot')}</span>
          </Checkbox>
        </Row>

        <Row gutter={12} justify="end" className="ts-footer">
          <Col>
            <Button className="cancel-button" style={{ width: 'auto' }} onClick={clearStates}>
              {t('cancel')}{' '}
            </Button>
          </Col>
          <Col>
            {updateTimeslotData?.uid && appointmentsData && appointmentsData.length ? (
              <Popconfirm
                title={t('edit.timeslot.confirm')}
                onConfirm={() => !loading && form.submit()}
                onCancel={() => closeModal()}
                okText="Yes"
                cancelText="No">
                <div className="submit-btn-wrapper">
                  <a href="#">{t('ok')}</a>
                </div>
              </Popconfirm>
            ) : (
              <SubmitButton disabled={loading} onClick={form.submit} value={t('ok')} />
            )}
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default memo(AddTimeslotModal);
