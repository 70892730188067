import React, { useRef, useState } from 'react';
import * as S from './components';
import Header from 'components/header/Header';
import SideMenu from 'components/side-menu/SideMenu';
import ChatWidget from 'components/chat/chat-widget/ChatWidget';
import { useRouteMatch } from 'react-router';

import { CHOOSE_PET, SELECT_VET, MEMBERSHIP_DETAILS, CHECKOUT } from 'constants/client';

const MembershipFlowRoutes = [CHOOSE_PET, MEMBERSHIP_DETAILS, SELECT_VET, CHECKOUT];

const Layout = ({ children, isHome }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [vhSize, setVhSize] = useState(() => window.innerHeight * 0.1);
  window.addEventListener('resize', () => {
    setVhSize(window.innerHeight * 0.1);
  });

  const match = useRouteMatch();

  const burgerRef = useRef();

  return (
    <S.Container vhSize={vhSize} sidebarOpen={sidebarOpen}>
      <Header setSidebarOpen={setSidebarOpen} burgerRef={burgerRef} />
      <S.Main>
        {sidebarOpen && <S.Layer />}
        <SideMenu sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} burgerRef={burgerRef} />
        <S.Content isHome={isHome}>{children}</S.Content>
      </S.Main>

      {!MembershipFlowRoutes.includes(match.path) && <ChatWidget />}
    </S.Container>
  );
};

export default Layout;
