import React, { useMemo, useState } from 'react';
import debounce from 'lodash/debounce';
import { Select, Spin } from 'antd';

const { Option } = Select;

const DebounceSelect = ({
  fetchOptions,
  parseData,
  zipCodes,
  onSelect,
  onDeselect,
  error,
  debounceTimeout = 800
}) => {
  const [options, setOptions] = useState([]);
  const [fetching, setFetching] = useState(false);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      setOptions([]);
      setFetching(true);
      fetchOptions(value).then((newOptions) => {
        setOptions(parseData(newOptions));
        setFetching(false);
      });
    };
    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  return (
    <Select
      mode="multiple"
      labelInValue
      filterOption={false}
      className="zip-code-select"
      onSearch={debounceFetcher}
      onSelect={onSelect}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      value={zipCodes}
      onDeselect={onDeselect}
      status={error ? 'error' : ''}>
      {options?.map((option) => (
        <Option key={option.value} value={option.value}>
          {option.label}
        </Option>
      ))}
    </Select>
  );
};

export default DebounceSelect;
