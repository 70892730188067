import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'antd';
import dayjs from 'dayjs';

import VetCalendar from '../vet-components/calendar/VetCalendar';
import { getTimeSlotTime, sortByStartTime } from '../../utils/helpers';
import Modal from 'components/common/modal/Modal';
import Button from '../common/button/Button';

import './style.scss';

const RescheduleAppointmentModal = ({
  selectedAppointmentDate,
  selectedTimeslot,
  timeSlotsInArea,
  fullTimeSlots,
  timeSlots,
  onConfirm,
  onclose,
  vetId
}) => {
  const { t } = useTranslation();
  const today = dayjs(selectedAppointmentDate).format('YYYY-MM-DD');

  const [selectedDateTimeSlots, setSelectedDateTimeSlots] = useState([]);
  const [showTimeSlotsInOtherArea, setShowTimeSlotsInOtherArea] = useState(false);
  const [showFullTimeSlots, setShowFullTimeSlots] = useState(false);
  const [selectedTime, setSelectedTime] = useState(null);
  const [calendarDate, setCalendarDate] = useState(today);
  const [timeslotsList, setTimeslotsList] = useState({});

  const onFullTsChange = (e) => {
    setShowFullTimeSlots(e.target.checked);
  };
  const onOtherAreaChange = (e) => {
    setShowTimeSlotsInOtherArea(e.target.checked);
  };

  const timeSlotsFilterHelper = (tsArray, fullTsArray) => {
    const finalArr = [];

    for (let i = 0; i < tsArray?.length; i++) {
      if (
        fullTsArray &&
        !fullTsArray.find((ts) => ts?.uid === tsArray[i]?.uid) &&
        !tsArray[i]?.isLocked &&
        tsArray[i]?.uid !== selectedTimeslot
      ) {
        finalArr?.push(tsArray[i]);
      } else if (!fullTsArray && tsArray[i]?.uid !== selectedTimeslot) {
        finalArr?.push(tsArray[i]);
      }
    }

    return finalArr;
  };

  useEffect(() => {
    if (!timeslotsList?.['timeslotsInArea']) {
      const filteredTsList = {
        timeslotsInArea: timeSlotsFilterHelper(timeSlotsInArea, fullTimeSlots),
        withFullTimeSlotsInArea: timeSlotsFilterHelper(timeSlotsInArea, false),
        withTimeSlotsInOtherArea: timeSlotsFilterHelper(timeSlots, fullTimeSlots),
        withFullTimeSlotsInOtherArea: timeSlotsFilterHelper(timeSlots, false)
      };
      setTimeslotsList(filteredTsList);
    }
  }, [timeSlots, timeSlotsInArea, fullTimeSlots, selectedTimeslot]);

  const filteredTimeslots = useMemo(() => {
    if (showTimeSlotsInOtherArea && showFullTimeSlots)
      return timeslotsList['withFullTimeSlotsInOtherArea'];
    if (showTimeSlotsInOtherArea) return timeslotsList['withTimeSlotsInOtherArea'];
    if (showFullTimeSlots) return timeslotsList['withFullTimeSlotsInArea'];
    return timeslotsList['timeslotsInArea'];
  }, [showTimeSlotsInOtherArea, showFullTimeSlots, timeslotsList]);

  return (
    <Modal zIndex={1001} isOpen={true} onRequestClose={onclose}>
      <div className="reschedule-appointment-modal">
        <h3>{t('new_appointment.reschedule_appointment_title')}</h3>
        <VetCalendar
          timeSlots={filteredTimeslots}
          fullTimeSlots={fullTimeSlots}
          vetId={vetId}
          setCalendarDate={(date) => {
            setCalendarDate(date);
            setSelectedTime(null);
          }}
          setSelectedDateTimeSlots={setSelectedDateTimeSlots}
          setSelectedTimeslot={() => {}}
          calendarDate={calendarDate}
          setAppointmentsData={() => {}}
          calendarTitle={t('new_appointment.calendar_title')}
          showTimeSlotsInOtherArea={showTimeSlotsInOtherArea}
          showFullTimeSlots={showFullTimeSlots}
        />
        {selectedDateTimeSlots.length > 0 && <h4>{t('new_appointment.time_slots_for_date')}</h4>}
        <div className="time-wrapper">
          {sortByStartTime(selectedDateTimeSlots)?.map((timeSlot, index) => {
            return (
              <div
                key={index}
                className={`time-item ${selectedTime?.uid === timeSlot.uid && 'selected'}`}
                onClick={() => setSelectedTime(timeSlot)}>
                <span>
                  {getTimeSlotTime(timeSlot?.startTime)} -{getTimeSlotTime(timeSlot?.endTime)}
                </span>
              </div>
            );
          })}
        </div>
        <h4>{t('new_appointment.filters')}</h4>
        <Checkbox checked={showFullTimeSlots} onChange={onFullTsChange}>
          {t('new_appointment.show_full_ts')}
        </Checkbox>
        <Checkbox
          checked={showTimeSlotsInOtherArea}
          onChange={onOtherAreaChange}
          style={{ margin: 0 }}>
          {t('new_appointment.show_ts_in_areas')}
        </Checkbox>
        <div className="footer-wrapper">
          <Button label={t('cancel')} className="cancel-btn" onClick={onclose} />
          <Button
            label={t('reschedule')}
            color="primary"
            disabled={!selectedTime}
            className="next-btn"
            onClick={() => onConfirm(calendarDate, selectedTime?.uid)}
          />
        </div>
      </div>
    </Modal>
  );
};

export default RescheduleAppointmentModal;
