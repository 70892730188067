import styled from 'styled-components';

export const GalleryImage = styled.img`
  margin: auto;
  max-height: calc(100vh - 10rem);
  max-width: 80%;
`;

export const GalleryItemViewWrapper = styled.div`
  display: flex;
`;

export const GalleryVideo = styled.video`
  margin: auto;
  height: 90vh;
  max-width: 100%;
`;
