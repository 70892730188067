import { useEffect, useMemo } from 'react';
import { useMutation, useQuery, useSubscription } from '@apollo/client';

import {
  getNotificationsByUserQuery,
  markAllAsReadMutation,
  newNotificationSubscription,
  updateLastNotificationsSeenMutation
} from 'gql';
import storage from '../../../../utils/storage';

const useNotificationsBugged = (token) => {
  const { data, refetch } = useQuery(getNotificationsByUserQuery);
  const [updateLastNotificationsSeen] = useMutation(updateLastNotificationsSeenMutation);
  const [markAllAsRead] = useMutation(markAllAsReadMutation);

  useEffect(() => {
    updateLastNotificationsSeen();
    return () => {
      if (token) {
        updateLastNotificationsSeen();
      }
    };
  }, []);

  const { data: subData } = useSubscription(newNotificationSubscription, {
    onSubscriptionData: ({
      client,
      subscriptionData: {
        data: { notificationAdded }
      }
    }) => {
      const prevData = client.readQuery({ query: getNotificationsByUserQuery });
      if (!prevData?.new?.find((item) => item.uid === notificationAdded?.uid)) {
        const newData = prevData
          ? {
              ...prevData,
              new: prevData?.new?.length
                ? [notificationAdded, ...prevData?.new]
                : [notificationAdded]
            }
          : { new: [notificationAdded] };

        client.writeQuery({
          query: getNotificationsByUserQuery,
          data: newData
        });
      }
    }
  });
  useEffect(() => {
    if (subData?.notificationAdded) {
      refetch();
    }
  }, [subData]);

  const isSetSeenAllDisabled = useMemo(() => {
    if (data) {
      const arr = [...data?.new, ...data?.earlier];
      return !arr?.length || arr?.every(({ seen }) => seen);
    }
    return true;
  }, [data]);

  return {
    isSetSeenAllDisabled,
    data,
    markAllAsRead
  };
};

// todo: fix this,
//  gql subscription get token only when created,
//  not per each query
const token = storage.getToken();

const useNotifications = () => {
  return token
    ? useNotificationsBugged(token)
    : {
        data: [],
        isSetSeenAllDisabled: true,
        markAllAsRead: () => {}
      };
};
export default useNotifications;
