import { useQuery } from '@apollo/client';
import { getTimeSlotsQuery } from 'gql';

export const useTimeslotsByVets = (filters) => {
  const { data: { getTimeSlots } = {}, ...rest } = useQuery(getTimeSlotsQuery, {
    variables: { record: filters },
    fetchPolicy: 'no-cache',
    skip: !filters.vetIds?.length
  });

  return { data: getTimeSlots, ...rest };
};
