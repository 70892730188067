import { processDate } from '../pages/vet/new-appointment-modal/serializers';

const statusMap = {
  unknown: null,
  neutered: true,
  no_neutered: false
};
export const prepareAppPets = (appPets = [], isForVet) => {
  return appPets.map((appPet) => ({
    pet: {
      uid: appPet.pet.uid,
      name: appPet.pet.name,
      type: appPet.pet.type?.uid,
      birthDate: appPet.pet.birthDate,
      isSterilized: statusMap[appPet.pet.isSterilized],
      gender: appPet.pet.gender,
      update: appPet.pet.update
    },
    uid: appPet.uid || undefined,
    isFirstTime: appPet.firstTime,
    comment: isForVet ? '' : appPet.comment,
    privateComment: isForVet ? appPet.comment : '',
    concerns: appPet.concerns.map((c) => c.uid),
    files: appPet.files
  }));
};

export const signUpFinalData = (
  formValues,
  address,
  me,
  auth,
  selectedTime,
  petList,
  propertyType,
  isForVet,
  selectedTimeslot,
  vet,
  nonAppPets
) => {
  const data = {
    user: {
      address: {
        ...address,
        countryCode: address?.countryCode || address?.country?.code,
        description: formValues.address,
        floor: formValues.floor?.replace(/\D/g, '')?.length
          ? Number(formValues.floor?.replace(/\D/g, ''))
          : null,
        apartment: formValues.apartment,
        comment: formValues?.userComment,
        propertyType
      },
      firstName: formValues.firstName || me.firstName,
      lastName: formValues.lastName || me?.lastName,
      email: me?.email || formValues.email,
      phoneNumber: me?.phoneNumber || formValues.phoneNumber,
      phonePrefix: me?.phonePrefix || formValues.phonePrefix
    },
    pets: serializePetList(nonAppPets, []),
    appointmentPets: prepareAppPets(petList, isForVet),
    date: processDate(formValues.date),
    vetId: selectedTime?.vet?.uid || vet.uid,
    sendEmail: formValues.isInformClient
  };
  if (formValues?.isFirstTime) {
    data.isFirstTime = formValues?.isFirstTime;
    data.firstTimePetsCount = formValues?.firstTimePetsCount;
  }
  if (isForVet) {
    data.vetComment = formValues?.condition;
    data.timeSlotId = selectedTimeslot?.uid || selectedTimeslot;
  } else {
    data.appointmentComment = formValues?.condition;
    data.timeSlotId = selectedTime?.uid;
  }
  return data;
};

export const serializeAddressDescription = (value) => {
  const { houseNumber, street, zipCode, city, state, countryCode } = value;
  return [houseNumber, street, zipCode, city, state, countryCode].filter(Boolean).join(', ');
};

const serializePetList = (petList) => {
  return petList?.map((pet, i) => ({
    uid: pet.uid,
    name: pet.name,
    type: pet.type?.uid,
    birthDate: pet.birthDay,
    isSterilized: statusMap[pet.status] || null,
    gender: pet.gender
  }));
};
