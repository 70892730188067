import React, { useState } from 'react';
import cn from 'classnames';
import css from './CreateClient.module.css';
import { Input, message, Radio, Row } from 'antd';
import { Modal, Button, FormItem, Label, cssSpace } from '_fsd/shared';
import { PetItem } from '_fsd/entities';
import { PlusOutlined } from '@ant-design/icons';
import PhoneInput from '../../../../components/PhoneInput/PhoneInput';
import { useFormik } from 'formik';
import * as yup from 'yup';
import AddressAutocomplete from '../../../../components/vet-components/address-autocomplete/AddressAutocomplete';
import PetEdit, { petYupForClientList } from '../../../../components/_new/PetEdit/PetEdit';
import uniqueId from 'lodash/uniqueId';
import { useCreateClient } from '../api/createClient';
import { clientSchema } from '../model/schema';
import { prepareValues, preparePet } from '../lib';

export const CreateClient = ({ onSubmit, vetId }) => {
  const [modalPet, setModalPet] = useState(false);
  const [createUser] = useCreateClient({ vetId });
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phonePrefix: '+1',
      phoneNumber: '',
      propertyType: 'private_home',
      address: undefined,
      addressObject: undefined,
      floor: undefined,
      apartment: undefined,
      pets: []
    },
    validationSchema: clientSchema,
    validateOnMount: true,
    validateOnChange: true,
    onSubmit: (values, formikHelpers) => {
      formikHelpers.setSubmitting(true);
      const data = prepareValues(values);
      createUser(data)
        .then(() => {
          message.success('Client was created!');
          formikHelpers.setSubmitting(false);
          onSubmit();
        })
        .catch((err) => {
          console.error(err);
          message.error(err.message);
          formikHelpers.setSubmitting(false);
        });
    }
  });
  const petFormik = useFormik({
    initialValues: {
      pet: {
        _id: uniqueId(),
        name: '',
        type: null,
        gender: undefined,
        isSterilized: undefined,
        age: null,
        year: '',
        month: '',
        day: ''
      }
    },
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: yup.object({ pet: petYupForClientList }),
    onSubmit: (value, formikHelpers) => {
      const pet = preparePet(value.pet);
      formik.setFieldValue('pets', [...formik.values.pets, pet]);
      setModalPet(false);
      formikHelpers.resetForm();
    }
  });
  const errors = !!Object.keys(formik.errors).length;
  return (
    <div className={css.container}>
      <Row>
        <Label>Add client details</Label>
      </Row>
      <Row justify="start" className={cn(cssSpace.gap16, cssSpace.mt16)}>
        <FormItem error={formik.touched.firstName && formik.errors.firstName} label="First Name">
          <Input
            className={css.input}
            disabled={formik.isSubmitting}
            name="firstName"
            value={formik.values.firstName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </FormItem>
        <FormItem error={formik.touched.lastName && formik.errors.lastName} label="Last Name">
          <Input
            className={css.input}
            disabled={formik.isSubmitting}
            name="lastName"
            value={formik.values.lastName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </FormItem>
      </Row>
      <Row justify="start" className={cn(cssSpace.gap16)}>
        <FormItem error={formik.touched.email && formik.errors.email} label="Email">
          <Input
            className={css.input}
            disabled={formik.isSubmitting}
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </FormItem>
        <FormItem
          error={formik.touched.phoneNumber && formik.errors.phoneNumber}
          label="Phone Number">
          <PhoneInput
            className={css.input}
            onBlur={() => formik.setFieldTouched('phoneNumber')}
            disabled={formik.isSubmitting}
            error={formik.touched.phoneNumber && formik.errors.phoneNumber}
            code={formik.values.phonePrefix}
            number={formik.values.phoneNumber}
            onChange={(code, number) => {
              formik.setFieldValue('phonePrefix', code);
              formik.setFieldValue('phoneNumber', number);
            }}
          />
        </FormItem>
      </Row>
      <Row justify="start" className={cn(cssSpace.gap16)}>
        <FormItem
          className={css.address}
          onClick={() => formik.setFieldTouched('addressObject')}
          label="Address"
          error={
            formik.touched.addressObject &&
            formik.errors.addressObject &&
            'Please, select an address'
          }>
          <AddressAutocomplete
            placeholder="Building, Street, ZIP Code, City, State, Country"
            disabled={formik.isSubmitting}
            onChange={(v) => {
              formik.setFieldValue('address', v);
              formik.setFieldValue('addressObject', undefined);
            }}
            address={formik.values.address}
            onSelect={(selected) => formik.setFieldValue('addressObject', selected)}
          />
        </FormItem>
      </Row>
      <Row justify="start" className={cn(cssSpace.gap16, cssSpace.mb8)}>
        <Radio.Group
          disabled={formik.isSubmitting}
          name="propertyType"
          onChange={formik.handleChange}
          value={formik.values.propertyType}>
          <Radio value="private_home">Private house</Radio>
          <Radio value="apartment">Apartment</Radio>
        </Radio.Group>
      </Row>
      {formik.values.propertyType === 'apartment' && (
        <Row justify="start" className={cn(cssSpace.gap16)}>
          <FormItem label="Floor">
            <Input
              type="number"
              disabled={formik.isSubmitting}
              name="floor"
              value={formik.values.floor}
              onChange={formik.handleChange}
            />
          </FormItem>
          <FormItem label="Unit\Apartment">
            <Input
              disabled={formik.isSubmitting}
              name="apartment"
              value={formik.values.apartment}
              onChange={formik.handleChange}
            />
          </FormItem>
        </Row>
      )}
      <Row justify="start" className={cn(cssSpace.gap16)}>
        <FormItem label="Comment">
          <Input.TextArea
            className={css.textarea}
            placeholder="Comments about the address"
            rows={5}
            disabled={formik.isSubmitting}
            name="comment"
            value={formik.values.comment}
            onChange={formik.handleChange}
          />
        </FormItem>
      </Row>
      <Row justify="start" className={cn(cssSpace.gap16)}>
        <FormItem label="Pets">
          <Button icon={<PlusOutlined />} onClick={() => setModalPet(true)}>
            Add pet
          </Button>
        </FormItem>
      </Row>
      <Row className={cn(cssSpace.gap3, cssSpace.wrap, cssSpace.mb8)}>
        {formik.values.pets.map((p) => (
          <PetItem key={p._id} pet={p} forList />
        ))}
      </Row>

      <Row justify="end">
        <Button
          loading={formik.isSubmitting}
          type="primary"
          colorScheme="orange"
          onClick={() => formik.submitForm()}
          disabled={errors}>
          Create Client
        </Button>
      </Row>
      {modalPet && (
        <Modal
          title="Create pet"
          customFooter={() => null}
          isOpen={true}
          forceRender
          onClose={() => {
            setModalPet(false);
          }}>
          <PetEdit formik={petFormik} reqMap={{ name: true, type: true }} />
          <Row justify="end">
            <Button
              type="primary"
              colorScheme="orange"
              onClick={() => petFormik.submitForm()}
              disabled={Object.keys(petFormik.errors).length}>
              Save
            </Button>
          </Row>
        </Modal>
      )}
    </div>
  );
};
