export const VetStatus = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  OCCUPIED: 'occupied',
  LIMIT_REACHED: 'limit_reached'
};

export const VetCommuteMethod = {
  DRIVING: 'driving',
  BICYCLING: 'bicycling',
  WALKING: 'walking'
};

export const VetCommuteMethodArr = [
  { value: 'driving', displayValue: 'Driving' },
  { value: 'bicycling', displayValue: 'Bicycling' },
  { value: 'walking', displayValue: 'Walking' }
];

export const GenderOptions = [
  { value: 'male', displayValue: 'male' },
  { value: 'female', displayValue: 'female' }
];

export const YesOrNoOptions = [
  { value: true, displayValue: 'yes' },
  { value: false, displayValue: 'no' }
];

export const VetExperience = [
  { value: '1', displayValue: '1' },
  { value: '2', displayValue: '2' },
  { value: '3', displayValue: '3' },
  { value: '4', displayValue: '4' },
  { value: '5', displayValue: '5' },
  { value: '6', displayValue: '6' },
  { value: '7+', displayValue: '7+' }
];

export const PetTypes = [
  { value: 'dog', displayValue: 'Dog' },
  { value: 'cat', displayValue: 'Cat' }
];

export const BankAccountType = {
  SAVING: 'SAVING',
  CHECKING: 'CHECKING'
};

export const BankAccountClass = {
  PERSONAL: 'PERSONAL',
  CORPORATE: 'CORPORATE',
  INTERNATIONAL: 'INTERNATIONAL'
};

export const Roles = {
  VET: 'Vet',
  VET_ADMIN: 'VetsAdmin',
  PET_OWNER: 'PetOwner',
  ADMIN: 'Admin'
};

export const TimeslotsFilterSort = {
  SUGGESTED: 'suggested',
  DISTANCE_NEAREST: 'distance_nearest',
  DATE_EARLIEST: 'date_earliest'
};

export const PropertyType = {
  PrivateHome: 'private_home',
  Apartment: 'apartment'
};
