import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { EyeTwoTone, EditTwoTone, MoreOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import css from './Customers.module.css';
import { SubscriptionStatus } from './subscriptionStatus';

import './customers-styles.css';
import useActAsUser from './useActAsUser';
import { getVetName, cssText, Dropdown, UserDynamicAvatar } from '_fsd/shared';
import { UserContactInfoPopover } from '_fsd/features/user-contact-info-popover/ui/UserContactInfoPopover.jsx';

const ActionColumn = ({ record, actAsHandler, editPetOwnerHandler }) => {
  const items = [
    {
      key: 2,
      label: (
        <div className={css.actionItem} onClick={() => editPetOwnerHandler(record)}>
          <EditTwoTone twoToneColor={'#112950'} />
          <span>Edit client</span>
        </div>
      )
    },
    {
      key: 1,
      label: (
        <div className={css.actionItem} onClick={() => actAsHandler(record.userId)}>
          <EyeTwoTone twoToneColor={'#112950'} />
          <span>View as client</span>
        </div>
      )
    }
  ];
  return (
    <Dropdown
      menu={{ items }}
      trigger={['click']}
      getPopupContainer={() => {
        return document.getElementById('ID_LAYOUT_SCROLLABLE');
      }}>
      <MoreOutlined />
    </Dropdown>
  );
};

export const useColumns = (sortBy, setEditClient) => {
  const { handleActAsUser } = useActAsUser();

  const handlePetStatus = (subscription) => {
    if (subscription?.subscriptionType?.name) {
      // if subscription is monthly return subscription status
      if (subscription?.subscriptionType?.name === 'monthly') {
        return subscription?.status;
      } else {
        // if pet has active one-time return ACTIVE status
        // if not return empty
        return subscription?.status === SubscriptionStatus.ACTIVE ? subscription?.status : '';
      }
    }
  };

  const editPetOwnerHandler = (user) => {
    setEditClient(user);
  };

  const actAsHandler = (userId) => {
    handleActAsUser(userId);
  };

  return [
    {
      title: 'Registration Date',
      dataIndex: 'registrationDate',
      key: 'registrationDate',
      showSorterTooltip: false,
      sorter: true
    },
    {
      title: 'Full Name',
      dataIndex: 'fullName',
      key: 'fullName',
      showSorterTooltip: false,
      sorter: true
    },
    {
      title: 'Contacts',
      dataIndex: 'contacts',
      align: 'center',
      key: 'contacts',
      render: (text, record) => (
        <UserContactInfoPopover
          phonePrefix={record.phone}
          phoneNumber={""}
          email={record.email}
          city={record.city}
          street={record.street}
        >
          @
        </UserContactInfoPopover>
      )
    },
    {
      showSorterTooltip: false,
      sorter: true,
      title: 'Number of Pets',
      dataIndex: 'petAmount',
      align: 'center',
      key: 'petAmount',
      render: (text, record) => (
        <Popover
          content={
            <div className="popover">
              {!!record?.pets?.length &&
                record?.pets.map((pet) => {
                  return (
                    <div key={pet.uid} className="single-popover">
                      <div className={cssText.s14w6l18}>Name:</div>
                      <span>{pet.name}</span>
                      {pet?.activeSubscription && (
                        <>
                          &nbsp;&nbsp;&nbsp;
                          <div className={cssText.s14w6l18}>Status:</div>
                          <span>{handlePetStatus(pet?.activeSubscription)}</span>
                        </>
                      )}
                    </div>
                  );
                })}
            </div>
          }>
          <div style={{ cursor: 'pointer' }}>{record.petAmount}</div>
        </Popover>
      )
    },
    {
      showSorterTooltip: false,
      sorter: false,
      title: 'Next Appt Date',
      dataIndex: 'nextAppointmentDate',
      align: 'center',
      key: 'nextAppointmentDate',
      render: (text, record) => (
        <div>
          {record?.userNextAppointmentDate
            ? moment(record.userNextAppointmentDate).format('DD/MM/YYYY')
            : ''}
        </div>
      )
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: 'action',
      align: 'right',
      render: (text, record) => (
        <ActionColumn
          actAsHandler={actAsHandler}
          editPetOwnerHandler={editPetOwnerHandler}
          record={record}
        />
      )
    }
  ].filter((item) => item?.visible !== false);
};

export default useColumns;
