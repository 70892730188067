import { useMutation } from '@apollo/client';

import { toggleHasLoggedInMutation } from 'gql';

const useWizard = () => {
  const [toggleHasLoggedIn] = useMutation(toggleHasLoggedInMutation);

  return {
    toggleHasLoggedIn
  };
};

export default useWizard;
