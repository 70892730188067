import React, { useEffect } from 'react';
import { GoogleMap, Marker, Polygon, useLoadScript } from '@react-google-maps/api';
import mapStyle from 'assets/static/map-features';
import { getColor, getIconMarker } from '../lib';

const defCenter = {
  lat: 37.7749,
  lng: -122.4194
};

export const AdminMapVets = ({ vets }) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GM_KEY
  });

  const [map, setMap] = React.useState(null);

  const onMapLoad = React.useCallback((map) => {
    setMap(map);
  }, []);

  useEffect(() => {
    if (map) {
      const bounds = new window.google.maps.LatLngBounds();
      vets.forEach((v) => {
        v.timeslots.forEach((t) =>
          t.workingAreas?.forEach((w) => {
            w?.polygon?.area.forEach((a) => {
              bounds.extend(new window.google.maps.LatLng(a.lat, a.lng));
            });
          })
        );
      });
      if (vets.length) {
        map.panTo(bounds.getCenter());
        map.fitBounds(bounds, 50);
      }
    }
  }, [map, vets]);

  if (loadError) return 'Error loading maps';
  if (!isLoaded) return 'Loading maps...';

  return (
    <GoogleMap
      mapContainerStyle={{
        height: '100%',
        width: '100%'
      }}
      center={defCenter}
      zoom={13}
      onLoad={onMapLoad}
      version="weekly"
      options={{
        streetViewControl: false,
        mapTypeControl: false,
        gestureHandling: 'cooperative',
        styles: mapStyle
      }}>
      {vets.map((v, k) =>
        v.timeslots.map((t) =>
          t.workingAreas
            .filter((w) => w?.polygon?.area)
            .map((w) => (
              <React.Fragment key={w.uid}>
                <Polygon
                  key={w.uid}
                  options={{
                    fillColor: getColor(v.index),
                    fillOpacity: 0.12,
                    strokeColor: getColor(v.index),
                    strokeOpacity: 1,
                    strokeWeight: 2
                  }}
                  paths={w.polygon.area}
                />
              </React.Fragment>
            ))
        )
      )}

      {vets.map((v) =>
        v.apps.map((a) => {
          const { lat, lng } = a.appointmentRoute.address;
          const { order } = a.appointmentRoute;
          return (
            <Marker
              key={a.uid}
              position={{ lat, lng }}
              icon={{
                url:
                  'data:image/svg+xml;charset=UTF-8,' +
                  encodeURIComponent(getIconMarker(order >= 0 ? order : 30, v.index)),
                scaledSize: new window.google.maps.Size(40, 40)
              }}
            />
          );
        })
      )}
    </GoogleMap>
  );
};
