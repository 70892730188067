import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  width: 0;
  height: 0;
  overflow: hidden;
  box-shadow: none;
  transition: 0.1s ease-in-out;
  position: relative;
  ${({ visible }) =>
    visible &&
    css`
      width: 36rem;
      height: 54rem;
      box-shadow: 0rem 1.2rem 6rem -0.8rem rgba(0, 0, 0, 0.08);
    `}
  border-radius: 1.6rem;
  background-color: #ffffff;
  margin-right: 2.4rem;
  display: flex;
  max-height: 70vh;
  ${({ theme }) => theme.mobile`
      width: 0
      height: 0;
      ${({ visible }) =>
        visible &&
        css`
          width: 100%;
          height: 100%;
        `}
      transition: unset;
      margin-right: unset;
      border-radius: unset;
      max-height: unset;
      overflow:auto;
      overflow-x: hidden;
  `}
`;

export const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  left: ${({ isShown }) => (isShown ? '-100%' : 0)};
  transition: 0.3s ease-in-out;
`;
