import React from 'react';
import * as S from './components';
const SharedMedia = ({ type, url, name, clickHandler }) => {
  if (type === 'image') {
    return <S.Image src={url} alt={name} onClick={clickHandler} />;
  } else {
    return (
      <S.VideoWrapper onClick={clickHandler}>
        <S.Video src={url} />;
      </S.VideoWrapper>
    );
  }
};

export default SharedMedia;
