import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
`;

export const BackIconWrapper = styled.div`
  margin-right: 0.4rem;
  opacity: 0.8;
  width: 3.6rem;
  height: 3.6rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  > svg {
    fill: #36475f;
  }
`;

export const AvatarWrapper = styled.div`
  min-width: 3.6rem;
  height: 3.6rem;
  margin-right: 1.2rem;
`;

export const Avatar = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
`;

export const Name = styled.h3`
  color: #36475f;
  font-family: inter, assistant;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.2;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
`;

export const Content = styled.div`
  flex: 1;
  overflow-y: scroll;
  background-color: #f7f8fa;
  padding: 0 2rem;
  display: flex;
  flex-direction: column-reverse;
  padding: 1.8rem 3.4rem 0;
  width: 100%;
  ::-webkit-scrollbar {
    width: 0.6rem;
  }

  ::-webkit-scrollbar-track {
    background: #f7f8fa;
  }

  ::-webkit-scrollbar-thumb {
    background: #acbcd4;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #acbcd497;
  }
`;

export const Footer = styled.footer`
  height: 8.4rem;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  padding: 0 2.1rem;
  border-top: 0.1rem solid ${({ theme }) => theme.colors.gray};
`;

export const PlusIconWrapper = styled.div`
  width: 3.4rem;
  height: 3.4rem;
  border-radius: 50%;
  margin-right: 0.8rem;
  background-color: #f7f8fa;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const PlusIcon = styled.img`
  width: 100%;
  height: 100%;
`;

export const InputWrapper = styled.div`
  width: 86%;
  height: 4.4rem;
  position: relative;
`;

export const InputPadding = styled.div`
  width: 100%;
  height: 100%;
  padding: 1.2rem 0rem;
  background-color: #f2f4f7;
  border-radius: 5rem;
`;

export const Input = styled.textarea`
  width: 100%;
  height: 100%;
  background-color: #f2f4f7;
  padding-left: 2rem;
  padding-right: 4.5rem;
  line-height: 1.5rem;
  border-radius: 5rem;
  outline: none;
  border: none;
  color: #86919f;
  font-family: inter, assistant;
  font-weight: 400;
  font-size: 1.4rem;
  resize: none;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const SendButton = styled.button`
  outline: none;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.darkGreen};
  position: absolute;
  top: 0.2rem;
  right: 0;
  z-index: 1;
  cursor: pointer;
  outline: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.75;
      cursor: default;
    `}
`;

export const MessageContainer = styled.div`
  margin-bottom: 1.8rem;
  display: flex;
  flex-direction: column;
  align-items: ${({ isMe }) => (isMe ? 'flex-end' : 'flex-start')};
`;

export const MessageWrapper = styled.div`
  position: relative;
  max-width: 80%;
`;

export const Message = styled.div`
  white-space: pre-line;
  word-break: break-word;
  font-family: inter, assistant;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.5;
  color: ${({ theme, isMe }) => (isMe ? theme.colors.white : theme.colors.black)};
  border-radius: 1.2rem;
  padding: 1.6rem 2rem;
  background-color: ${({ isMe, theme }) => (isMe ? theme.colors.darkGreen : theme.colors.white)};
`;

export const MessageDate = styled.span`
  color: #adb5bd;
  font-family: inter, assistant;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.33;
  ${({ isMe }) => (isMe ? `margin-right: 3.6rem;` : `margin-left: 2.9rem;`)}
  margin-top: 0.4rem;
`;

export const MessageAvatarWrapper = styled.div`
  width: 3.6rem;
  height: 3.6rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 0.3rem;
  position: absolute;
  bottom: -1.3rem;
  ${({ isMe }) => (isMe ? `right: -1.8rem;` : `left: -1.8rem;`)}
`;

export const UnreadIndicator = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Line = styled.div`
  background-color: #acbcd4;
  width: 8rem;
  height: 0.05rem;
`;

export const UnreadIndicatorLabel = styled.h3`
  font-family: inter, assistant;
  font-weight: 400;
  font-size: 1.4rem;
  color: #acbcd4;
  line-height: 1.5;
`;

export const ReadOnlyFooter = styled.footer`
  height: 8.4rem;
  background-color: #fef2e4;
  color: ${({ theme }) => theme.colors.orange};
  padding: 1.3rem 0 1.8rem 0;
  text-align: center;
`;

export const ReadOnlyLabel = styled.h3`
  font-family: inter, assistant;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2;
`;

export const ReadOnlyDescription = styled.p`
  font-size: 1.2rem;
  font-family: inter, assistant;
  font-weight: 400;
  line-height: 2;
`;

export const Image = styled.img`
  width: 10rem;
  height: 10rem;
  border-radius: 10px;
  background: transparent url('https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif') center
    no-repeat;
  background-size: contain;
`;

export const VideoWrapper = styled.div`
  position: relative;
  cursor: pointer;
`;

export const VideoLayer = styled.div`
  background-color: rgba(255, 255, 255, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const VideoIconWrapper = styled.div`
  width: 3.6rem;
  height: 3.6rem;
`;

export const SentImage = styled.img`
  cursor: pointer;
  width: 20rem;
`;

export const HeaderHoverWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  border-radius: 0.6rem;
  padding: 0.4rem 0.8rem;
  cursor: pointer;

  &:hover {
    background-color: #f2f4f7;
  }
`;

export const ToolTopWrapper = styled.div`
  white-space: normal;
  text-align: left;
  max-width: 60%;

  & a {
    display: flex;
    align-items: center;
  }

  & span {
    font-size: 1.2rem;
    line-height: 1.6rem;
    font-weight: 400;
  }
`;
