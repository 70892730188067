import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';

import { useWizardContext } from 'common_repo/service/wizard/wizardContext';

import { ReactComponent as BookIcon } from 'assets/icons/book.svg';

import './style.scss';

const { Title } = Typography;

const HowItWorks = () => {
  const { t } = useTranslation();

  const { openWizard } = useWizardContext();

  return (
    <div className="how-it-works" onClick={openWizard}>
      <BookIcon />
      <Title level={2} className="title">
        {t('how.it.works')}
      </Title>
    </div>
  );
};

export default HowItWorks;
