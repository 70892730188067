import React, { memo, useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';

import RescheduleAppointmentModal from 'components/reschedule-appointment-modal/RescheduleAppointmentModal';
import useRescheduleAppointment from 'components/reschedule-appointment-modal/useRescheduleAppointment';
import AppointmentEtaModal from 'components/edit-appointment-eta-modal/AppointmentEtaModal';
import AppointmentPatientListModal from 'components/edit-appointment-patient-list-modal/AppointmentPatientListModal';
import AppointmentDurationModal from 'components/appointment-duration-modal/AppointmentDurationModal';
import useAppointmentDuration from 'components/appointment-duration-modal/useAppointmentDuration';
import useEditAppointmentEta from 'components/edit-appointment-eta-modal/useEditAppointmentEta';
import useEditAppointmentPatientList from 'components/edit-appointment-patient-list-modal/useEditAppointmentPatientList';
import CancelAppointmentModal from 'components/cancel-appointment-modal/CancelAppointmentModal';
import useCancelAppointment from 'components/cancel-appointment-modal/useCancelAppointment';
import useAppointments from './useAppointments';
import './styles.css';

import AppointmentMobileModal from './AppointmentMobileModal';
import EditAppointmentDetailsModal from './EditAppointmentDetailsModal';
import { processAddress } from '../../new-appointment-modal/serializers';
import { RescheduleWrapper } from '_fsd/widgets';
import { Modal } from '_fsd/shared';

const emptySelectedAppointment = {
  addPatient: null,
  duration: null,
  cancel: null,
  reschedule: null,
  eta: null
};

const Appointments = ({
  setIsSmartScheduleOpen,
  setSelectedAppointment,
  selectedAppointment,
  getAppointmentsByTimeslotUid,
  appointmentsData,
  selectedTimeslotId,
  selectedTimeslot,
  fullTimeSlots,
  calendarDate,
  timeSlots,
  vetId,
  vet
}) => {
  const { t } = useTranslation();

  const { removeVisit } = useCancelAppointment();
  const { rescheduleVisit } = useRescheduleAppointment();
  const { updateVisitDuration } = useAppointmentDuration();
  const { updateAppointmentETA } = useEditAppointmentEta();
  const { fetchTimeSlots, timeSlotsInArea } = useAppointments();
  const [view, onChangeView] = React.useState(true);

  const [selectedMobileAppointment, setSelectedMobileAppointment] = useState(null);
  const [isEditAppointmentModalOpen, setIsAppointmentModalIsOpen] = useState(false);

  const handleRemoveSelectedAppointment = useCallback(() => {
    return setSelectedAppointment(emptySelectedAppointment);
  }, []);

  const handleCancelAppointment = useCallback(() => {
    const { appointment, user } = selectedAppointment.cancel;
    try {
      removeVisit(appointment?.appointmentRoute?.uid, user).then((r) => {
        setSelectedAppointment(emptySelectedAppointment);
        getAppointmentsByTimeslotUid(selectedTimeslotId, calendarDate);
        message.success(t('new_appointment.cancel_success'));
      });
    } catch (e) {
      message.error('Error: ' + e.message);
    }
  }, [selectedAppointment]);

  const handleRescheduleAppointment = useCallback(
    (date, timeSlotId) => {
      try {
        rescheduleVisit(date, selectedAppointment.reschedule.uid, timeSlotId).then(() => {
          setSelectedAppointment(emptySelectedAppointment);
          getAppointmentsByTimeslotUid(selectedTimeslotId, calendarDate);
          message.success(t('new_appointment.reschedule_success'));
        });
      } catch (e) {
        message.error('Error: ' + e.message);
      }
    },
    [selectedAppointment]
  );

  const handleChangeAppointmentDuration = useCallback(
    (duration) => {
      const num = typeof duration === 'number' ? duration : Number(duration?.replace(/\D/g, ''));
      const { appointmentRoute } = selectedAppointment.duration;
      try {
        updateVisitDuration(appointmentRoute?.uid, num).then(() => {
          setSelectedAppointment(emptySelectedAppointment);
          getAppointmentsByTimeslotUid(selectedTimeslotId, calendarDate);
          message.success(t('new_appointment.duration_success'));
        });
      } catch (e) {
        message.error('Error: ' + e.message);
      }
    },
    [selectedAppointment]
  );

  const handleChangeAppointmentETA = useCallback(
    (etaStart, etaEnd) => {
      const { appointmentRoute } = selectedAppointment.eta;
      try {
        updateAppointmentETA(appointmentRoute?.uid, etaStart, etaEnd)
          .then(() => {
            setSelectedAppointment(emptySelectedAppointment);
            getAppointmentsByTimeslotUid(selectedTimeslotId, calendarDate);
            message.success(t('new_appointment.eta_success'));
          })
          .catch((e) => {
            message.error('Error: ' + e.message);
          });
      } catch (e) {
        message.error('Error: ' + e.message);
      }
    },
    [selectedAppointment]
  );

  const handleChangeAppointmentPatientListForAppt = useCallback(
    (patientsList, selectedPetsIndxList, newPetsIndxList, isRecalculateDuration) => {
      setSelectedAppointment(emptySelectedAppointment);
      message.success(t('new_appointment.patients_edit_success'));
    },
    [selectedAppointment]
  );

  useEffect(() => {
    if (appointmentsData?.length)
      fetchTimeSlots(vetId, appointmentsData?.[0]?.appointmentRoute?.address);
  }, [appointmentsData]);

  return (
    <>
      {selectedMobileAppointment && (
        <AppointmentMobileModal
          onRequestClose={() => setSelectedMobileAppointment(false)}
          setSelectedAppointment={setSelectedAppointment}
          appointment={selectedMobileAppointment}
        />
      )}

      {selectedAppointment?.duration && (
        <AppointmentDurationModal
          onclose={handleRemoveSelectedAppointment}
          onConfirm={handleChangeAppointmentDuration}
          duration={selectedAppointment?.duration?.appointmentRoute?.duration}
        />
      )}

      {selectedAppointment?.eta && (
        <AppointmentEtaModal
          onclose={handleRemoveSelectedAppointment}
          onConfirm={handleChangeAppointmentETA}
          apptEtaStart={selectedAppointment?.eta?.appointmentRoute?.etaStart}
          apptEtaEnd={selectedAppointment?.eta?.appointmentRoute?.etaEnd}
        />
      )}

      {selectedAppointment?.addPatient && (
        <AppointmentPatientListModal
          selectedAppointment={selectedAppointment}
          vet={vet}
          onclose={handleRemoveSelectedAppointment}
          onConfirm={handleChangeAppointmentPatientListForAppt}
        />
      )}

      {selectedAppointment?.cancel && (
        <CancelAppointmentModal
          onclose={handleRemoveSelectedAppointment}
          onConfirm={handleCancelAppointment}
          user={selectedAppointment.cancel?.user}
        />
      )}

      {selectedAppointment?.reschedule && (
        <Modal
          title="Reschedule Appointment"
          isOpen={selectedAppointment?.reschedule}
          onConfirm={handleRemoveSelectedAppointment}
          onClose={handleRemoveSelectedAppointment}
          customFooter={() => null}>
          <RescheduleWrapper
            close={handleRemoveSelectedAppointment}
            vetId={vetId}
            currentAddress={selectedAppointment?.reschedule?.appointment?.appointmentRoute?.address}
            appointment={selectedAppointment?.reschedule?.appointment}
            defaultTimeslot={selectedTimeslot}
            appointments={appointmentsData}
          />
        </Modal>
      )}

      {isEditAppointmentModalOpen && (
        <EditAppointmentDetailsModal
          data={isEditAppointmentModalOpen}
          onRequestClose={() => setIsAppointmentModalIsOpen(false)}
          onConfirm={() => {
            getAppointmentsByTimeslotUid(selectedTimeslotId, calendarDate);
            setIsAppointmentModalIsOpen(false);
            message.success('Updated Successfully');
          }}
        />
      )}
    </>
  );
};

export default memo(Appointments);
