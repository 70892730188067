import { loader } from 'graphql.macro';

// queries
export const getUserByPhoneQuery = loader('./queries/getUserByPhone.graphql');
export const meQuery = loader('./queries/me.graphql');
export const getPetsByOwnerQuery = loader('./queries/getPetsByOwner.graphql');
export const getPetQuery = loader('./queries/getPet.graphql');
export const getSubscriptionTypes = loader('./queries/getSubscriptionTypes.graphql');
export const getPlansQuery = loader('./queries/getPlans.graphql');
export const isConversationReadOnlyQuery = loader('./queries/isConversationReadOnly.graphql');
export const getVetsQuery = loader('./queries/getVets.graphql');
export const getVetsAdminQuery = loader('./queries/getVetsAdmin.graphql');
export const calculateSubscriptionPriceQuery = loader(
  './queries/calculateSubscriptionPrice.graphql'
);
export const getSubscriptionPetsQuery = loader('./queries/getSubscriptionPets.graphql');
export const getSubscriptionQuery = loader('./queries/getSubscription.graphql');
export const getAppointmentQuery = loader('./queries/getAppointment.graphql');
export const getVetByUidQuery = loader('./queries/getVetByUid.graphql');
export const getAppointmentsQuery = loader('./queries/getAppointments.graphql');
export const getAppointmentRoutesQuery = loader('./queries/getAppointmentRoutes.graphql');
export const getPaymentMethodsQuery = loader('./queries/getPaymentMethods.graphql');
export const getPetTypesQuery = loader('./queries/getPetTypes.graphql');

export const getPaymentProviderByUserLocationQuery = loader(
  './queries/getProviderByUserLocation.graphql'
);
export const getPetSubscriptionQuery = loader('./queries/getPetSubscription.graphql');
export const getSubscriptionDiscountQuery = loader('./queries/getSubscriptionDiscount.graphql');
export const getNotificationsByUserQuery = loader('./queries/getNotificationsByUser.graphql');

export const getVetsInUserAreaQuery = loader('./queries/getVetsInUserArea.graphql');

export const getSoldInventoriesForAppointmentQuery = loader(
  './queries/getSoldInventoriesForAppointment.graphql'
);
export const getTimeQuery = loader('./queries/getTime.graphql');
export const getSoldInventoriesForAppointmentRouteQuery = loader(
  './queries/getSoldInventoriesForAppointmentRoute.graphql'
);
export const getTimeslotsInUserAreaQuery = loader('./queries/getTimeslotsInUserArea.graphql');

export const getLanguagesQuery = loader('./queries/getLanguages.graphql');
export const getPaymentProvidersQuery = loader('./queries/getPaymentProviders.graphql');
export const getTimezonesQuery = loader('./queries/getTimezones.graphql');
export const getAuthVetQuery = loader('./queries/getAuthVet.graphql');
export const getVetInventoriesQuery = loader('./queries/getVetInventories.graphql');
export const getPetOwnersQuery = loader('./queries/getPetOwners.graphql');
export const getUniqueCountriesQuery = loader('./queries/getUniqueCountries.graphql');
export const getPetOwnersTotalsQuery = loader('./queries/getPetOwnersTotals.graphql');
export const getPetOwnerByUidQuery = loader('./queries/getPetOwnerByUid.graphql');
export const getSubscriptionPetsByUserQuery = loader('./queries/getSubscriptionPetsByUser.graphql');

export const getUsersByRouteDateQuery = loader('./queries/getUsersByRouteDate.graphql');
export const getPetsByUserQuery = loader('./queries/getPetsByUser.graphql');

// redundant?
export const getTimeSlotsByVetUidQuery = loader('./queries/getTimeSlotsByVetUid.graphql');
export const getAppointmentsByDateQuery = loader('./queries/getAppointmentsByDate.graphql');

export const getVetsForUserQuery = loader('./queries/getVetsForUser.graphql');

export const checkIfUserExistsQuery = loader('./queries/checkIfUserExists.graphql');

export const getVetByShareIdQuery = loader('./queries/getVetByShareId.graphql');

export const getConcernsQuery = loader('./queries/getConcerns.graphql');
export const getVetTimeSlotsByUserAddressQuery = loader(
  './queries/getVetTimeSlotsByUserAddress.graphql'
);
export const getZipCodesQuery = loader('./queries/getZipCodes.graphql');
export const getUserSettingsByUserQuery = loader('./queries/getUserSettingsByUser.graphql');
export const getUserConcernsByUserIdQuery = loader('./queries/getUserConcernsByUserId.graphql');

// mutations
export const requestCodeMutation = loader('./mutations/requestCode.graphql');
export const removePetMutation = loader('./mutations/removePet.graphql');
export const updatePlanForPetMutation = loader('./mutations/updatePlanForPet.graphql');
export const removeSubscriptionPetMutation = loader('./mutations/removeSubscriptionPet.graphql');
export const verifyCodeMutation = loader('./mutations/verifyCode.graphql');
export const createPetMutation = loader('./mutations/createPet.graphql');
export const updatePersonalDetailsMutation = loader('./mutations/updatePersonalDetails.graphql');
export const getUploadUrlMutation = loader('./mutations/getUploadUrl.graphql');
export const choosePetsForSubscriptionMutation = loader(
  './mutations/choosePetsForSubscription.graphql'
);
export const chooseVetForSubscriptionMutation = loader(
  './mutations/chooseVetForSubscription.graphql'
);
export const rescheduleAppointmentsMutation = loader('./mutations/rescheduleAppointments.graphql');
export const createSubscriptionMutation = loader('./mutations/createSubscription.graphql');
export const buySubscriptionMutation = loader('./mutations/buySubscription.graphql');
export const removePaymentCardMutation = loader('./mutations/removePaymentCard.graphql');

export const getPaymentFieldsTokenMutation = loader('./mutations/getPaymentFieldsToken.graphql');

export const buyBluesnapSubscriptionMutation = loader(
  './mutations/buyBluesnapSubscription.graphql'
);

export const createSubscriptionForPetMutation = loader(
  './mutations/createSubscriptionForPet.graphql'
);
export const bookAVisitForPetMutation = loader('./mutations/bookAVisitForPet.graphql');

export const updateEmailSubscriptionMutation = loader(
  './mutations/updateEmailSubscription.graphql'
);

export const updateLastNotificationsSeenMutation = loader(
  './mutations/updateLastNotificationsSeen.graphql'
);

export const updateNotificationSeenMutation = loader('./mutations/updateNotificationSeen.graphql');

export const markAllAsReadMutation = loader('./mutations/markAllAsRead.graphql');

export const updateVisitNoteMutation = loader('./mutations/updateVisitNote.graphql');

export const updateAppointmentUserComment = loader(
  './mutations/updateAppointmentUserComment.graphql'
);
export const actAsUserMutation = loader('./mutations/actAsUser.graphql');
export const removeSubscriptionMutation = loader('./mutations/removeSubscription.graphql');
export const updateUserMutation = loader('./mutations/updateUser.graphql');
export const cancelVisitMutation = loader('./mutations/cancelVisit.graphql');
export const toggleUserConcernIsSelectedMutation = loader(
  './mutations/toggleUserConcernIsSelected.graphql'
);

// subscriptions

export const newNotificationSubscription = loader('./subscriptions/newNotification.graphql');

export const createVetMutation = loader('./mutations/createVet.graphql');
export const removeTimeSlotMutation = loader('./mutations/removeTimeSlot.graphql');
export const createTimeSlotsMutation = loader('./mutations/createTimeSlots.graphql');
export const excludeTimeSlotMutation = loader('./mutations/excludeTimeSlot.graphql');
export const signUpFlowMutation = loader('./mutations/signUpFlow.graphql');
export const toggleHasLoggedInMutation = loader('./mutations/toggleHasLoggedIn.graphql');
export const updateUserSettingMutation = loader('./mutations/updateUserSetting.graphql');
export const createUserConcernMutation = loader('./mutations/createUserConcern.graphql');
export const removeUserConcernMutation = loader('./mutations/removeUserConcern.graphql');
export const updateNextAppointmentPetEstimationDateMutation = loader(
  './mutations/updateNextAppointmentPetEstimationDate.graphql'
);
export const toggleIsLockedMutation = loader('./mutations/toggleIsLocked.graphql');

export const changeVisitOrderMutation = loader('./mutations/changeVisitOrder.graphql');
export const updateAppointmentRouteEta = loader('./mutations/updateAppointmentRouteEta.graphql');
// export const addPetToAppointmentRoute = loader('./mutations/addPetToAppointmentRoute.graphql');
export const editAppointmentRoutePetList = loader(
  './mutations/editAppointmentRoutePetList.graphql'
);
export const updateVisitDurationMutation = loader('./mutations/updateVisitDuration.graphql');
export const rescheduleVisitMutation = loader('./mutations/rescheduleVisit.graphql');
export const updateRouteMutation = loader('./mutations/updateRoute.graphql');
export const updateAppointmentRouteAddressMutation = loader(
  './mutations/updateAppointmentRouteAddress.graphql'
);
export const updateAppointmentVetCommentMutation = loader(
  './mutations/updateAppointmentVetComment.graphql'
);
export const updateAppointmentPetMutation = loader('./mutations/updateAppointmentPet.graphql');
export const reassignUserPhoneMutation = loader('./mutations/reassignUserPhone.graphql');
export const requestCodeOnEmailMutation = loader('./mutations/requestCodeOnEmail.graphql');
export const createUserEvent = loader('./mutations/createUserEvent.graphql');
export const addPetToAppointmentRoute = loader('./mutations/addPetToAppointmentRoute.graphql');
export const authAdminMutation = loader('./mutations/authAdmin.graphql');
export const removeVetMutation = loader('./mutations/removeVet.graphql');
export const getClinicVetsQuery = loader('./queries/getClinicVets.graphql');
export const getTimeSlotsQuery = loader('./queries/getTimeSlots.graphql');
export const getCalendarEventsQuery = loader('./queries/getCalendarEvents.graphql');
export const getFilteredTimeSlotsByVetUidQuery = loader(
  './queries/getFilteredTimeSlotsByVetUid.graphql'
);
export const getVetByUidAdminQuery = loader('./queries/getVetByUid.admin.graphql');
export const updateRouteStatusMutation = loader('./mutations/updateRouteStatus.graphql');
export const unlockRouteByIdMutation = loader('./mutations/unlockRouteById.graphql');
export const lockRouteByIdMutation = loader('./mutations/lockRouteById.graphql');
