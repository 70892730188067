import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: auto;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  padding: 1rem 2.4rem 1rem 2.4rem;
  font-size: 1.2rem;
  font-weight: 400;
  color: #000000d9;
  margin-bottom: ${({ isMobile }) => (isMobile ? '1.6rem' : '0')};
`;
export const Text = styled.h3`
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 0;
`;
