import { useMutation } from '@apollo/client';
import { signUpFlowMutation } from 'gql';

export const useCreateClient = ({ vetId }) => {
  const [createUser, ...rest] = useMutation(signUpFlowMutation);
  return [
    (variables) =>
      createUser({
        variables: {
          record: {
            ...variables,
            vetId
          }
        }
      }),
    ...rest
  ];
};
