import {
  createUserConcernMutation,
  getUserSettingsByUserQuery,
  removeUserConcernMutation,
  updateUserSettingMutation,
  getUserConcernsByUserIdQuery
} from 'gql';
import { useMutation } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { message } from 'antd';

const usePreferences = (userId) => {
  const { t } = useTranslation();
  const { data, loading } = useQuery(getUserSettingsByUserQuery);
  const concerns = useQuery(getUserConcernsByUserIdQuery, {
    variables: {
      id: userId,
      includeDisabledServices: true
    }
  });
  const [updateUserSetting, { loading: loadingUpdateSettings }] = useMutation(
    updateUserSettingMutation,
    {
      refetchQueries: [{ query: getUserSettingsByUserQuery }]
    }
  );
  const [createUserConcern, { loading: loadingCreateUserConcern }] = useMutation(
    createUserConcernMutation,
    {
      refetchQueries: [
        { query: getUserSettingsByUserQuery },
        {
          query: getUserConcernsByUserIdQuery,
          variables: { id: userId, includeDisabledServices: true }
        }
      ]
    }
  );

  const [removeUserConcern, { loading: loadingRemoveUserConcern }] = useMutation(
    removeUserConcernMutation,
    {
      refetchQueries: [
        { query: getUserSettingsByUserQuery },
        {
          query: getUserConcernsByUserIdQuery,
          variables: { id: userId, includeDisabledServices: true }
        }
      ]
    }
  );

  const handleUpdateUserSetting = async (uid, itemValue, description) => {
    try {
      await updateUserSetting({
        variables: { record: { uid, itemValue, description } }
      });
      message.success(t('updated.successfully'));
    } catch (err) {
      message.error(err?.message);
    }
  };
  const handleCreateUserConcern = async ({ uid, itemValue, duration, isSelected, isBookable }) => {
    try {
      await createUserConcern({
        variables: { record: { uid, itemValue, duration, isSelected, isBookable } }
      });
      if (uid) message.success(t('updated.successfully'));
      else message.success(t('created.successfully'));
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleRemoveUserConcern = async (id) => {
    try {
      await removeUserConcern({
        variables: { id }
      });
      message.success(t('deleted.successfully'));
    } catch (e) {
      const error = e?.graphQLErrors?.[0]?.message;
      message.error(error || t('failed.to.delete'), 10);
    }
  };

  return {
    userSettings: data?.getUserSettingsByUser,
    loading,
    concernsList: concerns?.data?.getUserConcernsByUserId,
    handleUpdateUserSetting,
    handleCreateUserConcern,
    handleRemoveUserConcern,
    loadingCreateUserConcern,
    loadingUpdateSettings,
    loadingRemoveUserConcern
  };
};

export default usePreferences;
