import React from 'react';
import * as S from './components';

import { ReactComponent as DropDownIcon } from 'assets/icons/arrow-down-gray.svg';

const DropDown = ({ open, toggle, children, headerTitle, hideBorder }) => {
  return (
    <S.DropDownWrapper open={open} hideBorder={hideBorder && open}>
      <S.DropDownHeader onClick={toggle}>
        <S.DropDownHeaderTitle>{headerTitle}</S.DropDownHeaderTitle>
        <S.IconWrapper open={open}>
          <DropDownIcon />
        </S.IconWrapper>
      </S.DropDownHeader>
      <S.DropDownContentWrapper open={open}>{children}</S.DropDownContentWrapper>
    </S.DropDownWrapper>
  );
};

export default DropDown;
